import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate } from 'react-router-dom';
import StudentContext from '../../../../context/StudentContext';
import axios from 'axios';
import Swal from 'sweetalert2';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;


export const EditDataKeluargaPage = () => {

    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();
    const { student, getStudent } = useContext(StudentContext);
    const [errors, setErrors] = useState({});

    const [rayons, setRayons] = useState([]);

    const [formData, setFormData] = useState({
        no_kk: "",
        nik: "",
        nama_ayah: "",
        nik_ayah: "",
        tempat_lahir_ayah: "",
        tanggal_lahir_ayah: "",
        pendidikan_ayah: "",
        pekerjaan_ayah: "",
        penghasilan_ayah: "",
        no_telp_ayah: "",
        nama_ibu: "",
        nik_ibu: "",
        tempat_lahir_ibu: "",
        tanggal_lahir_ibu: "",
        pendidikan_ibu: "",
        pekerjaan_ibu: "",
        penghasilan_ibu: "",
        no_telp_ibu: "",
        nama_wali: "",
        nik_wali: "",
        jk_wali: "",
        tempat_lahir_wali: "",
        tanggal_lahir_wali: "",
        pendidikan_wali: "",
        pekerjaan_wali: "",
        penghasilan_wali: "",
        no_telp_wali: "",
        ada_wali: "",
        status_data_family: "",
        ada_referensi: "",
        nama_referensi: "",
        referensi_rayon: "",
        referensi_penerima_beasiswa: "",
        status_data_diri: "",
        status_data_family: "",
        status_data_dokumen: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/student/${student?._id}`,
                    {
                        headers: {
                            'ngrok-skip-browser-warning': 'true',
                        },
                    });
                const responseData = response.data;
                const keluargaIdData = responseData?.student.keluarga_id || null;
                console.log(keluargaIdData)
                setFormData(keluargaIdData);
                setFormData((prevData) => ({ ...prevData, no_kk: responseData.student.no_kk }))
                setFormData((prevData) => ({ ...prevData, nik: responseData.student.nik }))
                setFormData((prevData) => ({ ...prevData, nama_referensi: responseData.student.nama_referensi }))
                setFormData((prevData) => ({ ...prevData, referensi_rayon: responseData.student.referensi_rayon }))
                setFormData((prevData) => ({ ...prevData, referensi_penerima_beasiswa: responseData.student.referensi_penerima_beasiswa }))
                const response2 = await axios.get(`${apiEndpoint}/rayon`,
                    {
                        headers: {
                            'ngrok-skip-browser-warning': 'true',
                        },
                    });
                setRayons(response2.data.rayon)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [student]);

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value ? "" : "Wajib diisi",
        }));
    };

    const handleChangeNumber = (e,exactLength) => {
        const { name, value } = e.target;
        // onKeyDown={(e) => (e.target.value.length==16) ? true : false}
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        
        if (value.length > exactLength) {
            let newValue = value.slice(0, exactLength);
            setFormData((prevData) => ({ ...prevData, [name]: newValue }));
        }
        
        if (value.length !== exactLength) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: `${name} Harus tepat ${exactLength} digit`,
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "",
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await Swal.fire({
            title: "Konfirmasi",
            text: "Apakah Anda yakin data sudah benar?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/student/update/${student?._id}`, formData);
                Swal.fire({
                    title: "Sukses!",
                    text: "Berhasil Update Data",
                    icon: "success",
                }).then(() => {
                    getStudent()
                    navigate("/dashboard/data-diri");
                });
            } catch (error) {
                console.log(error, "error ini");
            }
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Data Siswa" dua="Edit Data Keluarga" urlSatu="/dashboard/data-diri" />

                <div className='d-flex w-100'>
                    <div style={{ border: `5px solid  ${currentStep === 1 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100" />
                    <div style={{ border: `5px solid  ${currentStep === 2 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                    <div style={{ border: `5px solid  ${currentStep === 3 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                    <div style={{ border: `5px solid  ${currentStep === 4 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                    <div style={{ border: `5px solid  ${currentStep === 5 ? '#1c367e' : '#e9e9e9'} `, borderRadius: "10px" }} className="w-100 ml-2" />
                </div>

                <form onSubmit={(e) => handleSubmit(e)}>

                    {currentStep === 1 && (
                        <div className='card-box shadow-sm mt-4 p-4 mb-4'>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No Kartu Keluarga (KK) <span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.no_kk ? 'is-invalid' : ''}`}
                                            value={formData?.no_kk}
                                            onChange={(e) => handleChangeNumber(e,16) }
                                            name='no_kk'
                                        />
                                        {errors.no_kk && <p style={{ color: "red" }}>{errors.no_kk}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>NIK Peserta Didik <span className='text-danger'>*</span></label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.nik ? 'is-invalid' : ''}`}
                                            value={formData?.nik}
                                            onChange={(e) => handleChangeNumber(e,16) }
                                            name='nik'
                                        />
                                        {errors.nik && <p style={{ color: "red" }}>{errors.nik}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Nama Ayah  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.nama_ayah ? 'is-invalid' : ''}`}
                                            value={formData?.nama_ayah}
                                            onChange={(e) => handleChange(e)}
                                            name='nama_ayah'
                                        />
                                        {errors.nama_ayah && <p style={{ color: "red" }}>{errors.nama_ayah}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>NIK Ayah  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.nik_ayah ? 'is-invalid' : ''}`}
                                            value={formData?.nik_ayah}
                                            onChange={(e) => handleChangeNumber(e,16)}
                                            name='nik_ayah'
                                        />
                                        {errors.nik_ayah && <p style={{ color: "red" }}>{errors.nik_ayah}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tempat Lahir Ayah  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.tempat_lahir_ayah ? 'is-invalid' : ''}`}
                                            value={formData?.tempat_lahir_ayah}
                                            onChange={(e) => handleChange(e)}
                                            name='tempat_lahir_ayah'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tanggal Lahir Ayah  <span className='text-danger'>*</span></label>
                                        <input
                                            type='date'
                                            className={`form-control ${errors.tanggal_lahir_ayah ? 'is-invalid' : ''}`}
                                            value={formData?.tanggal_lahir_ayah}
                                            onChange={(e) => handleChange(e)}
                                            name='tanggal_lahir_ayah'
                                        />
                                        {errors.tanggal_lahir_ayah && <p style={{ color: "red" }}>{errors.tanggal_lahir_ayah}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pendidikan Ayah  <span className='text-danger'>*</span></label>
                                        <select
                                            name='pendidikan_ayah'
                                            className={`form-select ${errors.agama ? 'is-invalid' : ''}`}
                                            value={formData.pendidikan_ayah}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Pendidikan</option>
                                            <option value="Tidak Sekolah">Tidak Sekolah</option>
                                            <option value="Putus SD">Putus SD</option>
                                            <option value="SD Sederajat">SD Sederajat</option>
                                            <option value="SMP Sederajat">SMP Sederajat</option>
                                            <option value="SMA Sederajat">SMA Sederajat</option>
                                            <option value="D1">D1</option>
                                            <option value="D2">D2</option>
                                            <option value="D3">D3</option>
                                            <option value="D4/S1"> D4/S1</option>
                                            <option value="S2">S2</option>
                                            <option value="S3">S3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pekerjaan Ayah  <span className='text-danger'>*</span></label>
                                        <select
                                            name='pekerjaan_ayah'
                                            className={`form-select ${errors.agama ? 'is-invalid' : ''}`}
                                            value={formData.pekerjaan_ayah}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Perkerjaan</option>
                                            <option value="Tidak Bekerja">Tidak Bekerja</option>
                                            <option value="Nelayan">Nelayan</option>
                                            <option value="Petani">Petani</option>
                                            <option value="Peternak">Peternak</option>
                                            <option value="PNS/TNI/POLRI"> PNS/TNI/POLRI</option>
                                            <option value="Karyawan Swasta"> Karyawan Swasta</option>
                                            <option value="Pedagang Kecil"> Pedagang Kecil</option>
                                            <option value="Pedagang Besar"> Pedagang Besar</option>
                                            <option value="Wiraswasta"> Wiraswasta</option>
                                            <option value="Wirausaha"> Wirausaha</option>
                                            <option value="Buruh"> Buruh</option>
                                            <option value="Pensiunan"> Pensiunan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Penghasilan Ayah (perbulan)  <span className='text-danger'>*</span></label>
                                        <select
                                            name='penghasilan_ayah'
                                            className={`form-select ${errors.penghasilan_ayah ? 'is-invalid' : ''}`}
                                            value={formData?.penghasilan_ayah}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Penghasilan</option>
                                            <option value="Rp. 500.000-Rp.999.999">Rp. 500.000-Rp.999.999</option>
                                            <option value="Rp. 1.000.000-Rp.1.999.999">Rp. 1.000.000-Rp.1.999.999</option>
                                            <option value="Rp.2.000.000-Rp.4.999.999">Rp.2.000.000-Rp.4.999.999</option>
                                            <option value="Rp.5.000.000-Rp.20.000.000">Rp.5.000.000-Rp.20.000.000</option>
                                            <option value="diatas Rp.20.000.000"> diatas Rp.20.000.000</option>
                                            <option value="Tidak Berpenghasilan"> Tidak Berpenghasilan</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No HP Ayah <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.no_telp_ayah ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_telp_ayah}
                                            onChange={(e) => handleChange(e)}
                                            name='no_telp_ayah'
                                        />
                                        {errors.no_telp_ayah && <p style={{ color: "red" }}>{errors.no_telp_ayah}</p>}

                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}

                    {currentStep === 2 && (
                        <div className='card-box shadow-sm p-4 mt-4'>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Nama Ibu  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.nama_ayah ? 'is-invalid' : ''}`}
                                            value={formData?.nama_ibu}
                                            onChange={(e) => handleChange(e)}
                                            name='nama_ibu'
                                        />
                                        {errors.nama_ibu && <p style={{ color: "red" }}>{errors.nama_ibu}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>NIK Ibu  <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.nik_ibu ? 'is-invalid' : ''}`}
                                            value={formData?.nik_ibu}
                                            onChange={(e) => handleChangeNumber(e,16)}
                                            name='nik_ibu'
                                        />
                                        {errors.nik_ibu && <p style={{ color: "red" }}>{errors.nik_ibu}</p>}

                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tempat Lahir Ibu  <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.tempat_lahir_ibu ? 'is-invalid' : ''}`}
                                            value={formData?.tempat_lahir_ibu}
                                            onChange={(e) => handleChange(e)}
                                            name='tempat_lahir_ibu'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Tanggal Lahir Ibu  <span className='text-danger'>*</span></label>
                                        <input
                                            type='date'
                                            className={`form-control ${errors.tanggal_lahir_ibu ? 'is-invalid' : ''}`}
                                            value={formData?.tanggal_lahir_ibu}
                                            onChange={(e) => handleChange(e)}
                                            name='tanggal_lahir_ibu'
                                        />
                                        {errors.tanggal_lahir_ibu && <p style={{ color: "red" }}>{errors.tanggal_lahir_ibu}</p>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pendidikan Ibu  <span className='text-danger'>*</span></label>
                                        <select
                                            name='pendidikan_ibu'
                                            className={`form-select ${errors.pendidikan_ibu ? 'is-invalid' : ''}`}
                                            value={formData.pendidikan_ibu}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Pendidikan</option>
                                            <option value="Tidak Sekolah">Tidak Sekolah</option>
                                            <option value="Putus SD">Putus SD</option>
                                            <option value="SD Sederajat">SD Sederajat</option>
                                            <option value="SMP Sederajat">SMP Sederajat</option>
                                            <option value="SMA Sederajat">SMA Sederajat</option>
                                            <option value="D1">D1</option>
                                            <option value="D2">D2</option>
                                            <option value="D3">D3</option>
                                            <option value="D4/S1"> D4/S1</option>
                                            <option value="S2">S2</option>
                                            <option value="S3">S3</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Pekerjaan Ibu  <span className='text-danger'>*</span></label>
                                        <select
                                            name='pekerjaan_ibu'
                                            className={`form-select ${errors.pekerjaan_ibu ? 'is-invalid' : ''}`}
                                            value={formData.pekerjaan_ibu}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Perkerjaan</option>
                                            <option value="Tidak Bekerja">Tidak Bekerja</option>
                                            <option value="Nelayan">Nelayan</option>
                                            <option value="Petani">Petani</option>
                                            <option value="Peternak">Peternak</option>
                                            <option value="PNS/TNI/POLRI"> PNS/TNI/POLRI</option>
                                            <option value="Karyawan Swasta"> Karyawan Swasta</option>
                                            <option value="Pedagang Kecil"> Pedagang Kecil</option>
                                            <option value="Pedagang Besar"> Pedagang Besar</option>
                                            <option value="Wiraswasta"> Wiraswasta</option>
                                            <option value="Wirausaha"> Wirausaha</option>
                                            <option value="Buruh"> Buruh</option>
                                            <option value="Pensiunan"> Pensiunan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Penghasilan Ibu  <span className='text-danger'>*</span></label>
                                        <select
                                            name='penghasilan_ibu'
                                            className={`form-select ${errors.penghasilan_ibu ? 'is-invalid' : ''}`}
                                            value={formData.penghasilan_ibu}
                                            onChange={(e) => handleChange(e)}>

                                            <option value="">Pilih Penghasilan</option>
                                            <option value="Rp. 500.000-Rp.999.999">Rp. 500.000-Rp.999.999</option>
                                            <option value="Rp. 1.000.000-Rp.1.999.999">Rp. 1.000.000-Rp.1.999.999</option>
                                            <option value="Rp.2.000.000-Rp.4.999.999">Rp.2.000.000-Rp.4.999.999</option>
                                            <option value="Rp.5.000.000-Rp.20.000.000">Rp.5.000.000-Rp.20.000.000</option>
                                            <option value="diatas Rp.20.000.000"> diatas Rp.20.000.000</option>
                                            <option value="Tidak Berpenghasilan"> Tidak Berpenghasilan</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>No HP Ibu <span className='text-danger'>*</span></label>
                                        <input
                                            type='number'
                                            className={`form-control ${errors.no_telp_ibu ? 'is-invalid' : ''}`}
                                            defaultValue={formData.no_telp_ibu}
                                            onChange={(e) => handleChange(e)}
                                            name='no_telp_ibu'
                                        />
                                        {errors.no_telp_ibu && <p style={{ color: "red" }}>{errors.no_telp_ibu}</p>}

                                    </div>
                                </div>

                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>

                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>

                        </div>
                    )}
                    {currentStep === 3 && (
                        <div className='card-box shadow-sm p-4 mb-4 mt-4'>
                            <div className='row'>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Ada Wali?</label>
                                        <div className="form-inline">
                                            <input type="radio" name="ada_wali" id="wali" className={'form-check'}
                                                onChange={(e) => handleChange(e)} value={'Ya'}
                                            /> Ya
                                        </div>
                                        <div className="form-inline">
                                            <input type="radio" name="ada_wali" id="wali" className={'form-check'}
                                                onChange={(e) => handleChange(e)} value={'Tidak'}
                                            /> Tidak
                                        </div>
                                        {/* <input
                                            type='text'
                                            className={`form-control ${errors.nama_wali ? 'is-invalid' : ''}`}
                                            value={formData?.nama_wali}
                                            onChange={(e) => handleChange(e)}
                                            name='nama_wali'
                                        /> */}
                                    </div>
                                </div>
                            </div>

                            {formData.ada_wali == 'Ya' ? (
                                <div className='row'>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Nama Wali</label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.nama_wali ? 'is-invalid' : ''}`}
                                                value={formData?.nama_wali}
                                                onChange={(e) => handleChange(e)}
                                                name='nama_wali'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>NIK Wali</label>
                                            <input
                                                type='number'
                                                className={`form-control ${errors.nik_wali ? 'is-invalid' : ''}`}
                                                value={formData?.nik_wali}
                                                onChange={(e) => handleChangeNumber(e,16)}
                                                name='nik_wali'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Jenis Kelamin</label>
                                            <select
                                                name='jk_wali'
                                                className={`form-select ${errors.jk_wali ? 'is-invalid' : ''}`}
                                                value={formData.jk_wali}
                                                onChange={(e) => handleChange(e)}>
                                                {/* <option value="" disabled>{formData.jk_wali ? formData.jk_wali : "Pilih Jenis Kelamin"}</option> */}
                                                <option value="">Pilih Jenis Kelamin</option>
                                                <option value="P">P</option>
                                                <option value="L">L</option>
                                            </select>
                                            {errors.jk_wali && <p style={{ color: "red" }}>{errors.jk_wali}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Tempat Lahir Wali</label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.tempat_lahir_wali ? 'is-invalid' : ''}`}
                                                value={formData?.tempat_lahir_wali}
                                                onChange={(e) => handleChange(e)}
                                                name='tempat_lahir_wali'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Tanggal Lahir Wali</label>
                                            <input
                                                type='date'
                                                className={`form-control ${errors.tanggal_lahir_wali ? 'is-invalid' : ''}`}
                                                value={formData?.tanggal_lahir_wali}
                                                onChange={(e) => handleChange(e)}
                                                name='tanggal_lahir_wali'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Pendidikan Wali</label>
                                            <select
                                                name='pendidikan_wali'
                                                className={`form-select ${errors.pendidikan_wali ? 'is-invalid' : ''}`}
                                                value={formData.pendidikan_wali}
                                                onChange={(e) => handleChange(e)}>

                                                <option value="">Pilih Pendidikan</option>
                                                <option value="Tidak Sekolah">Tidak Sekolah</option>
                                                <option value="Putus SD">Putus SD</option>
                                                <option value="SD Sederajat">SD Sederajat</option>
                                                <option value="SMP Sederajat">SMP Sederajat</option>
                                                <option value="SMA Sederajat">SMA Sederajat</option>
                                                <option value="D1">D1</option>
                                                <option value="D2">D2</option>
                                                <option value="D3">D3</option>
                                                <option value="D4/S1"> D4/S1</option>
                                                <option value="S2">S2</option>
                                                <option value="S3">S3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Pekerjaan Wali</label>
                                            <select
                                                name='pekerjaan_wali'
                                                className={`form-select ${errors.pekerjaan_wali ? 'is-invalid' : ''}`}
                                                value={formData.pekerjaan_wali}
                                                onChange={(e) => handleChange(e)}>

                                                <option value="">Pilih Perkerjaan</option>
                                                <option value="Tidak Bekerja">Tidak Bekerja</option>
                                                <option value="Nelayan">Nelayan</option>
                                                <option value="Petani">Petani</option>
                                                <option value="Peternak">Peternak</option>
                                                <option value="PNS/TNI/POLRI"> PNS/TNI/POLRI</option>
                                                <option value="Karyawan Swasta"> Karyawan Swasta</option>
                                                <option value="Pedagang Kecil"> Pedagang Kecil</option>
                                                <option value="Pedagang Besar"> Pedagang Besar</option>
                                                <option value="Wiraswasta"> Wiraswasta</option>
                                                <option value="Wirausaha"> Wirausaha</option>
                                                <option value="Buruh"> Buruh</option>
                                                <option value="Pensiunan"> Pensiunan</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Penghasilan Wali</label>
                                            <select
                                                name='penghasilan_wali'
                                                className={`form-select ${errors.penghasilan_wali ? 'is-invalid' : ''}`}
                                                value={formData.penghasilan_wali}
                                                onChange={(e) => handleChange(e)}>

                                                <option value="">Pilih Penghasilan</option>
                                                <option value="Rp. 500.000-Rp.999.999">Rp. 500.000-Rp.999.999</option>
                                                <option value="Rp. 1.000.000-Rp.1.999.999">Rp. 1.000.000-Rp.1.999.999</option>
                                                <option value="Rp.2.000.000-Rp.4.999.999">Rp.2.000.000-Rp.4.999.999</option>
                                                <option value="Rp.5.000.000-Rp.20.000.000">Rp.5.000.000-Rp.20.000.000</option>
                                                <option value="diatas Rp.20.000.000"> diatas Rp.20.000.000</option>
                                                <option value="Tidak Berpenghasilan"> Tidak Berpenghasilan</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>No HP Wali <span className='text-danger'>*</span></label>
                                            <input
                                                type='number'
                                                className={`form-control ${errors.no_telp_wali ? 'is-invalid' : ''}`}
                                                defaultValue={formData.no_telp_wali}
                                                onChange={(e) => handleChange(e)}
                                                name='no_telp_wali'
                                            />
                                            {errors.no_telp_wali && <p style={{ color: "red" }}>{errors.no_telp_wali}</p>}

                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}

                    {currentStep === 4 && (
                        <div className='card-box shadow-sm p-4'>
                            <div className='row'>
                                <div className="col-md-8 col-sm-12">
                                    <div className="form-group">
                                        <label>Apakah punya sodara yang sekarang sekolah di Wikrama atau Alumni Wikrama?</label>
                                        <div className="form-inline">
                                            <input type="radio" name="ada_referensi" id="referensi" className={'form-check'}
                                                onChange={(e) => handleChange(e)} value={'Ya'}
                                            /> Ya
                                        </div>
                                        <div className="form-inline">
                                            <input type="radio" name="ada_referensi" id="referensi" className={'form-check'}
                                                onChange={(e) => handleChange(e)} value={'Tidak'}
                                            /> Tidak
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {formData.ada_referensi == 'Ya' ? (
                                <div className='row'>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Nama Siswa/Alumni</label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.nama_referensi ? 'is-invalid' : ''}`}
                                                value={formData?.nama_referensi}
                                                onChange={(e) => handleChange(e)}
                                                name='nama_referensi'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Rayon</label>
                                            <select
                                                name='referensi_rayon'
                                                className={`form-select ${errors.referensi_rayon ? 'is-invalid' : ''}`}
                                                value={formData.referensi_rayon}
                                                onChange={(e) => handleChange(e)}>

                                                <option value="">Pilih Rayon</option>
                                                {rayons.map((rayon) => (
                                                    <option value={rayon.nama_rayon}>{rayon.nama_rayon}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>Apakah pernah mendapat beasiswa di Wikrama?</label>
                                            <div className="form-inline">
                                                <input type="radio" name="referensi_penerima_beasiswa" id="referensi_penerima_beasiswa" className={'form-check'}
                                                    onChange={(e) => handleChange(e)} value={'Ya'}
                                                /> Ya
                                            </div>
                                            <div className="form-inline">
                                                <input type="radio" name="referensi_penerima_beasiswa" id="referensi_penerima_beasiswa" className={'form-check'}
                                                    onChange={(e) => handleChange(e)} value={'Tidak'}
                                                /> Tidak
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="button" className="btn btn-primary ml-2 bg-blue" onClick={nextStep}>Selanjutnya</button>
                        </div>
                    )}

                    {currentStep === 5 && (
                        <div className='card-box shadow-sm p-4'>
                            <div className="row">

                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>Persetujuan dan Kirim <span className='text-danger'>*</span></label>
                                        <div className="form-inline">
                                            <input type="checkbox" name="status_data_family" id="status_data_family" className={'form-check mr-1'}
                                                onChange={(e) => handleChange(e)} value={'Konfirmasi'} required={'required'}
                                            /> Dengan ini saya menyetujui data yang saya isi sesuai dengan data Ijazah SMP, Ijazah SD, Akta Kelahiran dan KK
                                        </div>
                                        {errors.status_data_family && <p style={{ color: "red" }}>{errors.status_data_family}</p>}

                                    </div>
                                </div>

                            </div>
                            <button type="button" className='btn  btn-danger' onClick={prevStep}>
                                Sebelumnya
                            </button>
                            <button type="submit" className='btn bg-blue text-white ml-3'>Kirim</button>
                        </div>
                    )}
                </form>
            </LayoutDashboard>
        </div>
    )
}
