import React, { useEffect, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditPendidikan = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        'bidang_studi': "",
        "jenjang_pendidikan": "", // Ubah menjadi string kosong untuk select option
        "gelar_akademik": "",
        "satuan_pendidikan": "",
        "tahun_masuk": "",
        "tahun_keluar": "",
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/pendidikan/${id}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    },
                });
                setFormData(response.data.pendidikan);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const jenjangOptions = [
        { value: "1", label: "PAUD" },
        { value: "2", label: "TK / sederajat" },
        { value: "3", label: "Putus SD" },
        { value: "4", label: "SD / sederajat" },
        { value: "5", label: "SMP / sederajat" },
        { value: "6", label: "SMA / sederajat" },
        { value: "7", label: "Paket A" },
        { value: "8", label: "Paket B" },
        { value: "9", label: "Paket C" },
        { value: "20", label: "D1" },
        { value: "21", label: "D2" },
        { value: "22", label: "D3" },
        { value: "23", label: "D4" },
        { value: "30", label: "S1" },
        { value: "31", label: "Profesi" },
        { value: "32", label: "Sp-1" },
        { value: "35", label: "S2" },
        { value: "36", label: "S2 Terapan" },
        { value: "37", label: "Sp-2" },
        { value: "40", label: "S3" },
        { value: "41", label: "S3 Terapan" },
        { value: "90", label: "Non formal" },
        { value: "91", label: "Informal" },
    ];
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/pendidikan/update/${id}`, formData);
                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/dashboard/pembimbing/pendidikan/show'));
            } catch (error) {
                console.log(error, "error");
            }
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Edit Data Pendidikan" tiga="Lainnya" empat="Pendidikan" urlEmpat="/dashboard/pembimbing/pendidikan/show" urlSatu="/dashboard/pembimbing" />

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='bidang_studi' className='form-label'>Bidang Studi :</label>
                                    <input type='text' name='bidang_studi'
                                        className={`form-control ${errors.bidang_studi ? 'is-invalid' : ''}`}
                                        value={formData.bidang_studi}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.bidang_studi && <p style={{ color: "red" }}>{errors.bidang_studi}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='jenjang_pendidikan' className='form-label'>Jenjang Pendidikan :</label>
                                <select name='jenjang_pendidikan'
                                    className={`form-control ${errors.jenjang_pendidikan ? 'is-invalid' : ''}`}
                                    value={formData.jenjang_pendidikan}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value="">Pilih Jenjang Pendidikan</option>
                                    {jenjangOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                                {errors.jenjang_pendidikan && <p style={{ color: "red" }}>{errors.jenjang_pendidikan}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='gelar_akademik' className='form-label'>Gelar Akademik :</label>
                                    <input type='text' name='gelar_akademik'
                                        className={`form-control ${errors.gelar_akademik ? 'is-invalid' : ''}`}
                                        value={formData.gelar_akademik}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.gelar_akademik && <p style={{ color: "red" }}>{errors.gelar_akademik}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='satuan_pendidikan' className='form-label'>Satuan Pendidikan :</label>
                                <input type='text' name='satuan_pendidikan'
                                    className={`form-control ${errors.satuan_pendidikan ? 'is-invalid' : ''}`}
                                    value={formData.satuan_pendidikan}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.satuan_pendidikan && <p style={{ color: "red" }}>{errors.satuan_pendidikan}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tahun_masuk' className='form-label'>Tahun Masuk :</label>
                                    <input type='number' name='tahun_masuk'
                                        className={`form-control ${errors.tahun_masuk ? 'is-invalid' : ''}`}
                                        value={formData.tahun_masuk}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.tahun_masuk && <p style={{ color: "red" }}>{errors.tahun_masuk}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tahun_keluar' className='form-label'>Tahun Keluar :</label>
                                <input type='number' name='tahun_keluar'
                                    className={`form-control ${errors.tahun_keluar ? 'is-invalid' : ''}`}
                                    value={formData.tahun_keluar}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.tahun_keluar && <p style={{ color: "red" }}>{errors.tahun_keluar}</p>}
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>

                    </div>

                </form>


            </LayoutDashboard>

        </div>

    )
}
