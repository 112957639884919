import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const CreatePendidikan = () => {

    const navigate = useNavigate();

    const { gtk } = useContext(GtkContext)
    const gtkId = gtk?.gtk?._id
    const [formData, setFormData] = useState({
        'bidang_studi': "",
        "jenjang_pendidikan": "", // Ubah menjadi string kosong untuk select option
        "gelar_akademik": "",
        "satuan_pendidikan": "",
        "tahun_masuk": "",
        "tahun_keluar": "",
    })

    const [errors, setErrors] = useState({});

    const jenjangOptions = [
        { value: "PAUD", label: "PAUD" },
        { value: "TK / sederajat", label: "TK / sederajat" },
        { value: "Putus SD", label: "Putus SD" },
        { value: "SD / sederajat", label: "SD / sederajat" },
        { value: "SMP / sederajat", label: "SMP / sederajat" },
        { value: "SMA / sederajat", label: "SMA / sederajat" },
        { value: "Paket A", label: "Paket A" },
        { value: "Paket B", label: "Paket B" },
        { value: "Paket C", label: "Paket C" },
        { value: "D1", label: "D1" },
        { value: "D2", label: "D2" },
        { value: "D3", label: "D3" },
        { value: "D4", label: "D4" },
        { value: "S1", label: "S1" },
        { value: "Profesi", label: "Profesi" },
        { value: "Sp-1", label: "Sp-1" },
        { value: "S2", label: "S2" },
        { value: "S2 Terapan", label: "S2 Terapan" },
        { value: "Sp-2", label: "Sp-2" },
        { value: "S3", label: "S3" },
        { value: "S3 Terapan", label: "S3 Terapan" },
        { value: "Non Formal", label: "Non Formal" },
        { value: "Informal", label: "Informal" },
    ];
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : `wajib diisi` }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            if (gtk && gtkId) {
                try {
                    await axios.post(`${apiEndpoint}/pendidikan/create/${gtkId}`, { ...formData });
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Berhasil Create Data',
                        icon: 'success',
                    }).then(() => navigate('/dashboard/pembimbing/pendidikan/show'));
                } catch (error) {
                    console.log(error.response?.data?.error || 'Error');
                }
            } else {
                console.log('Tidak ada ID.');
            }
        }
    }   

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Tambah Data Pendidikan" tiga="Lainnya" empat="Pendidikan" urlEmpat="/dashboard/pembimbing/pendidikan/show" urlSatu="/dashboard/pembimbing" />

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='bidang_studi' className='form-label'>Bidang Studi :</label>
                                    <input type='text' name='bidang_studi'
                                        className={`form-control ${errors.bidang_studi ? 'is-invalid' : ''}`}
                                        value={formData.bidang_studi}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.bidang_studi && <p style={{ color: "red" }}>{errors.bidang_studi}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='jenjang_pendidikan' className='form-label'>Jenjang Pendidikan :</label>
                                <select name='jenjang_pendidikan'
                                    className={`form-control ${errors.jenjang_pendidikan ? 'is-invalid' : ''}`}
                                    value={formData.jenjang_pendidikan}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value="">Pilih Jenjang Pendidikan</option>
                                    {jenjangOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                                {errors.jenjang_pendidikan && <p style={{ color: "red" }}>{errors.jenjang_pendidikan}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='gelar_akademik' className='form-label'>Gelar Akademik :</label>
                                    <input type='text' name='gelar_akademik'
                                        className={`form-control ${errors.gelar_akademik ? 'is-invalid' : ''}`}
                                        value={formData.gelar_akademik}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.gelar_akademik && <p style={{ color: "red" }}>{errors.gelar_akademik}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='satuan_pendidikan' className='form-label'>Satuan Pendidikan :</label>
                                <input type='text' name='satuan_pendidikan'
                                    className={`form-control ${errors.satuan_pendidikan ? 'is-invalid' : ''}`}
                                    value={formData.satuan_pendidikan}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.satuan_pendidikan && <p style={{ color: "red" }}>{errors.satuan_pendidikan}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tahun_masuk' className='form-label'>Tahun Masuk :</label>
                                    <input type='number' name='tahun_masuk'
                                        className={`form-control ${errors.tahun_masuk ? 'is-invalid' : ''}`}
                                        value={formData.tahun_masuk}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.tahun_masuk && <p style={{ color: "red" }}>{errors.tahun_masuk}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tahun_keluar' className='form-label'>Tahun Keluar :</label>
                                <input type='number' name='tahun_keluar'
                                    className={`form-control ${errors.tahun_keluar ? 'is-invalid' : ''}`}
                                    value={formData.tahun_keluar}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.tahun_keluar && <p style={{ color: "red" }}>{errors.tahun_keluar}</p>}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </LayoutDashboard>
        </div>
    )
}
