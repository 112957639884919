import React, { useContext, useEffect, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import StudentContext from '../../../../context/StudentContext';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditDataSmpPage = () => {
    const navigate = useNavigate();
    const { student, getStudent } = useContext(StudentContext);

    const [formData, setFormData] = useState({
        ijazah_smp: null,
        akte: null,
        kk: null,
        ijazah_sd: null,
        ktp_ayah: null,
        ktp_ibu: null,
    });

    const [input, setInput] = useState({
        status_data_dokumen: 'Pending'
    });

    const [errors, setErrors] = useState({});

    const validateFile = (file, acceptedFormats, maxSize) => {
        const allowedFormats = new RegExp(`(${acceptedFormats.join('|')})$`, 'i');
        if (!file) return 'Wajib diisi';
        if (!allowedFormats.test(file.name)) return 'Format file tidak valid. Harap pilih file JPG atau PDF.';
        if (file.size > maxSize) return 'Ukuran file terlalu besar. Maksimal 2 MB.';
        return '';
    };

    const handleChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        const errorMessage = validateFile(file, ['jpg', 'jpeg', 'pdf'], 2 * 1024 * 1024);

        setFormData((prevData) => ({ ...prevData, [name]: file }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));

        if (!formData.status_data_dokumen) {
            setErrors((prevErrors) => ({...prevErrors, 'status_data_dokumen': 'Wajib Diceklis'}));
        }
    };
    
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setInput((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value ? "" : "Wajib diisi",
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, validateFile(value, ['jpg', 'jpeg', 'pdf'], 2 * 1024 * 1024)])
        );

        if (Object.values(formErrors).some((error) => error !== '')) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            Swal.fire({
                title: 'Loading...',
                text: 'Sedang memproses, harap tunggu.',
                allowOutsideClick: false,
                showConfirmButton: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                const data = new FormData();
                Object.entries(formData).forEach(([key, value]) => {
                    data.append(key, value);
                });

                for (let pair of data.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }

                data.append('status_data_dokumen', input.status_data_dokumen)

                const response = await axios.post(`${apiEndpoint}/student/upload-s3/${student?._id}`, data);
                console.log(response, "ini data response");
                getStudent();
                Swal.fire({
                    title: 'Success!',
                    text: 'Dokumen berhasil diunggah.',
                    icon: 'success',
                }).then(() => navigate('/dashboard/data-diri'));
            } catch (error) {
                console.error(error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Gagal mengunggah data. Silakan coba lagi.',
                    icon: 'error',
                });
            }
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Data Siswa" dua="Edit Data Dokumen" urlSatu="/dashboard/data-diri" />
                <div className='card-box shadow-sm p-4'>
                    <form onSubmit={handleSubmit} encType='multipart/form-data'>
                        <div className='row'>
                            {['ijazah_sd', 'ijazah_smp', 'akte', 'ktp_ayah', 'ktp_ibu', 'kk'].map((field, idx) => (
                                <div key={idx} className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label htmlFor={`formFile-${field}`} className="form-label text-capitalize">
                                            {field.replace('_', ' ')} <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors[field] ? 'is-invalid' : ''}`}
                                            type="file"
                                            id={`formFile-${field}`}
                                            name={field}
                                            onChange={handleChange}
                                        />
                                        {errors[field] && <p className='text-danger'>{errors[field]}</p>}
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="row">

                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label>Persetujuan dan Kirim <span className='text-danger'>*</span></label>
                                    <div className="form-inline">
                                        <input type="checkbox" name="status_data_dokumen" id="status_data_dokumen" className={'form-check mr-1'}
                                            onChange={(e) => handleChangeInput(e)} value={'Konfirmasi'} required
                                        /> Dengan ini saya menyetujui data yang saya isi sesuai dengan data Ijazah SMP, Ijazah SD, Akta Kelahiran dan KK
                                    </div>
                                    {errors.status_data_dokumen && <p style={{ color: "red" }}>{errors.status_data_dokumen}</p>}

                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Simpan</button>
                    </form>
                </div>
            </LayoutDashboard>
        </div>
    );
};
