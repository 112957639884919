import React, { useContext, useState } from 'react';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import { RombelContext } from '../../../../context/RombelContext';
import { Link, useNavigate } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import { confirmDelete } from '../../../../function/delete';
import { Kosong } from '../../../../components/globalcomp/Kosong';

export const RombelPage = () => {
  const { rombels, getRombels } = useContext(RombelContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const filteredRombels = rombels.filter((rombel) =>
    rombel.nama_rombel.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredRombels.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const navigate = useNavigate();

  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Home" tiga="Master Data" dua="Rombel" urlSatu="/admin" />
        <div className="card-box  shadow-sm p-4 mb-30">
          <div className='d-flex justify-content-between'>
            <Link to="/admin/rombel/create">
              <button className='btn bg-blue text-white rounded'>
                <BiPlus /> Tambah Rombel
              </button>
            </Link>
            <div>
              <input
                type="search"
                className='form-control'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Cari nama rombel..."
              />
            </div>
          </div>

          <table className="table mt-3  table-borderless">
            <thead className='thead-dark  custom-header'>
              <tr>
                <th>#</th>
                <th>Nama</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {rombels && rombels.length > 0 ? (
                currentItems.length > 0 ? (
                  currentItems.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.nama_rombel}</td>
                      <td>
                        <Link to={`/admin/rombel/edit/${item._id}`} className='btn btn-warning btn-sm text-white mr-2'>
                          <BsPencilFill /> Ubah
                        </Link>
                        <button className='btn btn-danger btn-sm' onClick={() => confirmDelete(item._id, 'delete-rombel', getRombels, navigate, "/admin/rombel")}>
                          <BsTrashFill /> Hapus
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">Data tidak ditemukan</td>
                  </tr>
                )
              ) : (
                <Kosong />
              )}
            </tbody>

          </table>

          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <button className="page-link" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
              </li>
              {Array.from({ length: Math.ceil(filteredRombels.length / itemsPerPage) }).map((_, index) => (
                <li key={index} className={` page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => paginate(index + 1)}>{index + 1}</button>
                </li>
              ))}
              <li className="page-item">
                <button className="page-link" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredRombels.length / itemsPerPage)}>Next</button>
              </li>
            </ul>
          </nav>
        </div>
      </LayoutDashboard>
    </div>
  );
};
