import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { BsPencil, BsTrash } from 'react-icons/bs'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { Information } from '../../../../components/globalcomp/Information'

export const ShowAnak = () => {

    const { gtk, getGtk } = useContext(GtkContext)
    const [getAnak, setAnak] = useState([])
    useEffect(() => {
        setAnak(gtk.gtk?.anak_id)
    }, [gtk.gtk])


    useEffect(() => {
        getGtk()
    }, [])

    const confirmDelete = (_id) => {
        console.log(_id)
        Swal.fire({
            title: 'Konfirmasi Hapus',
            text: 'Apakah anda yakin untuk menghapus data ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDelete(_id);
            }
        });
    };

    const handleDelete = (_id) => {
        axios.delete(process.env.REACT_APP_API_ENDPOINT + `/anak/delete/${_id}`)
            .then(() => {
                getGtk()
                Swal.fire('Sukses!', 'Data Berhasil Dihapus', 'success');
            })
            .catch((err) => {
                console.error(err);
                Swal.fire('Error', 'An error occurred while deleting the data.', 'error');
            });
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" tiga="Data Pribadi" urlSatu="/dashboard/pembimbing" dua="Data Anak" />

                <Information title="anak" />
                <div className='card-box shadow-sm p-4 mb-30'>
                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/anak/create">
                            <button style={{ marginBottom: '30px' }} type="button" className="btn text-white bg-blue mb-2">Tambah Data Anak</button>
                        </Link>
                    </div>
                    <table className="table table-borderless rounded">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nama</th>
                                <th scope="col">Jenis Kelamin</th>
                                <th scope="col">Anak Ke</th>
                                <th scope="col">Tempat Lahir</th>
                                <th scope="col">Tanggal Lahir</th>
                                <th scope="col">Jenjang Pendidikan</th>
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getAnak && getAnak.length > 0 ? (

                                getAnak?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.nama}</td>
                                        <td>{item.jk}</td>
                                        <td>{item.anak_ke}</td>
                                        <td>{item.tempat_lahir}</td>
                                        <td>{item.tanggal_lahir}</td>
                                        <td>{item.jenjang_pendidikan}</td>
                                        <td className='flex flex-row gap-1'>
                                            <Link to={`/dashboard/pembimbing/anak/edit/${item._id}`}>
                                                <button className='btn btn-warning btn-sm text-white'>
                                                    <BsPencil />
                                                </button>
                                            </Link>
                                            <button onClick={() => confirmDelete(item._id)} className='btn ml-2 btn-sm btn-danger'>
                                                <BsTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <Kosong />

                            )}

                        </tbody>
                    </table>
                </div>
            </LayoutDashboard>
        </div>
    )
}
