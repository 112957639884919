import axios from 'axios';
import Swal from 'sweetalert2';

const cancelVerifikasiStudent = (apiEndpoint, studentId, getGtk) => () => {  
  Swal.fire({
    title: 'Konfirmasi',
    text: 'Apakah Anda yakin ingin memperbarui data?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Ya',
    cancelButtonText: 'Batal'
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Mohon tunggu',
        html: 'Sedang memproses...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      axios.post(`${apiEndpoint}/${studentId}`)
        .then(response => {
          setTimeout(() => {
            Swal.fire('Berhasil', 'Data berhasil diperbarui!', 'success')
          }, 1000);
          getGtk();
        })
        .catch(error => {
          console.error('Error:', error);
          Swal.fire('Error', 'Terjadi kesalahan saat memperbarui data!', 'error');
        })
        .finally(() => {
          Swal.close();
        });
    }
  });
};

export default cancelVerifikasiStudent;