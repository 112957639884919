import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../../../context/AuthContext';
import { Kosong } from '../../../../../components/globalcomp/Kosong';
import { FaRegCheckCircle, FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const CekSmp = ({ studentData }) => {
    const { user } = useContext(AuthContext);
    const [cekSiswa, setCekSiswa] = useState({});
    const [selectedDocument, setSelectedDocument] = useState(null); // State untuk menyimpan data dokumen yang dipilih

    const allowedKeys = ["documentIjazahSmp", "documentAkte", "documentIjazahSd", "documentKk", "documentKtpAyah", "documentKtpIbu"];

    useEffect(() => {
        if (studentData) {
            const filteredData = Object.fromEntries(
                Object.entries(studentData.dokumen_id || {}).filter(([key, _]) => allowedKeys.includes(key))
            );
            setCekSiswa(filteredData);
        }
    }, [studentData]);

    const openModal = (documentName, documentUrl) => {
        setSelectedDocument({ documentName, documentUrl });
    };


    return (
        <div className="pd-20 mb-30">
            {user?.role === "student" && (
                cekSiswa && Object.keys(cekSiswa).length === allowedKeys.length ? (
                    cekSiswa.status_data_dokumen === "Pending" ? (
                        <div className="alert alert-info alert-dismissible fade show" role="alert">
                            <strong>Data Masih Pending!</strong> Pembimbing siswa akan mengecek datamu terlebih dahulu
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    ) : null
                ) : (
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Data Belum Lengkap!</strong> Segera Lengkapi Data
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                )
            )}
            <div className="clearfix">
                <div className="pull-left">
                    <h4 className="text-blue h4">Data Dokumen</h4>
                    {user.role === 'student' && <p className="mb-30">Lengkapi Data Jika Masih Kosong</p>}
                </div>

                {user.role === 'student' && (
                    <div className="pull-right">
                        {studentData?.status_data_dokumen === "Konfirmasi" ? (
                            <button
                                className="btn gap-2 btn-success"
                                disabled>
                                Menunggu Verifikasi
                                <FaSpinner className='ml-2' />
                            </button>
                        //     <Link
                        //     to="/dashboard/update/data-keluarga"
                        // >
                        //     <button className='btn btn-success py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                        //         Lengkapi Data Keluarga
                        //     </button>
                        // </Link>
                        ) : (studentData?.status_data_dokumen === 'Verifikasi' ? (
                            <button
                                className="btn gap-2 btn-success"
                                disabled>
                                Diverifikasi
                                <FaRegCheckCircle className='ml-2' />
                            </button>
                        ) : (
                            <Link
                                to="/dashboard/update/data-dokumen"
                            >
                                <button className='btn btn-success py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                                    Lengkapi Data Dokumen
                                </button>
                            </Link>
                        ))}

                        {/* {studentData?.status_data_dokumen === "Verifikasi" && Object.keys(cekSiswa).length === allowedKeys.length ? (
                            <button className="btn gap-2 btn-success" disabled>
                                Diverifikasi <FaRegCheckCircle className='ml-2' />
                            </button>
                        ) : (
                            studentData?.status_data_dokumen === "Pending" && Object.keys(cekSiswa).length === 0 ? (
                                <Link to="/dashboard/update/data-dokumen">
                                    <button className='btn btn-success py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                                        Lengkapi Data Dokumen
                                    </button>
                                </Link>
                            ) : null
                        )} */}
                    </div>
                )}

            </div>


            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Document</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(cekSiswa).length > 0 ? (
                        Object.entries(cekSiswa).map(([key, value]) => (
                            <tr key={key}>
                                <td className='font-weight-bold'>
                                    {key === "documentIjazahSmp" ? "Ijazah SMP" : ""}
                                    {key === "documentAkte" ? "Akte Kelahiran" : ""}
                                    {key === "documentIjazahSd" ? "Ijazah SD" : ""}
                                    {key === "documentKk" ? "Kartu Keluarga" : ""}
                                    {key === "documentKtpAyah" ? "Ktp Ayah" : ""}
                                    {key === "documentKtpIbu" ? "Ktp Ibu" : ""}
                                </td>
                                <td>
                                    {typeof value === "string" && value.toLowerCase().endsWith(".pdf") ? (
                                        <button className="btn btn-primary" onClick={() => window.open(`https://be-sidata.smkwikrama.sch.id/get-file-s3/${value}`, "_blank")}>
                                            Open PDF
                                        </button>
                                    ) : (
                                        <div data-toggle="modal" data-target={`#exampleModalLong-${key}`}>
                                            <img
                                                src={`https://be-sidata.smkwikrama.sch.id/get-file-s3/${value}`}
                                                alt={`Document ${key}`}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '150px',
                                                    height: '150px',
                                                    objectFit: 'cover',
                                                }}
                                                onClick={() => openModal(key, value)}
                                            />
                                        </div>
                                    )}
                                    <div class="modal fade" id={`exampleModalLong-${key}`} tabindex="-1" role="dialog" aria-labelledby={`show-image-${key}`} aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id={`show-image-${key}`}>{key === "documentIjazahSmp" ? "Ijazah SMP" : key === "documentAkte" ? "Akte Kelahiran" : key === "documentIjazahSd" ? "Ijazah SD" : key === "documentKk" ? "Kartu Keluarga" : key === "documentKtpAyah" ? "Ktp Ayah" : key === "documentKtpIbu" ? "Ktp Ibu" : "Dokumen"}</h5>
                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <img
                                                        src={`https://be-sidata.smkwikrama.sch.id/get-file-s3/${value}`}
                                                        alt={`Document ${key}`} width="100%"
                                                    />
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                    <button type="button" class="btn btn-primary">Save changes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2" className="text-center">Data dokumen kosong</td>
                        </tr>
                    )}
                </tbody>

            </table>
        </div>
    );
};

export default CekSmp;
