import React, { useEffect, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditInpassing = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        no_sk: '',
        tanggal_sk: '',
        tmt_sk: '',
        angka_kridit: '',
        masa_kerja_thn: '',
        masa_kerja_bln: '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/inpassing/${id}`,
                    {
                        headers: {
                            'ngrok-skip-browser-warning': 'true',
                        },
                    });
                setFormData(response.data.inpassing);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );
        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/inpassing/update/${id}`, formData);
                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/dashboard/pembimbing/inpassing/show'));
            } catch (error) {
                console.error('Error updating data:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Gagal Update Data. Silakan coba lagi.',
                    icon: 'error',
                });
            }
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Edit Data Inpassing" tiga="Lainnya" empat="Inpassing" urlEmpat="/dashboard/pembimbing/inpassing/show" urlSatu="/dashboard/pembimbing" />
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='no_sk' className='form-label'>No. SK :</label>
                                    <input
                                        type='number'
                                        className={`form-control ${errors.no_sk ? 'is-invalid' : ''}`}
                                        value={formData.no_sk}
                                        onChange={(e) => handleChange(e)}
                                        name='no_sk'
                                    />
                                    {errors.no_sk && <p style={{ color: "red" }}>{errors.no_sk}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tanggal_sk' className='form-label'>Tanggal SK :</label>
                                <input type='date'
                                    className={`form-control ${errors.tanggal_sk ? 'is-invalid' : ''}`}
                                    value={formData.tanggal_sk}
                                    onChange={(e) => handleChange(e)}
                                    name='tanggal_sk'
                                />
                                {errors.tanggal_sk && <p style={{ color: "red" }}>{errors.tanggal_sk}</p>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tmt_sk' className='form-label'>TMT SK :</label>
                                    <input type='date'
                                        className={`form-control ${errors.tmt_sk ? 'is-invalid' : ''}`}
                                        value={formData.tmt_sk}
                                        onChange={(e) => handleChange(e)}
                                        name='tmt_sk'
                                    />
                                    {errors.tmt_sk && <p style={{ color: "red" }}>{errors.tmt_sk}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='angka_kridit' className='form-label'>Angka Kridit :</label>
                                <input type='text'
                                    className={`form-control ${errors.angka_kridit ? 'is-invalid' : ''}`}
                                    value={formData.angka_kridit}
                                    onChange={(e) => handleChange(e)}
                                    name='angka_kridit'
                                />
                                {errors.angka_kridit && <p style={{ color: "red" }}>{errors.angka_kridit}</p>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='masa_kerja_thn' className='form-label'>Masa Kerja (Tahun) :</label>
                                    <input type='number'
                                        className={`form-control ${errors.masa_kerja_thn ? 'is-invalid' : ''}`}
                                        value={formData.masa_kerja_thn}
                                        onChange={(e) => handleChange(e)}
                                        name='masa_kerja_thn' />
                                    {errors.masa_kerja_thn && <p style={{ color: "red" }}>{errors.masa_kerja_thn}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='masa_kerja_bln' className='form-label'>Masa Kerja (Bulan) :</label>
                                <input type='number'
                                    className={`form-control ${errors.masa_kerja_bln ? 'is-invalid' : ''}`}
                                    value={formData.masa_kerja_bln}
                                    onChange={(e) => handleChange(e)}
                                    name='masa_kerja_bln' />
                                {errors.masa_kerja_bln && <p style={{ color: "red" }}>{errors.masa_kerja_bln}</p>}

                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </LayoutDashboard>
        </div>
    )
}
