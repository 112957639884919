import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../components/globalcomp/GelBreadcrumb'
import GtkContext from '../../../context/GtkContext'
import { Link } from 'react-router-dom';
import { BsEyeFill, BsTrashFill } from 'react-icons/bs';
import handleValidateStudent from '../../../function/verifikasi';
import cancelVerifikasiStudent from '../../../function/cancelVerifikasi';

export const StudentValidate = () => {
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
  const [searchTerm, setSearchTerm] = useState('');

  const [getGtkData, setGtk] = useState([])
  const { gtk, getGtk } = useContext(GtkContext);
  useEffect(() => {
    document.title = "Data Siswa";
    setGtk(gtk.student)
  }, [gtk])

  const filtersStudents = getGtkData?.filter((item) =>
    item.nis.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.nama.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];


  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Home" dua="Data Siswa" />
        <div className='card-box shadow-sm p-4 mb-5'>
          <div className='d-flex justify-content-between'>
            <div></div>
            <div>
              <input
                type="search"
                className='form-control'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Cari nama atau NIS..."
              />
            </div>
          </div>
          <div class="table-responsive">
            <table className="table mt-3  table-borderless">
              <thead className='thead-dark  custom-header'>
                <tr>
                  <th>#</th>
                  <th>Nama Lengkap</th>
                  <th>NIS</th>
                  <th>Rombel</th>
                  <th>Rayon</th>
                  <th>Detail</th>
                  <th>Data Diri</th>
                  <th>Data Keluarga</th>
                  <th>Data Dokumen</th>
                  <th>Aksi</th>
                </tr>
              </thead>
              <tbody>
                {filtersStudents.length > 0 ? (
                  filtersStudents.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item.nama}</td>
                      <td>{item.nis}</td>
                      <td>{item.rombel}</td>
                      <td>{item.rayon}</td>

                      <td>
                        <Link to={`/dashboard/student/detail/${item._id}`}>
                          <button className='btn btn-info text-white rounded-md'><BsEyeFill /> Detail</button>
                        </Link>
                      </td>
                      <td>
                          {
                            item.status_data_diri === "Pending" || item.status_data_diri === "Konfirmasi" ? (
                              (item.status_data_diri === "Pending") ? (
                                <button className='btn btn-warning ml-3'>
                                  Belum Isi
                                </button>
                              ) : (
                                <button className='btn btn-success ml-3' onClick={handleValidateStudent(`${apiEndpoint}/verifikasi-data`, item._id, getGtk)}>
                                  Sudah Isi
                                </button>
                              )
                            ) : (

                                <div class="dropdown">
                                  <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                   Divalidasi PS
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" onClick={cancelVerifikasiStudent(`${apiEndpoint}/cancel-verifikasi-data`, item._id, getGtk)}>Cancel</a></li>
                                  </ul>
                                </div>
                            )
                          }

                        </td>
                        <td>

                        {
                            item.status_data_family === "Pending" || item.status_data_family === "Konfirmasi" ? (
                              (item.status_data_family === "Pending") ? (
                                <button className='btn btn-warning ml-3'>
                                  Belum Isi
                                </button>
                              ) : (
                                <button className='btn btn-success ml-3' onClick={handleValidateStudent(`${apiEndpoint}/verifikasi-family`, item._id, getGtk)}>
                                  Sudah Isi
                                </button>
                              )
                            ) : (

                                <div class="dropdown">
                                  <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                   Divalidasi PS
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" onClick={cancelVerifikasiStudent(`${apiEndpoint}/cancel-verifikasi-family`, item._id, getGtk)}>Cancel</a></li>
                                  </ul>
                                </div>
                            )
                          }

                          </td>
                          <td>

                          {
                            item.status_data_dokumen === "Pending" || item.status_data_dokumen === "Konfirmasi" ? (
                              (item.status_data_dokumen === "Pending") ? (
                                <button className='btn btn-warning ml-3'>
                                  Belum Isi
                                </button>
                              ) : (
                                <button className='btn btn-success ml-3' onClick={handleValidateStudent(`${apiEndpoint}/verifikasi-dokumen`, item._id, getGtk)}>
                                  Sudah Isi
                                </button>
                              )
                            ) : (

                                <div class="dropdown">
                                  <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                   Divalidasi PS
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" onClick={cancelVerifikasiStudent(`${apiEndpoint}/cancel-verifikasi-dokumen`, item._id, getGtk)}>Cancel</a></li>
                                  </ul>
                                </div>
                            )
                          }
                      </td>
                      <td>
                        <div className='gap-2 flex flex-row min-w-[200px]'>
                          <button className='btn btn-danger ml-3' >
                            <BsTrashFill /> Hapus
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">Data tidak ditemukan</td>
                  </tr>
                )}

              </tbody>

            </table>
          </div>

        </div>

      </LayoutDashboard>
    </div>
  )
}
