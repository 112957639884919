import React from 'react'

export const JaringanError = () => {
    return (
        <div
            className="error-page d-flex align-items-center flex-wrap justify-content-center pd-20"
        >
            <div className="pd-10">
                <div className="error-page-wrap text-center">
                    <h1>500</h1>
                    <h3>Error: Terjadi Kesalahan Server</h3>
                  
                   
                </div>
            </div>
        </div>

    )
}
