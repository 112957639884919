import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { BsPencil, BsTrashFill } from 'react-icons/bs'

import { Information } from '../../../../components/globalcomp/Information'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { confirmDelete } from '../../../../function/delete'

export const ShowTugasTambahan = () => {

    document.title = "Data Tugas Tambahan";

    const { gtk, getGtk } = useContext(GtkContext)
    const [getTugasTambahan, setTugasTambahan] = useState([])


    useEffect(() => {
        setTugasTambahan(gtk?.gtk?.tugas_tambahan_id)
    }, [gtk.gtk])

    useEffect(() => {
        getGtk()
    }, [])


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" tiga="Lainnya" urlSatu="/dashboard/pembimbing" dua="Data Tugas Tambahan" />
                <Information title="tugas tambahan" />
                <div className='card-box shadow-sm p-4 mb-30'>
                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/tugas-tambahan/create">
                            <button type="button" className="btn btn-primary mb-2 text-white bg-blue">Tambah Data Tugas Tambahaan</button>
                        </Link>
                    </div>
                    <table className="table table-borderless">
                        <thead className='thead-dark'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Jabatan</th>
                                <th scope="col">No SK Tugas Tambahan</th>
                                <th scope="col">TMT Tugas Tambahan</th>
                                <th scope="col">TST Tugas Tambahan</th>
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getTugasTambahan && getTugasTambahan.length > 0 ? (
                                getTugasTambahan.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.jabatan}</td>
                                        <td>{item.no_sk}</td>
                                        <td>{item.tmt_tambahan}</td>
                                        <td>{item.tst_tambahan}</td>
                                        <td className='flex flex-row gap-1'>
                                            <Link to={`/dashboard/pembimbing/tugas-tambahan/edit/${item._id}`}>
                                                <button className='btn btn-warning text-white btn-sm'>
                                                    <BsPencil /> Ubah
                                                </button>
                                            </Link>
                                            <button
                                                className='btn btn-danger ml-3 btn-sm'
                                                onClick={() => confirmDelete(item._id, 'tugas/delete', getGtk)}
                                            >
                                                <BsTrashFill /> Hapus
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <Kosong />
                            )}
                        </tbody>

                    </table>
                </div>



            </LayoutDashboard>
        </div>
    )
}
