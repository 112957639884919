import React, { useEffect, useContext, useState } from 'react';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import { RayonContext } from '../../../../context/RayonContext';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import { BiPlus } from 'react-icons/bi';
import { confirmDelete } from '../../../../function/delete';
import { Kosong } from '../../../../components/globalcomp/Kosong';
import Swal from 'sweetalert2';
import axios from 'axios';



const apiEndPoint = process.env.REACT_APP_API_ENDPOINT + "/delete-rayon";
export const RayonPage = () => {

  const { rayons, getRayons } = useContext(RayonContext);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    document.title = 'Data Rayon | Sidata';
  }, []);


  const filter = rayons.filter((rombel) =>
    rombel.nama_rayon?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const navigate = useNavigate();

  const confirmDelete = async (_id) => {
    try {
      const { value: confirmed } = await Swal.fire({
        title: 'Konfirmasi Hapus',
        text: 'Apakah anda yakin untuk menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        allowOutsideClick: () => !Swal.isLoading(),
      });

      if (!confirmed) {
        Swal.fire('Batal', 'Penghapusan data dibatalkan', 'info');
        return;
      }

      Swal.fire({
        title: 'Sedang Menghapus...',
        text: 'Mohon tunggu',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await axios.delete(`${apiEndPoint}/${_id}`);

      if (response.status === 200) {
        getRayons();

        Swal.fire({
          title: 'Sukses!',
          text: 'Data Berhasil Dihapus',
          icon: 'success',
        });

        navigate("/admin/rayon/show");
      } else {
        throw new Error('Gagal menghapus data');
      }
    } catch (error) {
      console.log(error, "ini kenapa")
      Swal.fire('error', 'Terjadi kesalahan saat menghapus data.', 'error');
    }
  };

  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Home" tiga="Master Data" dua="Rayon" urlSatu="/admin" />
        <div className='card-box  shadow-sm p-4'>
          <div className='d-flex mb-3 justify-content-between'>
            <Link to="/admin/rayon/create">
              <button className='btn bg-blue  text-white rounded'>
                <BiPlus /> Tambah Rayon
              </button>
            </Link>
            <div>
              <input
                type="search"
                className='form-control'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Cari nama rayon..."
              />
            </div>
          </div>
          <table className="table-borderless  table">
            <thead className='thead-dark'>
              <tr>
                <th>No</th>
                <th>Rayon</th>
                <th>Nama PJ</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody>
              {rayons && rayons?.length > 0 ? (
                filter.length > 0 ? (
                  filter?.reverse().map((rayon, index) => (
                    <tr key={rayon._id}>
                      <td>{index + 1}</td>
                      <td>{rayon.nama_rayon}</td>
                      <td>{rayon.pembimbing_id?.username}</td>
                      <td className='flex flex-row gap-1'>
                        <Link to={`/admin/rayon/edit/${rayon._id}`}>
                          <button className='btn btn-warning text-white'>
                            <BsPencilFill /> Ubah
                          </button>
                        </Link>
                        <button className='btn btn-danger btn-sm' onClick={() => confirmDelete(rayon._id)}>
                          <BsTrashFill /> Hapus
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">Data tidak ditemukan</td>
                  </tr>
                )
              ) : (<Kosong />)}
            </tbody>

          </table>

        </div>
      </LayoutDashboard>
    </div>
  );
}

