import React, { useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditGaji = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    masa_kerja_thn: '',
    masa_kerja_bln: '',
    gaji_pokok: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiEndpoint}/gaji/${id}`);
        setFormData(response.data.riwayat_gaji);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const formErrors = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
    );

    if (Object.values(formErrors).some((error) => error)) {
      setErrors(formErrors);
      return;
    }

    const result = await Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah Anda yakin data sudah benar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    });

    if (result.isConfirmed) {
      try {
        await axios.put(`${apiEndpoint}/gaji/update/${id}`, formData);
        Swal.fire({
          title: 'Sukses!',
          text: 'Berhasil Update Data',
          icon: 'success',
        }).then(() => navigate('/dashboard/pembimbing/gaji/show'));
      } catch (error) {
        console.log(error, "error");
      }
    }
  };




  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Home" dua="Data Gaji" />



        <div className='card-box shadow-sm p-4 mb-30'>
          {/* {
                                                                                    alertError ? <p className='alert alert-danger'>{alertError}</p> : ""
                                                                      } */}
          <form onSubmit={handleSubmit}>



            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='masa_kerja_thn' className='form-label'>Masa Kerja (Tahun) :</label>
                  <input type='number'
                    className={`form-control ${errors.masa_kerja_thn ? 'is-invalid' : ''}`}
                    value={formData.masa_kerja_thn}
                    onChange={handleChange} name='masa_kerja_thn' />
                  {errors.masa_kerja_thn && (
                    <div className='invalid-feedback'>{errors.masa_kerja_thn}</div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor='masa_kerja_bln' className='form-label'>Masa Kerja (Bulan) :</label>
                <input type='number'
                  className={`form-control ${errors.masa_kerja_bln ? 'is-invalid' : ''}`}
                  value={formData.masa_kerja_bln}
                  onChange={handleChange} name='masa_kerja_bln' />
                {errors.masa_kerja_bln && (
                  <div className='invalid-feedback'>{errors.masa_kerja_bln}</div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='gaji_pokok' className='form-label'>Gaji Pokok :</label>
                  <input type='text'
                    className={`form-control ${errors.gaji_pokok ? 'is-invalid' : ''}`}
                    value={formData.gaji_pokok}
                    onChange={handleChange} name='gaji_pokok' />
                  {errors.gaji_pokok && (
                    <div className='invalid-feedback'>{errors.gaji_pokok}</div>
                  )}
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-primary">Submit</button>

          </form>
        </div>




      </LayoutDashboard>
    </div>
  )
}
