import React, { useContext } from 'react'

import AuthContext from '../../context/AuthContext'
import LayoutDashboard from '../../layouts/LayoutDashboard'
import Foto2 from '../../assets/pfp2.jpg'
import GelBreadcrumb from '../../components/globalcomp/GelBreadcrumb'
import StudentContext from '../../context/StudentContext'



export const ProfilePage = () => {
    const { user } = useContext(AuthContext);
    const { student } = useContext(StudentContext);
    return (
        <div>
            <LayoutDashboard>
                {
                    user.role === "student" ? (
                        <GelBreadcrumb satu="Dashboard" dua="Profile" urlSatu="/dashboard/petunjuk" />

                    ) : (user.role === "guru" ? (<GelBreadcrumb satu="Dashboard" dua="Profile" urlSatu="/dashboard/pembimbing" />
                    ) : (<GelBreadcrumb satu="Dashboard" dua="Profile" urlSatu="/admin" />
                    ))
                }

                <div className='card-box p-4 shadow-sm mb-5'>

                    <div className="row gutters-sm">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        <div className='profile-photo'>

                                            <img src={Foto2} alt="Admin" className="rounded-circle" width="150" />
                                            <div
                                                className="modal fade"
                                                id="modal"
                                                tabindex="-1"
                                                role="dialog"
                                                aria-labelledby="modalLabel"
                                                aria-hidden="true"
                                            >
                                                <div
                                                    className="modal-dialog modal-dialog-centered"
                                                    role="document"
                                                >
                                                    <div className="modal-content">
                                                        <div className="modal-body pd-5">
                                                            <div className="img-container">


                                                                <p className='text-left'>Edit Profile Pict</p>

                                                                <form>

                                                                    <div className="form-group">
                                                                        <div className="custom-file">
                                                                            <label className="custom-file-label" htmlFor="fileInput">
                                                                                Choose file
                                                                            </label>
                                                                            <input
                                                                                type="file"
                                                                                className="custom-file-input"
                                                                                id="fileInput"
                                                                                name="name"
                                                                            />

                                                                        </div>

                                                                    </div>
                                                                    <div className="modal-footer ">

                                                                        <button type="submit" class="btn bg-blue text-whites">Submit</button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-danger"
                                                                            data-dismiss="modal"
                                                                        >
                                                                            Close
                                                                        </button>
                                                                    </div>
                                                                </form>


                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <h4>{user.username}</h4>
                                            <p className="text-muted font-size-sm">Role : {user.role}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Nama</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {user.username}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">Email</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {user.email}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0">NIS</h6>
                                        </div>
                                        <div className="col-sm-9 text-secondary">
                                            {student.nis}
                                        </div>
                                    </div>
                            
                                   
                                    <hr className='mb-4' />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </LayoutDashboard>
        </div>
    )
}
