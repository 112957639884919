import React, { useContext, useEffect } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { Information } from '../../../../components/globalcomp/Information'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { BsPencil, BsTrashFill } from 'react-icons/bs'

import { confirmDelete } from '../../../../function/delete'
export const ShowTunjangan = () => {
    document.title = "Data Tunjangan";

    const { gtk, getGtk } = useContext(GtkContext)
    const showGtk = gtk.gtk?.tunjangan_id;
    useEffect(() => {
        getGtk()
    }, [])

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Data Tunjangan" />
                <Information title="tunjangan" />
                <div className='card-box shadow-sm p-4 mb-30'>
                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/tunjangan/create">
                            <button type="button" className="btn bg-blue text-white btn-primary mb-2">Tambah Data Tunjangan</button>
                        </Link>
                    </div>
                    <table className="table table-borderless">
                        <thead className='thead-dark'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Jenis Tunjangan</th>
                                <th scope="col">No SK</th>
                                <th scope="col">Tanggal SK</th>
                                <th scope="col">Sumber Dana</th>
                                <th scope="col">Nominal</th>
                                <th scope="col">Masih Menerima</th>
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {showGtk && showGtk.length > 0 ? (
                                showGtk.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.jenis_tunjangan}</td>
                                        <td>{item.no_sk}</td>
                                        <td>{item.tanggal_sk}</td>
                                        <td>{item.sumber_dana}</td>
                                        <td>{item.nominal}</td>
                                        <td><span className='badge badge-warning'>{item.masih_menerima === true ? "Iya" : "Tidak"}</span></td>
                                        <td className='d-flex '>
                                            <Link to={`/dashboard/pembimbing/tunjangan/edit/${item._id}`}>
                                                <button className='btn btn-warning text-white btn-sm'>
                                                    <BsPencil /> Ubah
                                                </button>
                                            </Link>
                                            <button
                                                className='btn btn-danger ml-3 btn-sm'
                                                onClick={() => confirmDelete(item._id, 'tunjangan/delete', getGtk)}
                                            >
                                                <BsTrashFill /> Hapus
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <Kosong />
                            )}
                        </tbody>
                    </table>
                </div>
            </LayoutDashboard>
        </div>
    )
}
