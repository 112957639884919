import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";

const PdfGenerator = ({ id }) => {
  const [student, setStudent] = useState([]);

  const options = { day: "numeric", month: "long", year: "numeric" };
  const dateFormatted = new Date().toLocaleDateString("en-GB", options);

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const getStudent = async () => {
    try {
      await axios
        .get(`${apiEndpoint}/dashboard/student/${id}`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        })
        .then((response) => {
          setStudent(response.data.student || []);
          generatePdf(response.data.student.nis, response.data.student.nama);
        });
    } catch (error) {
      console.log("Terjadi kesalahan pada server");
    }
  };

  useEffect(() => {
    getStudent();
  }, [id]);

  const generatePdf = async (nis, nama) => {
    const pdf = new jsPDF("p", "px", "a6");
    const pages = document.querySelectorAll(".pdf-page");

    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i]);
      const imgData = canvas.toDataURL("image/png");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      if (i < pages.length - 1) {
        pdf.addPage();
      }
    }
    pdf.save(`${nis}-${nama}.pdf`);
  };

  // getStudent();
  // generatePdf();

  const formatTL = (tl) => {
    const dateOpt = { day: 'numeric', month: 'long', year: 'numeric' };
    // const day = new Date(tl).getDate();

    // const month = new Date(tl).getMonth();

    // const year = new Date(tl).getFullYear();
    const date = new Date(tl).toLocaleDateString('id-ID', dateOpt);

    // console.log(`${day - 1}/${month + 1}/${year}`)
    return `${date}`;
  }

  return (
    <div>
      <div className="pdf-page" style={{ padding: "50px" }}>
        <style>
          {`
          body {
        height: auto;
        font-family: Arial;
        line-height: 1.625;
      }
      h1 {
        text-align: center;
        font-size: 0.9%;
      }
      p {
        text-indent: 50px;
      }
      th {
        text-align: left;
        white-space: nowrap;
        font-size: 0.8%;
      }
      p,
      table,
      span {
        border: none !important;
        
      }
      table tr th, table tr td {
        border-right: none !important;
        border-left: none !important;
        border-bottom: none !important;
        border-top: none !important;
      }
      table tr th:first-child, table tr td:first-child {
        border-left: none !important;
      }
      td {
        display: inline;
      }
      .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        
        line-height: 1.75;
      }
      .col1 {
        width: 25%;
        display: inline;
        
        font-weight: bold;
        line-height: 1.75;
      }
      .col2 {
        width: 2.5%;
        display: inline;
        
        line-height: 1.75;
      }
      .col3 {
        width: 67.5%;
        display: inline;
        
        line-height: 1.75;
      }
      .textHeadBold {
        
        font-weight: bold;
        text-align: start;
        text-transform: uppercase;
      }
      .textBold {
        
        font-weight: bold;
        display: inline;
      }
      .pageBreak {
        break-after: page;
        clear: both;
        page-break-before: always;
        page-break-after: always;
      }
          `}
        </style>
        <div style={{ marginTop: "5px" }}>
          <b
            style={{
              
              fontWeight: "bold",
              lineHeight: "5px",
              textAlign: "center",
              display: "block",
            }}
          >
            SURAT PERNYATAAN
          </b>
          <br />
          <b
            style={{
              
              fontWeight: "bold",
              lineHeight: "5px",
              textAlign: "center",
              display: "block",
            }}
          >
            VALIDASI DAN VERIFIKASI DATA PESERTA DIDIK
          </b>
          <br />
          <b
            style={{
              
              fontWeight: "bold",
              lineHeight: "5px",
              textAlign: "center",
              display: "block",
            }}
          >
            SMK WIKRAMA BOGOR T.P. 2023-2024
          </b>
        </div>
        <p style={{ marginTop: "20px" }}>
          <span style={{ textIndent: "40px" }}>
            Yang bertanda tangan di bawah ini kami selaku orang tua peserta
            didik dari :{" "}
          </span>
        </p>
        <p
          className="textHeadBold"
          style={{
            textDecoration: "uppercase",
            backgroundColor: "gray",
            padding: "5px 5px 0px 5px",
            verticalAlign: "bottom",
          }}
        >
          Data Peserta Didik
        </p>
        <div id="section_data_diri">
          <div class="row">
            <div class="col1">NIS/NISN/NIK</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.nis ? student.nis : "-"} /{" "}
              {student.nisn ? student.nisn : "-"} /{" "}
              {student.nik ? student.nik : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Nama</div>
            <div class="col2">:</div>
            <div class="col3">{student.nama ? student.nama : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Jenis Kelamin</div>
            <div class="col2">:</div>
            <div class="col3">{student.jk ? student.jk : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Rombel</div>
            <div class="col2">:</div>
            <div class="col3">{student.rombel ? student.rombel : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Rayon</div>
            <div class="col2">:</div>
            <div class="col3">{student.rayon ? student.rayon : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Tempat, Tanggal Lahir</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.tempat_lahir ? student.tempat_lahir : "-"},{" "}
              {student.tanggal_lahir ? formatTL(student.tanggal_lahir) : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Agama</div>
            <div class="col2">:</div>
            <div class="col3">{student.agama ? student.agama : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Alamat</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.alamat ? student.alamat : "-"}{" "}
              <span >RT./RW.</span>{" "}
              {student.rt ? student.rt : "-"}/{student.rw ? student.rw : "-"}{" "}
              <span >Desa/Kel.</span>{" "}
              {student.nama_kelurahan ? student.nama_kelurahan : "-"}{" "}
              <span >Kec.</span>{" "}
              {student.kecamatan ? student.kecamatan : "-"}{" "}
              <span >Kab/Kota.</span>{" "}
              {student.nama_kota_kabupaten ? student.nama_kota_kabupaten : "-"}{" "}
              <span >Provinsi.</span>{" "}
              {student.provinsi ? student.provinsi : "-"},{" "}
              {student.kode_pos ? student.kode_pos : "-"}
              <p></p>
            </div>
          </div>
          <div class="row">
            <div class="col1">Tempat Tinggal</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.tinggal_bersama
                ? student.tinggal_bersama === "Lainnya"
                  ? student.ket_tinggal_bersama
                  : student.tinggal_bersama
                : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Moda Transportasi</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.transportasi
                ? student.transportasi === "Lainnya"
                  ? student.ket_transportasi
                  : student.transportasi
                : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Anak ke-</div>
            <div class="col2">:</div>
            <div class="col3">{student.anak_ke ? student.anak_ke : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Sekolah Asal</div>
            <div class="col2">:</div>
            <div class="col3">{student.asal_smp ? student.asal_smp : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">No. Seri Ijazah SD</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.no_ijazah_sd ? student.no_ijazah_sd : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">No. Seri Ijazah SMP</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.no_ijazah_smp ? student.no_ijazah_smp : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Email Sekolah</div>
            <div class="col2">:</div>
            <div class="col3">{student.email ? student.email : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Nomor HP</div>
            <div class="col2">:</div>
            <div class="col3">{student.no_telp ? student.no_telp : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Tinggi Badan</div>
            <div class="col2">:</div>
            <div class="col3">{student.tb ? student.tb + ' cm' : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Berat Badan</div>
            <div class="col2">:</div>
            <div class="col3">{student.bb ? student.bb + ' kg' : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">Golongan Darah</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.gol_darah ? student.gol_darah : "-"}
            </div>
          </div>
        </div>
        <p
          className="textHeadBold"
          style={{
            textDecoration: "uppercase",
            backgroundColor: "gray",
            padding: "5px 5px 0px 5px",
            verticalAlign: "bottom",
            marginTop: "50px",
          }}
        >
          Data Ayah
        </p>
        <div id="section_data_ayah">
          <div class="row">
            <div class="col1">No. Kartu Keluarga</div>
            <div class="col2">:</div>
            <div class="col3">{student.no_kk ? student.no_kk : "-"}</div>
          </div>
          <div class="row">
            <div class="col1">NIK</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.nik_ayah : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Nama Ayah</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.nama_ayah : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Tempat, Tanggal Lahir</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id?.tempat_lahir_ayah
                ? student.keluarga_id?.tempat_lahir_ayah
                : "-"}
              ,{" "}
              {student.keluarga_id?.tanggal_lahir_ayah
                ? formatTL(student.keluarga_id?.tanggal_lahir_ayah)
                : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Alamat</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.alamat ? student.alamat : "-"}{" "}
              <span >RT./RW.</span>{" "}
              {student.rt ? student.rt : "-"}/{student.rw ? student.rw : "-"}{" "}
              <span >Desa/Kel.</span>{" "}
              {student.nama_kelurahan ? student.nama_kelurahan : "-"}{" "}
              <span >Kec.</span>{" "}
              {student.kecamatan ? student.kecamatan : "-"}{" "}
              <span >Kab/Kota.</span>{" "}
              {student.nama_kota_kabupaten ? student.nama_kota_kabupaten : "-"}{" "}
              <span >Provinsi.</span>{" "}
              {student.provinsi ? student.provinsi : "-"},{" "}
              {student.kode_pos ? student.kode_pos : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Pendidikan</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.pendidikan_ayah : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Pekerjaan</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.pekerjaan_ayah : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Penghasilan Perbulan</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.penghasilan_ayah : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">No. HP</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.no_telp_ayah : "-"}
            </div>
          </div>
        </div>
      </div>
      <div className="pdf-page" style={{ padding: "50px", marginTop: "10px" }}>
        <p
          className="textHeadBold"
          style={{
            textDecoration: "uppercase",
            backgroundColor: "gray",
            padding: "5px 5px 0px 5px",
            verticalAlign: "bottom",
          }}
        >
          Data Ibu
        </p>

        <div id="section_data_ibu">
          <div class="row">
            <div class="col1">NIK</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.nik_ibu : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Nama Ibu</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.nama_ibu : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Tempat, Tanggal Lahir</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id?.tempat_lahir_ibu
                ? student.keluarga_id?.tempat_lahir_ibu
                : "-"}
              ,{" "}
              {student.keluarga_id?.tanggal_lahir_ibu
                ? formatTL(student.keluarga_id?.tanggal_lahir_ibu)
                : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Alamat</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.alamat ? student.alamat : "-"}{" "}
              <span >RT./RW.</span>{" "}
              {student.rt ? student.rt : "-"}/{student.rw ? student.rw : "-"}{" "}
              <span >Desa/Kel.</span>{" "}
              {student.nama_kelurahan ? student.nama_kelurahan : "-"}{" "}
              <span >Kec.</span>{" "}
              {student.kecamatan ? student.kecamatan : "-"}{" "}
              <span >Kab/Kota.</span>{" "}
              {student.nama_kota_kabupaten ? student.nama_kota_kabupaten : "-"}{" "}
              <span >Provinsi.</span>{" "}
              {student.provinsi ? student.provinsi : "-"},{" "}
              {student.kode_pos ? student.kode_pos : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Pendidikan</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.pendidikan_ibu : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Pekerjaan</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.pekerjaan_ibu : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">Penghasilan Perbulan</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.penghasilan_ibu : "-"}
            </div>
          </div>
          <div class="row">
            <div class="col1">No. HP</div>
            <div class="col2">:</div>
            <div class="col3">
              {student.keluarga_id ? student.keluarga_id.no_telp_ibu : "-"}
            </div>
          </div>
        </div>

        {student.keluarga_id ? (
          student.keluarga_id.nama_wali ? (
            <>
              <p
                className="textHeadBold"
                style={{
                  textDecoration: "uppercase",
                  backgroundColor: "gray",
                  padding: "5px 5px 0px 5px",
                  verticalAlign: "bottom",
                }}
              >
                Data Wali
              </p>
              <div class="row">
                <div class="col1">NIK</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.keluarga_id ? student.keluarga_id.nik_wali : "-"}
                </div>
              </div>
              <div class="row">
                <div class="col1">Nama Wali</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.keluarga_id ? student.keluarga_id.nama_wali : "-"}
                </div>
              </div>
              <div class="row">
                <div class="col1">Jenis Kelamin Wali</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.keluarga_id ? student.keluarga_id.jk_wali : "-"}
                </div>
              </div>
              <div class="row">
                <div class="col1">Tempat, Tanggal Lahir</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.keluarga_id?.tempat_lahir_wali
                    ? student.keluarga_id?.tempat_lahir_wali
                    : "-"}
                  ,{" "}
                  {student.keluarga_id?.tanggal_lahir_wali
                    ? formatTL(student.keluarga_id?.tanggal_lahir_wali)
                    : "-"}
                </div>
              </div>
              <div class="row">
                <div class="col1">Alamat</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.alamat ? student.alamat : "-"}{" "}
                  <span >RT./RW.</span>{" "}
                  {student.rt ? student.rt : "-"}/
                  {student.rw ? student.rw : "-"}{" "}
                  <span >Desa/Kel.</span>{" "}
                  {student.nama_kelurahan ? student.nama_kelurahan : "-"}{" "}
                  <span >Kec.</span>{" "}
                  {student.kecamatan ? student.kecamatan : "-"}{" "}
                  <span >Kab/Kota.</span>{" "}
                  {student.nama_kota_kabupaten
                    ? student.nama_kota_kabupaten
                    : "-"}{" "}
                  <span >Provinsi.</span>{" "}
                  {student.provinsi ? student.provinsi : "-"},{" "}
                  {student.kode_pos ? student.kode_pos : "-"}
                </div>
              </div>
              <div class="row">
                <div class="col1">Pendidikan</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.keluarga_id
                    ? student.keluarga_id.pendidikan_wali
                    : "-"}
                </div>
              </div>
              <div class="row">
                <div class="col1">Pekerjaan</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.keluarga_id
                    ? student.keluarga_id.pekerjaan_wali
                    : "-"}
                </div>
              </div>
              <div class="row">
                <div class="col1">Penghasilan Perbulan</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.keluarga_id
                    ? student.keluarga_id.penghasilan_wali
                    : "-"}
                </div>
              </div>
              <div class="row">
                <div class="col1">No. HP</div>
                <div class="col2">:</div>
                <div class="col3">
                  {student.keluarga_id ? student.keluarga_id.no_telp_wali : "-"}
                </div>
              </div>
            </>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        <p>
          <span style={{ textIndent: "40px" }}>
            Menyatakan bahwa saya menyetujui data tersebut dan dapat
            mempertanggungjawabkan kebenarannya, jika suatu saat kesalahan data
            di ijazah dan dokumen lainnya, bukan merupakan tanggung jawab SMK
            Wikrama Bogor.
          </span>
          <br />
        </p>
        <p>
          <span style={{ textIndent: "40px" }}>
            Demikian surat pernyataan ini dibuat agar dapat digunakan
            sebagaimana mestinya.
          </span>
        </p>
        <table style={{width: '100%', marginTop: '20px'}}>
            <tr style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <td style={{width: '50%'}}></td>
                <td style={{width: '15%'}}></td>
                <td style={{width: '35%'}}><span style={{fontWeight: 'bold', textAlign: 'left'}}>Bogor, {dateFormatted}</span></td>
            </tr>
            <tr style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <td style={{width: '50%'}}></td>
                <td style={{width: '15%'}}></td>
                <td style={{width: '35%'}}><span style={{fontWeight: 'bold', textAlign: 'left'}}>Yang membuat pernyataan,</span></td>
            </tr>
            <tr style={{height: '100px'}}>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                <td style={{width: '50%'}}></td>
                <td style={{width: '15%'}}></td>
                <td style={{width: '35%', textAlign: 'left'}}>{student.keluarga_id ? student.keluarga_id.nama_ibu + ' / ' + student.keluarga_id.nama_ayah : '-'}</td>
            </tr>
        </table>
      </div>
      {/* <div className="pdf-page" style={{ padding: "10px", marginTop: "10px" }}>
        <h1>Page 3</h1>
        <p>This is the content of the third page.</p>
      </div> */}
      {/* <button onClick={generatePdf}>Generate Multi-Page PDF</button> */}
    </div>
  );
};

export default PdfGenerator;
