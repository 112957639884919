import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../../../context/AuthContext';
import { FaCheckCircle, FaRegCheckCircle, FaSpinner } from 'react-icons/fa';

export const CekKeluarga = ({ studentData }) => {
    const [cekSiswa, setCekSiswa] = useState({});
    const { user } = useContext(AuthContext);

    useEffect(() => {
        setCekSiswa(studentData);
        console.log(studentData)
    }, [studentData]);

    return (
        <div className="pd-20 mb-30">
            {user?.role === "student" ?(
                cekSiswa?.no_kk &&
                    cekSiswa?.nik &&
                    cekSiswa.nik_ayah &&
                    cekSiswa.nik_ayah &&
                    cekSiswa.tanggal_lahir_ayah &&
                    cekSiswa.pendidikan_ayah &&
                    cekSiswa.pekerjaan_ayah &&
                    cekSiswa.penghasilan_ayah &&
                    cekSiswa.nama_ibu &&
                    cekSiswa.nik_ibu &&
                    cekSiswa.tanggal_lahir_ibu &&
                    cekSiswa.pendidikan_ibu &&
                    cekSiswa.pekerjaan_ibu &&
                    cekSiswa.penghasilan_ibu &&
                    cekSiswa.nama_wali &&
                    cekSiswa.tanggal_lahir_wali &&
                    cekSiswa.pendidikan_wali &&
                    cekSiswa.pekerjaan_wali &&
                    cekSiswa.penghasilan_wali
                    ? (
                        cekSiswa.status_data_family === "Pending" ? (
                            <div className="alert alert-info alert-dismissible fade show" role="alert">
                                <strong>Data Masih Pending!</strong> Pembimbing siswa akan mengecek datamu terlebih dahulu
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        ) : (
                            ""
                        )
                    ) : <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Data Belum Lengkap!</strong> Segera Lengkapi Data
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
            ) : ""}


            <div className="clearfix">
                <div className="pull-left">
                    <h4 className="text-blue h4">Data Keluarga Siswa</h4>
                    {user.role === 'student' && <p className="mb-30">Lengkapi Data Jika Masih Kosong</p>}
                </div>
                {user.role === 'student' && (
                    <div className="pull-right">
                        {studentData?.status_data_family === "Konfirmasi" ? (
                            <button
                                className="btn gap-2 btn-success"
                                disabled>
                                Menunggu Verifikasi
                                <FaSpinner className='ml-2' />
                            </button>
                        //     <Link
                        //     to="/dashboard/update/data-keluarga"
                        // >
                        //     <button className='btn btn-success py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                        //         Lengkapi Data Keluarga
                        //     </button>
                        // </Link>
                        ) : (studentData?.status_data_family === 'Verifikasi' ? (
                            <button
                                className="btn gap-2 btn-success"
                                disabled>
                                Diverifikasi
                                <FaRegCheckCircle className='ml-2' />
                            </button>
                        ) : (
                            <Link
                                to="/dashboard/update/data-keluarga"
                            >
                                <button className='btn btn-success py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                                    Lengkapi Data Keluarga
                                </button>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <table className="table table-borderless table-hover">
                        <tbody>
                            <TableRow label="No. Kartu Keluarga" value={cekSiswa.no_kk ? cekSiswa.no_kk : '-'} />
                            <TableRow label="NIK Peserta Didik" value={cekSiswa.nik ? cekSiswa.nik : '-'} />
                            <TableRow label="Nama Ayah" value={cekSiswa?.keluarga_id?.nama_ayah ? cekSiswa.keluarga_id.nama_ayah : "-"} />
                            <TableRow label="NIK Ayah" value={cekSiswa?.keluarga_id?.nik_ayah ? cekSiswa?.keluarga_id?.nik_ayah : "-"} />
                            <TableRow label="Tempat Lahir Ayah" value={cekSiswa?.keluarga_id?.tempat_lahir_ayah ? cekSiswa?.keluarga_id?.tempat_lahir_ayah : "-"} />
                            <TableRow label="Tanggal Lahir Ayah" value={cekSiswa?.keluarga_id?.tanggal_lahir_ayah ? cekSiswa?.keluarga_id?.tanggal_lahir_ayah : "-"} />
                            <TableRow label="Pendidikan Ayah" value={cekSiswa?.keluarga_id?.pendidikan_ayah ? cekSiswa?.keluarga_id?.pendidikan_ayah : "-"} />
                            <TableRow label="Pekerjaan Ayah" value={cekSiswa?.keluarga_id?.pekerjaan_ayah ? cekSiswa?.keluarga_id?.pekerjaan_ayah : "-"} />
                            <TableRow label="Penghasilan Ayah" value={cekSiswa?.keluarga_id?.penghasilan_ayah ? cekSiswa?.keluarga_id?.penghasilan_ayah : "-"} />
                            <TableRow label="No HP Ayah" value={cekSiswa?.keluarga_id?.no_telp_ayah ? cekSiswa?.keluarga_id?.no_telp_ayah : "-"} />
                            <TableRow label="Nama Ibu" value={cekSiswa?.keluarga_id?.nama_ibu ? cekSiswa?.keluarga_id?.nama_ibu : "-"} />
                            <TableRow label="NIK Ibu" value={cekSiswa?.keluarga_id?.nik_ibu ? cekSiswa?.keluarga_id?.nik_ibu : "-"} />
                            <TableRow label="Tempat Lahir Ibu" value={cekSiswa?.keluarga_id?.tempat_lahir_ibu ? cekSiswa?.keluarga_id?.tempat_lahir_ibu : "-"} />
                            <TableRow label="Tanggal Lahir Ibu" value={cekSiswa?.keluarga_id?.tanggal_lahir_ibu ? cekSiswa?.keluarga_id?.tanggal_lahir_ibu : "-"} />
                            <TableRow label="Pendidikan Ibu" value={cekSiswa?.keluarga_id?.pendidikan_ibu ? cekSiswa?.keluarga_id?.pendidikan_ibu : "-"} />
                            <TableRow label="Pekerjaan Ibu" value={cekSiswa?.keluarga_id?.pekerjaan_ibu ? cekSiswa?.keluarga_id?.pekerjaan_ibu : "-"} />
                            <TableRow label="Penghasilan Ibu" value={cekSiswa?.keluarga_id?.penghasilan_ibu ? cekSiswa?.keluarga_id?.penghasilan_ibu : "-"} />
                            <TableRow label="No HP Ibu" value={cekSiswa?.keluarga_id?.no_telp_ibu ? cekSiswa?.keluarga_id?.no_telp_ibu : "-"} />

                            <TableRow label="Nama Wali" value={cekSiswa?.keluarga_id?.nama_wali ? cekSiswa?.keluarga_id?.nama_wali : "-"} />
                            <TableRow label="NIK Wali" value={cekSiswa?.keluarga_id?.nik_wali ? cekSiswa?.keluarga_id?.nik_wali : "-"} />
                            <TableRow label="Jenis Kelamin Wali" value={cekSiswa?.keluarga_id?.jk_wali ? cekSiswa?.keluarga_id?.jk_wali : "-"} />
                            <TableRow label="Tempat Lahir Wali" value={cekSiswa?.keluarga_id?.tempat_lahir_wali ? cekSiswa?.keluarga_id?.tempat_lahir_wali : "-"} />
                            <TableRow label="Tanggal Lahir Wali" value={cekSiswa?.keluarga_id?.tanggal_lahir_wali ? cekSiswa?.keluarga_id?.tanggal_lahir_wali : "-"} />
                            <TableRow label="Pendidikan Wali" value={cekSiswa?.keluarga_id?.pendidikan_wali ? cekSiswa?.keluarga_id?.pendidikan_wali : "-"} />
                            <TableRow label="Pekerjaan Wali" value={cekSiswa?.keluarga_id?.pekerjaan_wali ? cekSiswa?.keluarga_id?.pekerjaan_wali : "-"} />
                            <TableRow label="Penghasilan Wali" value={cekSiswa?.keluarga_id?.penghasilan_wali ? cekSiswa?.keluarga_id?.penghasilan_wali : "-"} />
                            <TableRow label="No HP Wali" value={cekSiswa?.keluarga_id?.no_telp_wali ? cekSiswa?.keluarga_id?.no_telp_wali : "-"} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const TableRow = ({ label, value }) => (

    <tr>
        <th style={{ fontSize: '18px', width: "250px" }}>{label}</th>
        <th style={{ fontSize: '18px', fontWeight: "500" }}> {value}</th>
    </tr>



);
