import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../../context/AuthContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import StudentContext from '../../../../context/StudentContext';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditDataAlamatPage = () => {
    const navigate = useNavigate();
    const { student } = useContext(StudentContext);
    const [provinces, setProvinces] = useState([]);
    const [regencies, setRegencies] = useState([]);
    const [districts, setDistricts] = useState([]);

    const [studentData, setStudentData] = useState({
        alamat: "",
        rt: "",
        rw: "",
        nama_dusun: "",
        kecamatan: "",
        nama_kota: "",
        kode_pos: "",
        provinsi: "",
        kabupaten: "",
        transportasi: "",
        district: ""
    });

    useEffect(() => {
        fetchProvinces();
    }, []);

    useEffect(() => {
        if (studentData.provinsi) {
            fetchRegencies(studentData.provinsi);
        }
    }, [studentData.provinsi]);

    useEffect(() => {
        if (studentData.kabupaten) {
            fetchDistricts(studentData.kabupaten);
        }
    }, [studentData.kabupaten]);

    useEffect(() => {
        setStudentData(student);
    }, [student]);

    const fetchRegencies = async (provinceId) => {
        try {
            const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${provinceId}.json`);
            const data = await response.json();
            setRegencies(data);
        } catch (error) {
            console.error('Error fetching regencies:', error);
        }
    };

    const fetchDistricts = async (regencyId) => {
        try {
            const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${regencyId}.json`);
            const data = await response.json();
            setDistricts(data);
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };

    const fetchProvinces = async () => {
        try {
            const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json`);
            const data = await response.json();
            setProvinces(data);
        } catch (error) {
            console.error('Error fetching provinces:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        Object.fromEntries(
            Object.entries(studentData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            Swal.fire({
                title: 'Loading...',
                text: 'Sedang memproses, harap tunggu.',
                allowOutsideClick: false,
                showConfirmButton: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },

            });
            try {
                await axios.put(`${apiEndpoint}/student/update/${student?._id}`, studentData);
                setStudentData(studentData);

                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/dashboard/data-diri'));
            } catch (error) {
                console.error('Error updating data:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Gagal Update Data. Silakan coba lagi.',
                    icon: 'error',
                });
            }
        }
    };

    return (
        <div>
            <LayoutDashboard >
                <GelBreadcrumb satu="Data Siswa" dua="Edit Data Keluarga" urlSatu="/dashboard/data-diri" />
                <div className='card-box shadow-sm p-4 mb-5'>
                    <div className='row'>


                        <form onSubmit={handleSubmit} className='mb-5'>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Provinsi <span className='text-danger'>*</span></label>
                                    <select
                                        required
                                        name='provinsi'
                                        value={studentData.provinsi}
                                        className="form-control"
                                        onChange={e => setStudentData({ ...studentData, provinsi: e.target.value })}
                                    >
                                        <option value="">Pilih Provinsi</option>
                                        {provinces.map(provinsi => (
                                            <option key={provinsi.id} value={provinsi.id}>{provinsi.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Kabupaten/Kota <span className='text-danger'>*</span></label>
                                    <select
                                        required
                                        name='kabupaten'
                                        value={studentData.kabupaten}
                                        className="form-control"
                                        onChange={e => setStudentData({ ...studentData, kabupaten: e.target.value })}
                                    >
                                        <option value="">Pilih Kabupaten/Kota</option>
                                        {regencies.map(kabupaten => (
                                            <option key={kabupaten.id} value={kabupaten.id}>{kabupaten.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Kecamatan <span className='text-danger'>*</span></label>
                                    <select
                                        required
                                        name='kecamatan'
                                        value={studentData.kecamatan}
                                        className="form-control"
                                        onChange={e => setStudentData({ ...studentData, kecamatan: e.target.value })}
                                    >
                                        <option value="">Pilih Kecamatan</option>
                                        {districts.map(kecamatan => (
                                            <option key={kecamatan.id} value={kecamatan.id}>{kecamatan.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </LayoutDashboard>
        </div>

    )
}
