import React, { useContext } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import GtkContext from '../../../../context/GtkContext'
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const CreateJabatan = () => {

    const navigate = useNavigate();

    const { gtk } = useContext(GtkContext)
    const gtkId = gtk?.gtk?._id

    const [formData, setFormData] = useState({
        jabatan: '',
        sk_jabatan: '',
        tmt_jabatan: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : `wajib diisi` }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            if (gtk && gtkId) {
                try {
                    await axios.post(`${apiEndpoint}/jabatan/create/${gtkId}`, { ...formData });
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Berhasil Create Data',
                        icon: 'success',
                    }).then(() => navigate('/dashboard/pembimbing/jabatan/show'));
                } catch (error) {
                    console.log(error.response?.data?.error || 'Error');
                }
            } else {
                console.log('Tidak ada ID.');
            }
        }
    }



    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Data Jabatan" />



                <div className='card-box shadow-sm p-4 mb-30'>
                    {/* {
                        alertError ? <p className='alert alert-danger'>{alertError}</p> : ""
                    } */}
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='jabatan' className='form-label'>Jabatan :</label>
                                    <input type='text'
                                        className={`form-control ${errors.jabatan ? 'is-invalid' : ''}`}
                                        value={formData.jabatan}
                                        onChange={handleChange} name='jabatan' />
                                    {errors.jabatan && (
                                        <div className='invalid-feedback'>{errors.jabatan}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='sk_jabatan' className='form-label'>SK Jabatan :</label>
                                <input type='number'
                                    className={`form-control ${errors.sk_jabatan ? 'is-invalid' : ''}`}
                                    value={formData.sk_jabatan}
                                    onChange={handleChange} name='sk_jabatan' />
                                {errors.sk_jabatan && (
                                    <div className='invalid-feedback'>{errors.sk_jabatan}</div>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tmt_jabatan' className='form-label'>TMT Jabatan :</label>
                                    <input type='date'
                                        className={`form-control ${errors.tmt_jabatan ? 'is-invalid' : ''}`}
                                        value={formData.tmt_jabatan}
                                        onChange={handleChange} name='tmt_jabatan' />
                                    {errors.tmt_jabatan && (
                                        <div className='invalid-feedback'>{errors.tmt_jabatan}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>

                    </form>
                </div>




            </LayoutDashboard>
        </div>
    )
}
