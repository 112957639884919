import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LayoutDashboard from '../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../components/globalcomp/GelBreadcrumb';
import axios from 'axios';

const DetailGuru = () => {

  const id = useParams();

  console.log(id.id, "idy")

  const [detailGtk, setDetailGtk] = useState([]);

  const getDetailGtk = async () => {
    try {

      const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/gtk/${id.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      })
      console.log(response.data.gtk, "tahu tempe")
      setDetailGtk(response.data.gtk)

    } catch (error) {
      console.log(error, "error ");

    }
  }

  useEffect(() => {
    getDetailGtk()
  }, [id])


  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Data GTK" urlSatu="/admin/show/gtk" tiga="detail data GTK" dua={`${detailGtk.user_id?.username}`} />
        <div className='card-box  shadow-sm p-4'>
          <table className="table-borderless  table">
            <thead className='thead-dark'>
              <tr>
                <th colSpan="2" className='text-center'>Data Akun</th>
              </tr>
              <tr>
                <td>Email</td>
                <td>{detailGtk.user_id?.email}</td>
              </tr>
              <tr>
                <td>Username</td>
                <td>{detailGtk.user_id?.username}</td>
              </tr>
              <tr>
                <td>Role</td>
                <td>{detailGtk.user_id?.role}</td>
              </tr>
              <tr>
                <td>Nama Lengkap</td>
                <td>{detailGtk.user_id?.nama_lengkap}</td>
              </tr>
              <tr>
                <td>NIK</td>
                <td>{detailGtk.user_id?.nik}</td>
              </tr>
              <tr>
                <td>JK</td>
                <td>{detailGtk.user_id?.jk}</td>
              </tr>
              <tr>
                <td>Tempat Lahir</td>
                <td>{detailGtk.user_id?.tempat_lahir}</td>
              </tr>
              <tr>
                <td>Tanggal Lahir</td>
                <td>{detailGtk.user_id?.tanggal_lahir}</td>
              </tr>
              <tr>
                <td>NIP</td>
                <td>{detailGtk.user_id?.nip}</td>
              </tr>
              <tr>
                <td>Nama Ibu</td>
                <td>{detailGtk.user_id?.nama_ibu}</td>
              </tr>
              <tr>
                <td>Alamat</td>
                <td>{detailGtk.user_id?.alamat}</td>
              </tr>
              <tr>
                <td>No KK</td>
                <td>{detailGtk.user_id?.no_kk}</td>
              </tr>
              <tr>
                <td>Kode POS</td>
                <td>{detailGtk.user_id?.kode_pos}</td>
              </tr>
              <tr>
                <td>Agama</td>
                <td>{detailGtk.user_id?.agama}</td>
              </tr>
              <tr>
                <td>kewarganegaraan</td>
                <td>{detailGtk.user_id?.kewarganegaraan}</td>
              </tr>
              <tr>
                <td>NPWP</td>
                <td>{detailGtk.user_id?.npwp}</td>
              </tr>
              <tr>
                <td>Nama Wajib Pajak</td>
                <td>{detailGtk.user_id?.nama_wajib_pajak}</td>
              </tr>
              <tr>
                <td>Status Kawin</td>
                <td>{detailGtk.user_id?.status_kawin}</td>
              </tr>
              <tr>
                <td>Nama Istri Suami</td>
                <td>{detailGtk.user_id?.nama_istri_suami}</td>
              </tr>
              <tr>
                <td>NIp Istri Suami</td>
                <td>{detailGtk.user_id?.nip_istri_suami}</td>
              </tr>
              <tr>
                <td>Pekerjaan Istri Suami</td>
                <td>{detailGtk.user_id?.pekerjaan_istri_suami}</td>
              </tr>
              <tr>
                <td>No Telp</td>
                <td>{detailGtk.user_id?.no_telp}</td>
              </tr>
              <tr>
                <td>No Telephone Rumah</td>
                <td>{detailGtk.user_id?.no_telp_rumah}</td>
              </tr>
              {/* <tr>
                <td>Email</td>
                <td>{detailGtk.user_id?.email}</td>
              </tr> */}
              <tr>
                <td>BB</td>
                <td>{detailGtk.user_id?.bb}</td>
              </tr>
              <tr>
                <td>TB</td>
                <td>{detailGtk.user_id?.tb}</td>
              </tr>
              <tr>
                <td>Gol Darah</td>
                <td>{detailGtk.user_id?.gol_darah}</td>
              </tr>
            </thead>
          </table>

          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="5" className='text-center'>Data Pendidikan</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.pendidikan_id?.map((pendidikan, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="11" className='text-center'> {index + 1}</td>
                  </tr>
                  <tr>
                    <td className='font-weight-bold'>Bidang Studi</td>
                    <td className='font-weight-bold'>{pendidikan.bidang_studi}</td>
                  </tr>
                  <tr>
                    <td>Jenjang Pendidikan</td>
                    <td>{pendidikan.jenjang_pendidikan}</td>
                  </tr>
                  <tr>
                    <td>Gelar Akademik</td>
                    <td>{pendidikan.gelar_akademik}</td>
                  </tr>
                  <tr>
                    <td>Satuan Pendidikan</td>
                    <td>{pendidikan.satuan_pendidikan}</td>
                  </tr>
                  <tr>
                    <td>Tahun Masuk</td>
                    <td>{pendidikan.tahun_masuk}</td>
                  </tr>
                  <tr>
                    <td>Tahun Keluar</td>
                    <td>{pendidikan.tahun_keluar}</td>
                  </tr>
                  <tr>
                    <td>NIM</td>
                    <td>{pendidikan.nim}</td>
                  </tr>
                  <tr>
                    <td>Mata Kuliah</td>
                    <td>{pendidikan.mata_kuliah}</td>
                  </tr>
                  <tr>
                    <td>Semester</td>
                    <td>{pendidikan.semester}</td>
                  </tr>
                  <tr>
                    <td>IPK</td>
                    <td>{pendidikan.ipk}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="5" className='text-center'>Data Anak</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.anak_id?.map((anak, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="9" className='text-center'> {index + 1}</td>
                  </tr>
                  <tr >
                    <td className='font-weight-bold'>Nama Anak</td>
                    <td className='font-weight-bold'>{anak.nama}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{anak.status}</td>
                  </tr>
                  <tr>
                    <td>Jenjang Pendidikan</td>
                    <td>{anak.jenjang_pendidikan}</td>
                  </tr>
                  <tr>
                    <td>NISN</td>
                    <td>{anak.nisn}</td>
                  </tr>
                  <tr>
                    <td>Tahun Masuk</td>
                    <td>{anak.tahun_masuk}</td>
                  </tr>
                  <tr>
                    <td>JK</td>
                    <td>{anak.jk}</td>
                  </tr>
                  <tr>
                    <td>Tempat Lahir</td>
                    <td>{anak.tempat_lahir}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Lahir</td>
                    <td>{anak.tanggal_lahir}</td>
                  </tr>

                </React.Fragment>
              ))}
            </tbody>
          </table>

          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="5" className='text-center'>Data Sertifikasi</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.sertifikasi_id?.map((sertifikasi, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="6" className='text-center'> {index + 1}</td>
                  </tr>
                  <tr >
                    <td className='font-weight-bold'>Jenis Sertifikasi</td>
                    <td className='font-weight-bold'>{sertifikasi?.jenis_sertifikasi}</td>
                  </tr>
                  <tr>
                    <td>No Sertifikasi</td>
                    <td>{sertifikasi?.no_sertifikasi}</td>
                  </tr>
                  <tr>
                    <td>Tahun Sertifikasi</td>
                    <td>{sertifikasi?.thn_sertifikasi}</td>
                  </tr>
                  <tr>
                    <td>No Registrasi</td>
                    <td>{sertifikasi?.no_reg}</td>
                  </tr>
                  <tr>
                    <td>No Peserta</td>
                    <td>{sertifikasi?.no_peserta}</td>
                  </tr>


                </React.Fragment>
              ))}
            </tbody>
          </table>


          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="5" className='text-center'>Data Diklat</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.diklat_id?.map((diklat, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="7" className='text-center'> {index + 1}</td>
                  </tr>
                  <tr >
                    <td className='font-weight-bold'>Jenis Diklat</td>
                    <td className='font-weight-bold'>{diklat?.jenis_diklat}</td>
                  </tr>
                  <tr>
                    <td>Nama Diklat</td>
                    <td>{diklat?.nama_diklat}</td>
                  </tr>
                  <tr>
                    <td>Penyelenggara</td>
                    <td>{diklat?.penyelenggara}</td>
                  </tr>
                  <tr>
                    <td>Tahun Diklat</td>
                    <td>{diklat?.tahun_diklat}</td>
                  </tr>
                  <tr>
                    <td>Peran</td>
                    <td>{diklat?.peran}</td>
                  </tr>
                  <tr>
                    <td>Tingkat Diklat</td>
                    <td>{diklat?.tingkat_diklat}</td>
                  </tr>


                </React.Fragment>
              ))}
            </tbody>
          </table>
          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="5" className='text-center'>Data Penugasan</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.penugasan_id?.map((penugasan, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="7" className='text-center'> {index + 1}</td>
                  </tr>
                  <tr >
                    <td className='font-weight-bold'>No Surat Tugas</td>
                    <td className='font-weight-bold'>{penugasan?.no_surat_tugas}</td>
                  </tr>
                  <tr>
                    <td>Tangal Surat Tugas</td>
                    <td>{penugasan?.tanggal_surat_tugas}</td>
                  </tr>
                  <tr>
                    <td>TMT Tugas</td>
                    <td>{penugasan?.tmt_tugas}</td>
                  </tr>
                  <tr>
                    <td>Status Sekolah Induk</td>
                    <td>{penugasan?.status_sekolah_induk}</td>
                  </tr>
                  <tr>
                    <td>Keluar Karena</td>
                    <td>{penugasan?.keluar_karena}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Keluar</td>
                    <td>{penugasan?.tanggal_keluar}</td>
                  </tr>

                </React.Fragment>
              ))}
            </tbody>
          </table>
          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="5" className='text-center'>Data Tugas Tambahan</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.tugas_tambahan_id?.map((tambahan, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="6" className='text-center'> {index + 1}</td>
                  </tr>
                  <tr >
                    <td className='font-weight-bold'>Jabatan</td>
                    <td className='font-weight-bold'>{tambahan?.jabatan}</td>
                  </tr>
                  <tr>
                    <td>Prasarana</td>
                    <td>{tambahan?.prasarana}</td>
                  </tr>
                  <tr>
                    <td>No SK</td>
                    <td>{tambahan?.no_sk}</td>
                  </tr>
                  <tr>
                    <td>TMT Tambahan</td>
                    <td>{tambahan?.tmt_tambahan}</td>
                  </tr>
                  <tr>
                    <td>Keluar Karena</td>
                    <td>{tambahan?.tst_tambahan}</td>
                  </tr>


                </React.Fragment>
              ))}
            </tbody>
          </table>
          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="4" className='text-center'>Data Penghargaan</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.tugas_tambahan_id?.map((penghargaan, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="6" className='text-center'> {index + 1}</td>
                  </tr>
                  <tr >
                    <td className='font-weight-bold'>Tingkat Penghargaan</td>
                    <td className='font-weight-bold'>{penghargaan?.tingkat_penghargaan}</td>
                  </tr>
                  <tr>
                    <td>Jenis Penghargaan</td>
                    <td>{penghargaan?.jenis_penghargaan}</td>
                  </tr>
                  <tr>
                    <td>Nama Penghargaan</td>
                    <td>{penghargaan?.nama_penghargaan}</td>
                  </tr>
                  <tr>
                    <td>Tahun</td>
                    <td>{penghargaan?.tahun}</td>
                  </tr>
                  <tr>
                    <td>Penghargaan</td>
                    <td>{penghargaan?.instansi}</td>
                  </tr>


                </React.Fragment>
              ))}
            </tbody>
          </table>
          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="4" className='text-center'>Data Tunjangan</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.tunjangan_id?.map((tunjangan, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="7" className='text-center'> {index + 1}</td>
                  </tr>

                  <tr>
                    <td>Jenis Tunjangan</td>
                    <td>{tunjangan?.jenis_tunjangan}</td>
                  </tr>
                  <tr>
                    <td>No SK</td>
                    <td>{tunjangan?.no_sk}</td>
                  </tr>
                  <tr>
                    <td>Tanggal SK</td>
                    <td>{tunjangan?.tanggal_sk}</td>
                  </tr>

                  <tr>
                    <td>Sumber Dana</td>
                    <td>{tunjangan?.sumber_dana}</td>
                  </tr>
                  <tr>
                    <td>Nominal</td>
                    <td>{tunjangan?.nominal}</td>
                  </tr>
                  <tr>
                    <td>Masih Menerima</td>
                    <td>{tunjangan?.masih_menerima}</td>
                  </tr>

                </React.Fragment>
              ))}
            </tbody>
          </table>
          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="4" className='text-center'>Data Inpassing</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.inpassing_id?.map((inpassing, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="7" className='text-center'> {index + 1}</td>
                  </tr>

                  <tr>
                    <td>No SK</td>
                    <td>{inpassing?.no_sk}</td>
                  </tr>

                  <tr>
                    <td>Tanggal SK</td>
                    <td>{inpassing?.tanggal_sk}</td>
                  </tr>

                  <tr>
                    <td>TMT SK</td>
                    <td>{inpassing?.tmt_sk}</td>
                  </tr>
                  <tr>
                    <td>Angka Kridit</td>
                    <td>{inpassing?.angka_kridit}</td>
                  </tr>
                  <tr>
                    <td>Masa Kerja Tahun</td>
                    <td>{inpassing?.masa_kerja_thn}</td>
                  </tr>
                  <tr>
                    <td>Masa Kerja Bulan</td>
                    <td>{inpassing?.masa_kerja_bln}</td>
                  </tr>

                </React.Fragment>
              ))}
            </tbody>
          </table>

          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="4" className='text-center'>Data Jabatan</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.jabatan_id?.map((jabatan, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="7" className='text-center'> {index + 1}</td>
                  </tr>

                  <tr>
                    <td>Jabatan</td>
                    <td>{jabatan?.jabatan}</td>
                  </tr>

                  <tr>
                    <td>SK Jabatan</td>
                    <td>{jabatan?.sk_jabatan}</td>
                  </tr>

                  <tr>
                    <td>TMT Jabatan</td>
                    <td>{jabatan?.tmt_jabatan}</td>
                  </tr>

                </React.Fragment>
              ))}
            </tbody>
          </table>
          <table className='table-borderless table'>
            <thead className='thead-dark'>
              <tr>
                <th colSpan="4" className='text-center'>Data Gaji</th>
              </tr>
            </thead>
            <tbody>
              {detailGtk?.gaji_id?.map((gaji, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td rowSpan="7" className='text-center'> {index + 1}</td>
                  </tr>

                  <tr>
                    <td>Maja Kerja Tahun</td>
                    <td>{gaji?.masa_kerja_thn}</td>
                  </tr>

                  <tr>
                    <td>Masa Kerja Bulan</td>
                    <td>{gaji?.masa_kerja_bln}</td>
                  </tr>

                  <tr>
                    <td>Gaji Pokok</td>
                    <td>{gaji?.gaji_pokok}</td>
                  </tr>

                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </LayoutDashboard>
    </div>
  )
}

export default DetailGuru