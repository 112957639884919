import React, { useEffect } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditTunjangan = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    jenis_tunjangan: '',
    no_sk: '',
    tanggal_sk: '',
    sumber_dana: '',
    dari_tahun: '',
    sampai_tahun: '',
    nominal: '',
    masih_menerima: ''
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiEndpoint}/tunjangan/${id}`, {
          headers: {
            'ngrok-skip-browser-warning': 'true',
          },
        });
        setFormData(response.data.tunjangan);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const formErrors = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
    );

    if (Object.values(formErrors).some((error) => error)) {
      setErrors(formErrors);
      return;
    }

    const result = await Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah Anda yakin data sudah benar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    });

    if (result.isConfirmed) {
      try {
        await axios.put(`${apiEndpoint}/tunjangan/update/${id}`, formData);
        Swal.fire({
          title: 'Sukses!',
          text: 'Berhasil Update Data',
          icon: 'success',
        }).then(() => navigate('/dashboard/pembimbing/tunjangan/show'));
      } catch (error) {
        console.log(error, "error");
      }
    }
  };
  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Dashboard" dua="Data Tunjangan" urlSatu="/dashboard/" />
        <div className='card-box shadow-sm p-4 mb-30'>
          <form onSubmit={(e) => handleSubmit(e)} >
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='jenis_tunjangan' className='form-label'>Jenis Tunjangan :</label>
                  <input type='text'
                    className={`form-control ${errors.jenis_tunjangan ? 'is-invalid' : ''}`}
                    value={formData.jenis_tunjangan}
                    onChange={(e) => handleChange(e)}
                    name='jenis_tunjangan' />
                  {errors.jenis_tunjangan && <p style={{ color: "red" }}>{errors.jenis_tunjangan}</p>}

                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor='no_sk' className='form-label'>Nomor SK :</label>
                <input type='number'
                  className={`form-control ${errors.no_sk ? 'is-invalid' : ''}`}
                  value={formData.no_sk}
                  onChange={(e) => handleChange(e)}
                  name='no_sk' />
                {errors.no_sk && <p style={{ color: "red" }}>{errors.no_sk}</p>}

              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='tanggal_sk' className='form-label'>Tanggal SK :</label>
                  <input type='date' className={`form-control ${errors.tanggal_sk ? 'is-invalid' : ''}`}
                    value={formData.tanggal_sk}
                    onChange={(e) => handleChange(e)}

                    name='tanggal_sk' />
                  {errors.tanggal_sk && <p style={{ color: "red" }}>{errors.tanggal_sk}</p>}

                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor='sumber_dana' className='form-label'>Sumber Dana :</label>
                <input type='text' className={`form-control ${errors.sumber_dana ? 'is-invalid' : ''}`}
                  value={formData.sumber_dana}
                  onChange={(e) => handleChange(e)}
                  name='sumber_dana' />
                {errors.sumber_dana && <p style={{ color: "red" }}>{errors.sumber_dana}</p>}

              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='dari_tahun' className='form-label'>Dari Tahun :</label>
                  <input type='number' className={`form-control ${errors.dari_tahun ? 'is-invalid' : ''}`}
                    value={formData.dari_tahun}
                    onChange={(e) => handleChange(e)}
                    name='dari_tahun' />
                  {errors.dari_tahun && <p style={{ color: "red" }}>{errors.dari_tahun}</p>}

                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor='sampai_tahun' className='form-label'>Sampai Tahun :</label>
                <input type='number' className={`form-control ${errors.sampai_tahun ? 'is-invalid' : ''}`}
                  value={formData.sampai_tahun}
                  onChange={(e) => handleChange(e)}
                  name='sampai_tahun' />
                {errors.sampai_tahun && <p style={{ color: "red" }}>{errors.sampai_tahun}</p>}

              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='nominal' className='form-label'>Nominal :</label>
                  <input type='text' className={`form-control ${errors.nominal ? 'is-invalid' : ''}`}
                    value={formData.nominal}
                    onChange={(e) => handleChange(e)}
                    name='nominal' />
                  {errors.nominal && <p style={{ color: "red" }}>{errors.nominal}</p>}

                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor='masih_menerima' className='form-label'>Masih Menerima :</label>
                <select
                  className={`form-select ${errors.masih_menerima ? 'is-invalid' : ''}`}
                  name="masih_menerima"
                  value={formData.masih_menerima}
                  onChange={(e) => handleChange(e)}

                >
                  <option value="" disabled>Pilih</option>
                  <option value="true">Iya</option>
                  <option value="false">Tidak</option>
                </select>

                {errors.masih_menerima && <p style={{ color: "red" }}>{errors.masih_menerima}</p>}

              </div>
            </div>

            <button className='btn btn-primary mt-2'>Kirim Data</button>

          </form>

        </div>



      </LayoutDashboard>
    </div>
  )
}
