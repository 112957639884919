import React, { useContext, useEffect, useState } from 'react';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';

import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import AllGtkContext from '../../../../context/AllGtkContext';
import RayonContext from '../../../../context/RayonContext';
import Select from 'react-select'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import GtkContext from '../../../../context/GtkContext';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const initialValues = {
    "nama_rayon": '',
    "pembimbing": '',
    "no_ruangan": '', 
}

export const EditRayonPage = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const {allGtk} = useContext(AllGtkContext);
    const { getRayons } = useContext(RayonContext);
    const [errorMessage, setErrorMessage] = useState('');

    const optionsGtk = allGtk.map(gtk => ({label: gtk.nama_lengkap, value: gtk.user_id}));

    const [formData, setFormData] = useState({
        "nama_rayon": "",
        "pembimbing_id": "",
        "no_ruangan": ""
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/rayon/${id}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true', 
                    },
                });
                setFormData(response.data.rayon);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
    };
    
    const handleGtkChange = (selectedOption) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            pembimbing_id: selectedOption.value
        }));
    };

    const onSubmit = (values) => {
        Swal.fire({
          title: 'Apakah data sudah benar?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ya, kirim!',
          cancelButtonText: 'Batal',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        }).then((confirmed) => {
          if (confirmed.isConfirmed) {
            Swal.fire({
              title: 'Mohon tunggu...',
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              },
            });
    
            axios.post(apiEndpoint, values)
              .then((response) => {
                Swal.fire({
                  icon: 'success',
                  title: 'Data berhasil disimpan!',
                  showConfirmButton: false,
                  timer: 1500,
                });
                setTimeout(() => {
                  getRayons()
                  navigate('/admin/rayon/show');
                }, 2000);
              })
              .catch((error) => {
                setErrorMessage(error.response.data.error);
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: error.response.data.error,
                });
              });
          }
        });
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/update-rayon/${id}`, formData);
                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/admin/rayon/show'));
            } catch (error) {
                console.log(error, "error");
            }
        }
    };

    const validationSchema = Yup.object({
        nama_rayon: Yup.string().required('Required'),
        pembimbing: Yup.string().required('Required'),
        no_ruangan: Yup.string().required('Required')
      
      })
    
      const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
      });
    
    
      const optionPembimbing = allGtk?.map(item => ({
        value: item.user_id._id,
        label: item.user_id.username
      }));
      

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Edit Data Rayon" tiga="Master Data" empat="Rayon" urlEmpat="/admin/rayon/show" urlSatu="/admin" />
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>
                        <div className='row g-3'>
                            <div className='col-md-6'>
                                <label className='form-label' htmlFor='nama_rayon'>Nama Rayon</label>
                                <input type='text'
                                className={`form-control ${errors.nama_rayon ? "is-invalid" : ""}`}
                                name='nama_rayon'
                                id='nama_rayon'
                                onChange={(e) => handleChange(e)}
                                defaultValue={formData?.nama_rayon}
                                />
                                {errors.nama_rayon ? <span className='text-danger'>{errors.nama_rayon}</span> : null}
                            </div>
                            <div className='col-md-6'>
                                <label >Pembimbing</label>
                                {/* <Select
                                    name="pembimbing"
                                    id='pembimbing'
                                    // className={` ${errors.pembimbing_id ? "is-invalid" : ""}`}
                                    options={allGtk.map(gtk => ({label: gtk.nama_lengkap, value: gtk._id}))}
                                    placeholder={formData.pembimbing_id ? formData.pembimbing_id : "Pilih Pembimbing"}
                                    // options={optionPembimbing}
                                    // onChange={e => handleChange(e)}
                                    // onChange={(selectedOption) => formik.setFieldValue("pembimbing", selectedOption.value)}
                                    // onBlur={() => formik.setFieldTouched("pembimbing", true)}
                                    // value={optionPembimbing?.find(option => option.value === formData?.pembimbing_id)}
                                    defaultValue={formData.pembimbing_id}
                                /> */}
                                <Select
                                    options={optionsGtk}
                                    onChange={handleGtkChange}
                                    defaultValue={formData.pembimbing_id._id}
                                    placeholder={formData.pembimbing_id.username ? formData.pembimbing_id.username : "Pilih Pembimbing"}
                                    name='pembimbing_id'
                                    id='pembimbing_id'
                                />
                                {errors.pembimbing_id ? <span className='text-danger'>{errors.pembimbing}</span> : null}
                            </div>
                        
                            <div className='col-md-6'>
                                <label className='form-label' htmlFor='nama_rayon'>No Ruangan</label>
                                <input type='text'
                                className={`form-control ${errors.no_ruangan ? "is-invalid" : ""}`}
                                name='no_ruangan'
                                id='no_ruangan'
                                onChange={(e) => handleChange(e)}
                                value={formData.no_ruangan}
                                // {...formik.getFieldProps("no_ruangan")}
                                />
                                {errors.no_ruangan ? <span className='text-danger'>{errors.no_ruangan}</span> : null}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>

                    </div>

                </form>
            </LayoutDashboard>
        </div>
    );
}
