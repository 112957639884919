import React, { useContext, useEffect, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import { useNavigate } from 'react-router-dom';
import GtkContext from '../../../../context/GtkContext';
import axios from 'axios';
import Swal from 'sweetalert2';

export const CreateTugasTambahan = () => {
  const { gtk } = useContext(GtkContext);
  const navigate = useNavigate();
  const gtkId = gtk?.gtk?._id;

  const [formData, setFormData] = useState({
    jabatan: '',
    no_sk: '',
    tmt_tambahan: '', // required field
    tst_tambahan: '', // optional field
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : 'Wajib diisi' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const formErrors = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [
        key,
        key === 'tmt_tambahan' ? (value.trim() ? '' : 'Wajib diisi') : '', // tmt_tambahan is required
      ])
    );

    if (Object.values(formErrors).some((error) => error)) {
      setErrors(formErrors);
      return;
    }

    const result = await Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah Anda yakin data sudah benar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    });

    if (result.isConfirmed) {
      if (gtk && gtkId) {
        try {
          await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/tugas/create/${gtkId}`, formData);

          Swal.fire({
            title: 'Sukses!',
            text: 'Berhasil Create Data',
            icon: 'success',
          }).then(() => {
            navigate('/dashboard/pembimbing/tugas-tambahan/show');
          });
        } catch (error) {
          console.error('Error creating data:', error);
          Swal.fire({
            title: 'Error!',
            text: 'Gagal Create Data',
            icon: 'error',
          });
        }
      } else {
        console.log('Tidak ada ID GTK');
      }
    }
  };

  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Home" dua="Data Tugas Tambahan" />

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="card-box shadow-sm p-4 mb-30">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="jabatan" className="form-label">
                    Jabatan PTK :
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errors.jabatan ? 'is-invalid' : ''}`}
                    value={formData.jabatan}
                    onChange={(e) => handleChange(e)}
                    name="jabatan"
                  />
                  {errors.jabatan && <p style={{ color: 'red' }}>{errors.jabatan}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="no_sk" className="form-label">
                  No Sk Tugas Tambahan :
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.no_sk ? 'is-invalid' : ''}`}
                  value={formData.no_sk}
                  onChange={(e) => handleChange(e)}
                  name="no_sk"
                />
                {errors.no_sk && <p style={{ color: 'red' }}>{errors.no_sk}</p>}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="tmt_tambahan" className="form-label">
                    TMT Tugas Tambahan :
                  </label>
                  <input
                    type="date"
                    className={`form-control ${errors.tmt_tambahan ? 'is-invalid' : ''}`}
                    value={formData.tmt_tambahan}
                    onChange={(e) => handleChange(e)}
                    name="tmt_tambahan"
                  />
                  {errors.tmt_tambahan && <p style={{ color: 'red' }}>{errors.tmt_tambahan}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="tst_tambahan" className="form-label">
                  TST Tugas Tambahan :
                </label>
                <input
                  type="date"
                  className={`form-control ${errors.tst_tambahan ? 'is-invalid' : ''}`}
                  value={formData.tst_tambahan}
                  onChange={(e) => handleChange(e)}
                  name="tst_tambahan"
                />
                {errors.tst_tambahan && <p style={{ color: 'red' }}>{errors.tst_tambahan}</p>}
              </div>
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </LayoutDashboard>
    </div>
  );
};
