import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { useContext } from 'react';
import AuthContext from './AuthContext';

export const GtkContext = createContext();


export const GtkProvider = ({ children }) => {
    const [gtk, setGtk] = useState([]);
    const { user } = useContext(AuthContext);
    const [statusKepeg, setStatusKepeg] = useState([])
    const [jenisPtk, setJenisPtk] = useState([])
    const [gtkId, setGtkId] = useState([])

    const getGtk = async () => {
        try {
            if (user.role !== "student" && user.role !== "admin") {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/dashboard/gtk/${user?._id}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    },
                });
                console.log('Response data:', response.data);
                setGtk(response.data || []);
            }
        } catch (error) {
            console.log("Server responded with an error", error);
        }
    };

    const getGtkWithRayon = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/data/name-id-gtk`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            })

        } catch (error) {

        }
    }

    const getStatusKepegawaian = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/status-kepeg`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            })
            setStatusKepeg(response?.data.status_kepegawaian)
        } catch (error) {
            console.log("Error Mendapatkan Status kepegawaian");

        }
    }

    const getJenisPtk = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/jenis-ptk`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            })
            setJenisPtk(response?.data.jenis_ptk)
        } catch (error) {
            console.log("Error Mendapatkan Status kepegawaian");

        }
    }

    const getGtkId = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/data/name-id-gtk`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            })
            setGtkId(response?.data.data)
        } catch (error) {
            console.log("Error Mendapatkan Status kepegawaian");

        }
    }

   

    useEffect(() => {
        getGtk();
        getJenisPtk();
        getStatusKepegawaian();
        getGtkId()
    }, [user])

    const state = {
        gtk,
        statusKepeg,
        jenisPtk,
        gtkId,
        getStatusKepegawaian,
        getGtk
    }
    return (
        <GtkContext.Provider value={state}>
            {children}
        </GtkContext.Provider>
    );
};

export default GtkContext;


