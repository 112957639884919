import React, { useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditDiklat = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        'jenis_diklat': "",
        "nama_diklat": "",
        "penyelenggara": "",
        "tahun_diklat": "",
        "peran": "",
        "tingkat_diklat": "",

    })


    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/diklat/${id}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                      }
                });
                setFormData(response.data.diklat);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : `wajib diisi` }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();


        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/diklat/update/${id}`, formData);
                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/dashboard/pembimbing/diklat/show'));
            } catch (error) {
                console.log(error, "error");
            }
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Edit Data Diklat" tiga="Lainnya" empat="Diklat" urlEmpat="/dashboard/pembimbing/diklat/show" urlSatu="/dashboard/pembimbing" />

                {/* create-diklat */}
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>


                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='jenis_diklat' className='form-label'>Jenis Diklat :</label>
                                    <input type='text' className={`form-control ${errors.jenis_diklat ? 'is-invalid' : ''}`}
                                        value={formData.jenis_diklat}
                                        onChange={(e) => handleChange(e)}
                                        name='jenis_diklat' />
                                    {errors.jenis_diklat && <p style={{ color: "red" }}>{errors.jenis_diklat}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='nama_diklat' className='form-label'>Nama Diklat :</label>
                                <input type='text' className={`form-control ${errors.nama_diklat ? 'is-invalid' : ''}`}
                                    value={formData.nama_diklat}
                                    onChange={(e) => handleChange(e)}
                                    name='nama_diklat' />
                                {errors.nama_diklat && <p style={{ color: "red" }}>{errors.nama_diklat}</p>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='penyelenggara' className='form-label'>Penyelenggara  :</label>
                                    <input type='text' className={`form-control ${errors.penyelenggara ? 'is-invalid' : ''}`}
                                        value={formData.penyelenggara}
                                        onChange={(e) => handleChange(e)}
                                        name='penyelenggara' />
                                    {errors.penyelenggara && <p style={{ color: "red" }}>{errors.penyelenggara}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tahun_diklat' className='form-label'>Tahun Diklat :</label>
                                <input type='number' className={`form-control ${errors.tahun_diklat ? 'is-invalid' : ''}`}
                                    value={formData.tahun_diklat}
                                    onChange={(e) => handleChange(e)}
                                    name='tahun_diklat' />
                                {errors.tahun_diklat && <p style={{ color: "red" }}>{errors.tahun_diklat}</p>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='peran' className='form-label'>Peran :</label>
                                    <input type='text' className={`form-control ${errors.peran ? 'is-invalid' : ''}`}
                                        value={formData.peran}
                                        onChange={(e) => handleChange(e)}
                                        name='peran' />
                                    {errors.peran && <p style={{ color: "red" }}>{errors.peran}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tingkat_diklat' className='form-label'>Tingkat Diklat :</label>
                                    <input type='text' className={`form-control ${errors.tingkat_diklat ? 'is-invalid' : ''}`}
                                        value={formData.tingkat_diklat}
                                        onChange={(e) => handleChange(e)}
                                        name='tingkat_diklat' />
                                    {errors.tingkat_diklat && <p style={{ color: "red" }}>{errors.tingkat_diklat}</p>}

                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>



            </LayoutDashboard>
        </div>
    )
}
