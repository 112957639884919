import React, {  useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditTugasTambahan = () => {
    const { id } = useParams();
    const navigate = useNavigate();


    const [formData, setFormData] = useState({
        'jabatan': "",
        "no_sk": "",
        "tmt_tambahan": "",
        "tst_tambahan": "",
    })

    const [errors, setErrors] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/tugas/${id}`);
                setFormData(response.data.tugas_tambahan);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();


        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/tugas/update/${id}`, formData);
                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/dashboard/pembimbing/tugas-tambahan/show'));
            } catch (error) {
                console.log(error, "error");
            }
        }
    }

    return (
        <div>
            <LayoutDashboard>
            <GelBreadcrumb satu="Dashboard" dua="Edit Data Tugas Tambahan" tiga="Lainnya" empat="Inpassing" urlEmpat="/dashboard/pembimbing/tugas-tambahan/show" urlSatu="/dashboard/pembimbing" />

                {/* create-tugas-tambahan */}
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='jabatan' className='form-label'>Jabatan PTK :</label>
                                    <input type='text' className={`form-control ${errors.jabatan ? 'is-invalid' : ''}`}
                                        value={formData.jabatan}
                                        onChange={(e) => handleChange(e)}
                                        name='jabatan' />
                                    {errors.jabatan && <p style={{ color: "red" }}>{errors.jabatan}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='no_sk' className='form-label'>No Sk Tugas Tambahan :</label>
                                <input type='text' className={`form-control ${errors.no_sk ? 'is-invalid' : ''}`}
                                    value={formData.no_sk}
                                    onChange={(e) => handleChange(e)}
                                    name='no_sk' />
                                {errors.no_sk && <p style={{ color: "red" }}>{errors.no_sk}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tmt_tambahan' className='form-label'>TMT Tugas Tambahan  :</label>
                                    <input type='date' className={`form-control ${errors.tmt_tambahan ? 'is-invalid' : ''}`}
                                        value={formData.tmt_tambahan}
                                        onChange={(e) => handleChange(e)}
                                        name='tmt_tambahan' />
                                    {errors.tmt_tambahan && <p style={{ color: "red" }}>{errors.tmt_tambahan}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tst_tambahan' className='form-label'>TST Tugas Tambahan :</label>
                                <input type='date' className={`form-control ${errors.tst_tambahan ? 'is-invalid' : ''}`}
                                    value={formData.tst_tambahan}
                                    onChange={(e) => handleChange(e)}
                                    name='tst_tambahan'
                                    required={false} />
                                {errors.tst_tambahan && <p style={{ color: "red" }}>{errors.tst_tambahan}</p>}
                            </div>
                        </div>


                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>

                </form>


            </LayoutDashboard>
        </div>
    )
}
