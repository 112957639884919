import React, { useState } from 'react'
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import LayoutDashboard from '../../layouts/LayoutDashboard'
import Banner from "../../assets/images/banner11.png"
import { useEffect } from 'react'
import { AllStudentContext } from '../../context/AllStudentContext'
import { GoPeople } from "react-icons/go";
import { CgGirl } from "react-icons/cg";
import { CgBoy } from "react-icons/cg";
import { MdOutlineSmsFailed } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import DashboardContext from '../../context/DashboardContext'
import RayonContext from '../../context/RayonContext'
import { RombelContext } from '../../context/RombelContext'
import { SiGoogleclassroom } from "react-icons/si";

import { LiaSchoolSolid } from "react-icons/lia";
import DashCard from '../../components/globalcomp/DashCard'
import AllGtkContext from '../../context/AllGtkContext'
import { Link } from 'react-router-dom'

export const DashboardPage = () => {
  const { user } = useContext(AuthContext);
  const { allStudent } = useContext(AllStudentContext);
  const { AdminDataBelumLengkap, AdminDataSudahLengkap, AdminDataDiriLengkap, AdminDataDiriBelum, AdminDataFamilyLengkap, AdminDataFamilyBelum, AdminDataDokumenLengkap, AdminDataDokumenBelum } = useContext(DashboardContext);

  const [isLoading, setIsLoading] = useState(true);
  const { rombels } = useContext(RombelContext)
  const { rayons } = useContext(RayonContext);
  const { allGtkCount } = useContext(AllGtkContext)

  const [p, setP] = useState(0)
  const [l, setL] = useState(0)

  useEffect(() => {
    setIsLoading(false)
    if (allStudent && allStudent.length > 0) {
      const maleStudents = allStudent.filter((allStudent) => allStudent.jk === 'L');
      const femaleStudents = allStudent.filter((allStudent) => allStudent.jk === 'P');
      setL(maleStudents.length);
      setP(femaleStudents.length);
    }
  }, [allStudent]);

  return (
    <div>
      <LayoutDashboard>
        <div className='card-box shadow-sm p-4 mb-4' style={{ position: 'relative' }}>
          <img src={Banner} alt="" style={{ borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' }} />
          <div style={{ position: 'absolute', top: '52%', left: '20%', transform: 'translate(-50%, -50%)', textAlign: 'left', color: '#fff', marginLeft: '20px' }}>
            <h4 className="font-16  text-capitalize">
              Selamat Datang
              <div className="weight-700 font-30 text-grey">Admin {user.username}</div>
            </h4>
            <p className="font-15 max-width-600 text-white">
              Kelola data dengan mudah disini
            </p>
          </div>
        </div>

        <div className="row pb-10">
          <DashCard title="Total Siswa" icon={GoPeople} total={allStudent.length} />
          <DashCard title="Total  GTK" icon={GoPeople} total={allGtkCount} />
          <DashCard title="Total Siswa Laki-laki" icon={CgBoy} total={l} isLoading={isLoading} />
          <DashCard title="Total Siswa Perempuan" icon={CgGirl} total={p} isLoading={isLoading} />
          
          <DashCard title="Total Rombel" icon={SiGoogleclassroom} total={rombels.length} isLoading={isLoading} />
          <DashCard title="Total Rayon" icon={LiaSchoolSolid} total={rayons.length} />
          
        </div>
        <div className="row pb-10">
          {/* <DashCard title="Sudah Mengisi Data" icon={FaRegCircleCheck} total={AdminDataSudahLengkap} isLoading={isLoading} />
          <DashCard title="Belum Mengisi Data" icon={MdOutlineSmsFailed} total={AdminDataBelumLengkap} isLoading={isLoading} /> */}

          <DashCard title="Sudah Mengisi Data Diri" icon={FaRegCircleCheck} total={AdminDataDiriLengkap} isLoading={isLoading} />

          <DashCard title="Belum Mengisi Data Diri" icon={MdOutlineSmsFailed} total={AdminDataDiriBelum} isLoading={isLoading} linkDetail="/dashboard/detail-data/diri/belum" />

          <DashCard title="Sudah Mengisi Data Keluarga" icon={FaRegCircleCheck} total={AdminDataFamilyLengkap} isLoading={isLoading} />
          <DashCard title="Belum Mengisi Data Keluarga" icon={MdOutlineSmsFailed} total={AdminDataFamilyBelum} isLoading={isLoading} linkDetail={'/dashboard/detail-data/keluarga/belum'} />

          <DashCard title="Sudah Mengisi Data Dokumen" icon={FaRegCircleCheck} total={AdminDataDokumenLengkap} isLoading={isLoading} />
          <DashCard title="Belum Mengisi Data Dokumen" icon={MdOutlineSmsFailed} total={AdminDataDokumenBelum} isLoading={isLoading} linkDetail={'/dashboard/detail-data/dokumen/belum'} />

        </div>
      </LayoutDashboard>
    </div>
  )
}

export default DashboardPage