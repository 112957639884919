import React, { useContext, useEffect } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import GtkContext from '../../../../context/GtkContext'
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditJabatan = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        jabatan: '',
        sk_jabatan: '',
        tmt_jabatan: '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/jabatan/${id}`);
                setFormData(response.data.riwayat_jabatan);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/jabatan/update/${id}`, formData);
                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/dashboard/pembimbing/jabatan/show'));
            } catch (error) {
                console.log(error, "error");
            }
        }
    };


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Data Jabatan" />
                <div className='card-box shadow-sm p-4 mb-30'>
                    {/* {
                        alertError ? <p className='alert alert-danger'>{alertError}</p> : ""
                    } */}
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='jabatan' className='form-label'>Jabatan :</label>
                                    <input type='text'
                                        className={`form-control ${errors.jabatan ? 'is-invalid' : ''}`}
                                        value={formData.jabatan}
                                        onChange={handleChange} name='jabatan' />
                                    {errors.jabatan && (
                                        <div className='invalid-feedback'>{errors.jabatan}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='sk_jabatan' className='form-label'>SK Jabatan :</label>
                                <input type='number'
                                    className={`form-control ${errors.sk_jabatan ? 'is-invalid' : ''}`}
                                    value={formData.sk_jabatan}
                                    onChange={handleChange} name='sk_jabatan' />
                                {errors.sk_jabatan && (
                                    <div className='invalid-feedback'>{errors.sk_jabatan}</div>
                                )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tmt_jabatan' className='form-label'>TMT Jabatan :</label>
                                    <input type='date'
                                        name='tmt_jabatan'
                                        className={`form-control ${errors.tmt_jabatan ? 'is-invalid' : ''}`}
                                        value={formData.tmt_jabatan}
                                        onChange={handleChange} />
                                    {errors.tmt_jabatan && (
                                        <div className='invalid-feedback'>{errors.tmt_jabatan}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>

                    </form>
                </div>




            </LayoutDashboard>
        </div>
    )
}
