import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { Information } from '../../../../components/globalcomp/Information'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { BsPencil, BsTrash, BsTrashFill } from 'react-icons/bs'
import axios from 'axios'
import Swal from 'sweetalert2'
import { confirmDelete } from '../../../../function/delete'

export const ShowJabatan = () => {

    const { gtk, getGtk } = useContext(GtkContext)

    const [showGtk, setGtk] = useState([]);
    useEffect(() => {
        setGtk(gtk.gtk?.jabatan_id)
        getGtk()
    }, [gtk])


    return (
        <div>
            <LayoutDashboard>
            <GelBreadcrumb satu="Dashboard" tiga="Kepegawaian" urlSatu="/dashboard/pembimbing"  dua="Data Jabatan"/>
                <Information title="jabatan" />
                <div className='card-box shadow-sm p-4 mb-30'>

                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/jabatan/create">
                        <button type="button" className="btn btn-primary bg-blue mb-2 text-white">Tambah Data Jabatan</button>
                        </Link>
                    </div>
                    <div className="flex w-full overflow-x-auto mt-10">
                        <table className="table table-borderless">
                            <thead className='thead-dark'>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Jabatan</th>
                                    <th scope="col">SK Jabatan (Bulan)</th>
                                    <th scope="col">TMT Jabatan</th>
                                    <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showGtk && showGtk.length > 0 ? (
                                    showGtk.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.jabatan}</td>
                                            <td>{item.sk_jabatan}</td>
                                            <td>{item.tmt_jabatan}</td>
                                            <td className='d-flex '>
                                            <Link to={`/dashboard/pembimbing/jabatan/edit/${item._id}`}>
                                                <button className='btn btn-warning text-white'>
                                                    <BsPencil /> Ubah
                                                </button>
                                            </Link>
                                            <button
                                                className='btn btn-danger ml-3 btn-sm'
                                                onClick={() => confirmDelete(item._id, 'jabatan/delete', getGtk)}
                                            >
                                                <BsTrashFill /> Hapus
                                            </button>
                                        </td>
                                        </tr>
                                    ))
                                ) : (
                                    <Kosong />
                                )}
                            </tbody>

                        </table>
                    </div>
                </div>



            </LayoutDashboard>
        </div>
    )
}
