import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { BsPencil, BsTrashFill } from 'react-icons/bs'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { Information } from '../../../../components/globalcomp/Information'
import { confirmDelete } from '../../../../function/delete'

export const ShowPenugasan = () => {

  document.title = "Data Penugasan";

  const { gtk, getGtk } = useContext(GtkContext)
  const [getPenugasan, setPenugasan] = useState([])


  useEffect(() => {
    setPenugasan(gtk?.gtk?.penugasan_id)
  }, [gtk.gtk])

  useEffect(() => {
    getGtk()
  }, [])


  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Dashboard" tiga="Lainnya" urlSatu="/dashboard/pembimbing" dua="Data Penugasan" />
        <Information title="penugasan" />
        <div className='card-box shadow-sm p-4 mb-30'>
          <div className='mb-20'>
            <Link to="/dashboard/pembimbing/penugasan/create">
              <button style={{ marginBottom: '30px' }} type="button" className="btn btn-primary bg-blue text-white mb-2">Tambah Data Penugasan</button>
            </Link>
          </div>
          <table className="table table-borderless">
            <thead className='thead-dark'>
              <tr>
                <th scope="col">#</th>
                <th scope="col">No Surat Tugas</th>
                <th scope="col">Tanggal Surat Tugas</th>
                <th scope="col">TMT Tugas</th>
                <th scope="col">Status Sekolah Induk</th>
                <th scope="col">Keluar Karena </th>
                <th scope="col">Tanggal Keluar </th>
                <th scope="col">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {getPenugasan && getPenugasan.length > 0 ? (
                getPenugasan.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.no_surat_tugas}</td>
                    <td>{item.tanggal_surat_tugas}</td>
                    <td>{item.tmt_tugas}</td>
                    <td><span className='badge badge-primary'>{item.status_sekolah_induk === true ? "IYA" : "TIDAK"}</span></td>
                    <td>{item.keluar_karena}</td>
                    <td>{item.tanggal_keluar}</td>
                    <td className='d-flex justify-center items-center text-center'>
                      <Link to={`/dashboard/pembimbing/penugasan/edit/${item._id}`}>
                        <button className='btn btn-warning text-white btn-sm'>
                          <BsPencil /> Ubah
                        </button>
                      </Link>
                      <button
                        className='btn btn-danger ml-3 btn-sm'
                        onClick={() => confirmDelete(item._id, 'penugasan/delete', getGtk)}
                      >
                        <BsTrashFill /> Hapus
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <Kosong />
              )}
            </tbody>
          </table>
        </div>
      </LayoutDashboard>
    </div>
  )
}
