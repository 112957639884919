import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { Information } from '../../../../components/globalcomp/Information'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { BsPencil, BsTrashFill } from 'react-icons/bs'

import { confirmDelete } from '../../../../function/delete'

export const ShowBeasiswa = () => {
    document.title = "Data Beasiswa";

    const { gtk, getGtk } = useContext(GtkContext)
    const [getBeasiswa, setBeasiswa] = useState([])
    useEffect(() => {
        setBeasiswa(gtk.gtk?.beasiswa_id)
    }, [gtk.gtk])

    useEffect(() => {
        getGtk()
    }, [])

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" tiga="Lainnya" urlSatu="/dashboard/pembimbing" dua="Data Beasiswa" />
                <Information title="beasiswa" />

                <div className='card-box shadow-sm p-4 mb-30'>

                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/beasiswa/create">
                            <button type="button" className="btn btn-primary bg-blue text-white mb-2">Tambah Data Beasiswa</button>
                        </Link>

                    </div>
                    <table className="table table-borderless">
                        <thead className='thead-dark'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Jenis Beasiswa</th>
                                <th scope="col">Keterangan</th>
                                <th scope="col">Tahun Mulai</th>
                                <th scope="col">Tahun Akhir</th>
                                <th scope="col">Masih Menerima?</th>
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getBeasiswa && getBeasiswa.length > 0 ? (
                                getBeasiswa.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.jenis_beasiswa}</td>
                                        <td>{item.keterangan}</td>
                                        <td>{item.tahun_mulai}</td>
                                        <td>{item.tahun_akhir}</td>
                                        <td>{item.masih_menerima === true ? "Ya" : "Tidak"}</td>
                                        <td className='d-flex '>
                                            <Link to={`/dashboard/pembimbing/beasiswa/edit/${item._id}`}>
                                                <button className='btn btn-warning text-white'>
                                                    <BsPencil /> Ubah
                                                </button>
                                            </Link>

                                            <button
                                                className='btn btn-danger ml-3 btn-sm'
                                                onClick={() => confirmDelete(item._id, 'beasiswa/delete', getGtk)}
                                            >
                                                <BsTrashFill /> Hapus
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <Kosong />
                            )}
                        </tbody>

                    </table>
                </div>



            </LayoutDashboard>
        </div>
    )
}
