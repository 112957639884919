import axios from 'axios';
import Swal from 'sweetalert2';

const importService = async (file, field_data, apiEndpointImport, AllGetData) => {
  const confirmResult = await Swal.fire({
    title: 'Apakah Anda yakin?',
    text: 'Anda akan mengimport data ini!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ya, Import!',
  });

  if (confirmResult.isConfirmed) {
    try {
      Swal.fire({
        title: 'Sedang Mengimport...',
        text: 'Mohon tunggu',
        icon: 'info',
        allowOutsideClick: () => !Swal.isLoading(),
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      if (file[0]) {
        console.log('ini udah di submit', file[0])
        const formDataToSend = new FormData();
        formDataToSend.append(field_data, file[0]);
        await axios.post(
          apiEndpointImport,
          formDataToSend,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        AllGetData();
        Swal.fire({
          title: 'Sukses!',
          text: 'Data Berhasil Diimport',
          icon: 'success',
        });
      } else {
        console.log("file is undefined");
      }
    } catch (error) {
      console.log(error, "kenapa ini")
      const errorMessage = error.response.data?.message || "Terjadi kesalahan saat mengimport data!";
      console.error(errorMessage);
      Swal.fire({
        title: 'Oops...',
        text: errorMessage,
        icon: 'error',
      });
    } finally {
      Swal.hideLoading();
    }
  }
};

export default importService;
