import React, { useEffect } from 'react';
import PdfGenerator from './PdfGenerator';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';

const TestPrint = () => {
    
    let { id } = useParams();

    return (
        <div>
            <h1>Multi-Page PDF Generator</h1>
            <PdfGenerator id={id} />
        </div>
    );
};

export default TestPrint;
