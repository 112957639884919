import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../../../context/AuthContext';
import { Kosong } from '../../../../../components/globalcomp/Kosong';
import { FaRegCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';

export const Portfolio = ({ studentData }) => {
    const { user } = useContext(AuthContext);
    const [portfolioSiswa, setPortfolioSiswa] = useState({});
    const [selectedDocument, setSelectedDocument] = useState(null); // State untuk menyimpan data dokumen yang dipilih

    // console.log(studentData, 'ini di port')
    const studentId = studentData._id
    const portfolioId = studentData.portfolio_id
    // const [studentData, setStudentData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/portfolio/${studentId}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });
            setPortfolioSiswa(response.data);
            console.log(portfolioSiswa, "data port")
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [studentId]);

    useEffect(() => {
        console.log(studentData, "data student");
    }, [studentData]);

    const openModal = (documentName, documentUrl) => {
        console.log(documentName,documentUrl)
        setSelectedDocument({ documentName, documentUrl });
    };

    return (
        <div className="pd-20 mb-30">
            <div className="clearfix">
                <div className="pull-left">
                    <h4 className="text-blue h4">Data Portfolio</h4>
                    {user.role === 'student' && <p className="mb-30">Lengkapi Data Jika Masih Kosong</p>}
                </div>
                <div className="pull-right">
                    {user.role === 'student' ? (
                        <Link to="/dashboard/update/data-portfolio">
                            <button className='btn btn-success py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                                Lengkapi Data Portfolio
                            </button>
                        </Link>
                    ) : ''}
                </div>
            </div>

            <table className="table table-bordered">
                {/* <thead>
                    <tr>
                        <th>Nama Proyek</th>
                        <th>Pengerjaan</th>
                        <th>Penyelenggara</th>
                        <th>Portfolio</th>
                        <th>Action</th>
                    </tr>
                </thead> */}
                <tbody>
                        {Object.keys(portfolioSiswa).length > 0 ? (
                            Object.entries(portfolioSiswa.portfolio).map(([key, value]) => (
                                <>
                                    {console.log(value._id)}
                                    <tr>
                                        <td colspan="2" className='font-weight-bold text-center'>
                                            Portfolio {Number(++key)}
                                        </td>
                                    </tr>
                                    <TableRow label="Nama Proyek" value={value.nama_projek ? value.nama_projek : '-'} />
                                    <TableRow label="Tanggal Proyek" value={value.tanggal_projek ? value.tanggal_projek : '-'} />
                                    <TableRow label="Lama Pengerjaan" value={value.pengerjaan ? value.pengerjaan : '-'} />
                                    <TableRow label="Pemberi Proyek" value={value.pemberi_projek ? value.pemberi_projek : (value.penyelenggara ? value.penyelenggara : '-')} />
                                    <TableRow label="Link Portfolio" value={value.link_portfolio ? value.link_portfolio : '-'} />
                                    <tr>
                                        <td className='font-weight-bold' style={{ fontSize: '18px', width: "250px" }}>Portfolio</td>
                                        <td>
                                            {Object.entries(value.portfolio).map(([keyImg, valueImg]) => (
                                                <>
                                                    {typeof valueImg === "string" && (valueImg.toLowerCase().endsWith(".pdf") || valueImg.toLowerCase().endsWith(".zip") || valueImg.toLowerCase().endsWith(".rar")) ? (
                                                        <>
                                                            <button className="ml-2 btn btn-primary" onClick={() => window.open(`https://be-sidata.smkwikrama.sch.id/get-file-s3/${valueImg}`, "_blank")}>
                                                                Open File {Number(++keyImg)}
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <div data-toggle="modal" data-target={`#exampleModalLong-${key}`}>
                                                            <img
                                                                src={`https://be-sidata.smkwikrama.sch.id/get-file-s3/${valueImg}`}
                                                                alt={`Document ${key}`}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    width: '150px',
                                                                    height: '150px',
                                                                    objectFit: 'cover',
                                                                }}
                                                                onClick={() => openModal(key, valueImg)}
                                                            />
                                                        </div>
                                                    )}
                                                    <div class="modal fade" id={`exampleModalLong-${key}`} tabindex="-1" role="dialog" aria-labelledby={`show-image-${key}`} aria-hidden="true">
                                                        <div class="modal-dialog" role="document">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id={`show-image-${key}`}>{key === "documentIjazahSmp" ? "Ijazah SMP" : key === "documentAkte" ? "Akte Kelahiran" : key === "documentIjazahSd" ? "Ijazah SD" : key === "documentKk" ? "Kartu Keluarga" : key === "documentKtpAyah" ? "Ktp Ayah" : key === "documentKtpIbu" ? "Ktp Ibu" : "Dokumen"}</h5>
                                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <img
                                                                        src={`https://be-sidata.smkwikrama.sch.id/get-file-s3/${valueImg}`}
                                                                        alt={`Document ${key}`} width="100%"
                                                                    />
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                                    <button type="button" class="btn btn-primary">Save changes</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </td>
                                    </tr>
                                </>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className="text-center">Data dokumen kosong</td>
                            </tr>
                        )}
                </tbody>

            </table>
        </div>
    );
};

const TableRow = ({ label, value }) => (
    <tr>
        <th style={{ fontSize: '18px', width: "250px" }}>{label}</th>
        <th style={{ fontSize: '18px', fontWeight: "500" }}> {value}</th>
    </tr>
);

export default Portfolio;
