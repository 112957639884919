import React, { useContext, useRef, useState } from "react";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const LoginScreen = () => {
  document.title = 'Login | Sidata';
  const email = useRef('');
  const password = useRef('');
  const { loginApiCall } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const loggedInUser = await loginApiCall({
        email: email.current.value,
        password: password.current.value,
      });
      if (loggedInUser && loggedInUser.role === "student") {
        navigate("/dashboard/petunjuk");
      } else if (loggedInUser && loggedInUser.role === "guru") {
        navigate("/dashboard/pembimbing");
      } else {
        navigate("/admin");
      }

      Swal.fire({
        icon: 'success',
        title: 'Login Successful',
        text: 'Welcome back!',
      });
    } catch (error) {
      console.error("Error during login:", error.response.data.message);
      setError(error.response.data.message)
      setIsLoading(false);
    }
  };

  const styles = {

    backgroundRadialGradient: {


      backgroundImage: `radial-gradient(870px circle at 0% 0%,
        hsl(218, 41%, 35%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%),
        radial-gradient(1250px circle at 100% 100%,
        hsl(218, 41%, 45%) 15%,
        hsl(218, 41%, 30%) 35%,
        hsl(218, 41%, 20%) 75%,
        hsl(218, 41%, 19%) 80%,
        transparent 100%)`,
    },

    loginContainer: {
      backgroundColor: 'white',
      borderRadius: '10px',
      padding: '50px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
  };

  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setPasswordType(showPassword ? "password" : "text");
  };

  return (
    <div style={{ height: "100vh", ...styles.backgroundRadialGradient }}>
      <div className="login-page  w-full" >
        <div className="container pt-5 px-md-5 mb-0 text-center text-lg-start ">
          <div className="row gx-lg-5 mt-5 pt-5 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
              <h1 className="my-5 display-5 fw-bold ls-tight" style={{ color: 'hsl(218, 81%, 95%)' }}>
                Selamat Datang di <br />
                <span style={{ color: 'hsl(218, 81%, 75%)' }}>dashboard SiData</span>
              </h1>
              <p className="mb-4 opacity-70" style={{ color: 'hsl(218, 81%, 85%)' }}>
                Sistem Manajemen Data Terpercaya SMK Wikrama Bogor
              </p>
            </div>

            {/* Login form */}
            <div className="col-md-6 mb-5 col-lg-5" >
              <div className="px-5" style={styles.loginContainer}>
                <div className="login-title text-center" style={{ marginBottom: '20px' }}>
                  <span className="fw-bold" style={{ color: 'hsl(218, 81%, 35%)', fontSize: '25px', display: 'center' }}>Sign In</span>
                </div>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <form onSubmit={handleLogin}>
                  <div className="input-group custom">
                    <input
                      required
                      type="email"
                      ref={email}
                      className="form-control form-control-md input-sm"
                      placeholder="Email Address"
                    />
                    <div className="input-group-append custom">
                      <span className="input-group-text"><i className="icon-copy dw dw-user1"></i></span>
                    </div>
                  </div>
                  <div className="input-group custom">
                    <input
                      required
                      type={passwordType}
                      placeholder="Enter Password"
                      id="password"
                      ref={password}
                      className="form-control form-control-dm"
                    />
                    <div className="input-group-append custom">
                      <span
                        className="input-group-text"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        <i className={`dw ${showPassword ? "dw-eye" : "dw-hide"}`}></i>
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="input-group mb-0">
                        <button
                          type="submit"
                          className={`btn btn-primary bg-blue btn-md btn-block ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                          disabled={isLoading}
                        >
                          {isLoading ? 'Logging in...' : 'Masuk'}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
