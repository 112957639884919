import React from 'react'

export const TeamPage = () => {
  document.title = 'PPLG XII-2 | Sidata';

  return (
    <div>
      <img src='/images/team.png' alt='Team' />
    </div>
  )
}
