import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { BsPencil, BsTrashFill } from 'react-icons/bs'
import { confirmDelete } from '../../../../function/delete'
import { Information } from '../../../../components/globalcomp/Information'

export const ShowGaji = () => {
    document.title = "Data Gaji";

    const { gtk, getGtk } = useContext(GtkContext)
    const [getGaji, setGaji] = useState([])
    useEffect(() => {
        setGaji(gtk?.gtk?.gaji_id)
    }, [gtk.gtk])
    useEffect(() => {
        getGtk()
    }, [])



    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Data Gaji" />

                <Information title="gaji" />
                <div className='card-box shadow-sm p-4 mb-30'>
                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/gaji/create">
                            <button type="button" className="btn btn-primary bg-blue mb-2 text-white">Tambah Data Gaji</button>
                        </Link>
                    </div>
                    <div className="flex w-full overflow-x-auto mt-10">
                        <table className="table table-borderless">
                            <thead className='thead-dark'>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Masa Kerja (Tahun)</th>
                                    <th scope="col">Masa Kerja (Bulan)</th>
                                    <th scope="col">Gaji Pokok</th>
                                    <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getGaji?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.masa_kerja_thn}</td>
                                        <td>{item.masa_kerja_bln}</td>
                                        <td>{item.gaji_pokok}</td>
                                        <td className='d-flex '>
                                            <Link to={`/dashboard/pembimbing/gaji/edit/${item._id}`}>
                                                <button className='btn btn-sm btn-warning text-white'>
                                                    <BsPencil /> Ubah
                                                </button>
                                            </Link>

                                            <button
                                                className='btn btn-danger ml-3 btn-sm'
                                                onClick={() => confirmDelete(item._id, 'gaji/delete', getGtk)}
                                            >
                                                <BsTrashFill /> Hapus
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </LayoutDashboard>
        </div>
    )
}
