import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children, accessBy, allowedRoles }) => {
  const { user } = useContext(AuthContext);

  if (accessBy === "non-authenticated" && !user) {
    return children;
  } else if (accessBy === "authenticated" && user) {
    if (!allowedRoles || allowedRoles.includes(user.role)) {
      return children;
    } else {
      return <Navigate to="/not-found" />;
    }
  } else {
    return <Navigate to="/" />;
  }
};

