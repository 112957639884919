import React, { useContext, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import { useNavigate } from 'react-router-dom';
import GtkContext from '../../../../context/GtkContext';
import Swal from 'sweetalert2';
import axios from 'axios';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';


const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const customTooltipKeterangan = (
    <Tooltip id="tooltip">
        Keterangan terkait beasiswa yang pernah diterima PTK
    </Tooltip>
);

const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
};

export const CreateInpassing = () => {
    const { gtk } = useContext(GtkContext);
    const navigate = useNavigate();
    const gtkId = gtk?.gtk?._id;

    const [formData, setFormData] = useState({
        'no_sk': '',
        'tanggal_sk': '',
        'tmt_sk': '',
        'angka_kridit': '',
        'masa_kerja_thn': '',
        'masa_kerja_bln': '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value.trim() }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : `wajib diisi` }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            if (gtk && gtkId) {
                try {
                    await axios.post(`${apiEndpoint}/inpassing/create/${gtkId}`, { ...formData });
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Berhasil Create Data',
                        icon: 'success',
                    }).then(() => navigate('/dashboard/pembimbing/inpassing/show'));
                } catch (error) {
                    console.log(error.response?.data?.error || 'Error');
                }
            } else {
                console.log('Tidak ada ID.');
            }
        }
    }
    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Tambah Data Inpassing" tiga="Lainnya" empat="Inpassing" urlEmpat="/dashboard/pembimbing/inpassing/show" urlSatu="/dashboard/pembimbing" />
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='no_sk' className='form-label'>No. SK :</label>
                                    <input type='number'
                                        className={`form-control ${errors.no_sk ? 'is-invalid' : ''}`}
                                        value={formData.no_sk}
                                        onChange={(e) => handleChange(e)}
                                        name='no_sk' />
                                    {errors.no_sk && <p className='text-danger'>{errors.no_sk}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tanggal_sk' className='form-label'>Tanggal SK :</label>
                                <input type='date'
                                    className={`form-control ${errors.tanggal_sk ? 'is-invalid' : ''}`}
                                    value={formData.tanggal_sk}
                                    onChange={(e) => handleChange(e)}
                                    name='tanggal_sk'
                                />
                                {errors.tanggal_sk && <p className='text-danger'>{errors.tanggal_sk}</p>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tmt_sk' className='form-label'>TMT SK :</label>
                                    <input type='date'
                                        className={`form-control ${errors.tmt_sk ? 'is-invalid' : ''}`}
                                        value={formData.tmt_sk}
                                        onChange={(e) => handleChange(e)}
                                        name='tmt_sk'
                                    />
                                    {errors.tmt_sk && <p className='text-danger'>{errors.tmt_sk}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='angka_kridit' className='form-label'>Angka Kridit :</label>
                                <input type='text'
                                    className={`form-control ${errors.angka_kridit ? 'is-invalid' : ''}`}
                                    value={formData.angka_kridit}
                                    onChange={(e) => handleChange(e)}
                                    name='angka_kridit'
                                />
                                {errors.angka_kridit && <p className='text-danger'>{errors.angka_kridit}</p>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='masa_kerja_thn' className='form-label'>Masa Kerja (Tahun) :</label>
                                    <input type='number'
                                        className={`form-control ${errors.masa_kerja_thn ? 'is-invalid' : ''}`}
                                        value={formData.masa_kerja_thn}
                                        onChange={(e) => handleChange(e)}
                                        name='masa_kerja_thn' />
                                    {errors.masa_kerja_thn && <p className='text-danger'>{errors.masa_kerja_thn}</p>}

                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <label htmlFor='masa_kerja_bln' className='form-label'>Masa Kerja (Bulan) :</label>
                                <input type='number'
                                    className={`form-control ${errors.masa_kerja_bln ? 'is-invalid' : ''}`}
                                    value={formData.masa_kerja_bln}
                                    onChange={(e) => handleChange(e)}
                                    name='masa_kerja_bln' />
                                {errors.masa_kerja_bln && <p className='text-danger'>{errors.masa_kerja_bln}</p>}

                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </LayoutDashboard>
        </div>
    )
}
