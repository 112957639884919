import React, { useContext, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import GtkContext from '../../../../context/GtkContext'
import Select from 'react-select'

const initialValues = {
  "status_kepegawaian": "",
  "jenis_ptk": "",
  "nuptk": "",
  "sumber_gaji": "",
}

export const CreateKepegawaian = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const { statusKepeg, jenisPtk, gtk } = useContext(GtkContext);
  const gtkId = gtk?.gtk?._id;
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + `/kepegawaian/create/${gtkId}`;
  const validationSchema = Yup.object({
    status_kepegawaian: Yup.string().required('Required'),
    jenis_ptk: Yup.string().required('Required'),
    nuptk: Yup.string().required('Required'),
    sumber_gaji: Yup.string().required('Required'),
  })
  const optionStatusKepeg = statusKepeg.map(item => ({
    value: item.jenis_status,
    label: item.jenis_status
  }));
  const optionJenisPtk = jenisPtk.map(item => ({
    value: item.jenis_ptk,
    label: item.jenis_ptk
  }));


  const lembaga = [
    {
      'name': 'Pemerintah Pusat',
    },
    {
      'name': 'Pemerintah Provinsi',
    },
    {
      'name': 'Pemerintah Kab/Kota',
    },
    {
      'name': 'Ketua Yayasan',
    },
    {
      'name': 'Kepala Sekolah',
    },
    {
      'name': 'Komite Sekolah',
    },
    {
      'name': 'Lainnya',
    }
  ]

  const otptionLembaga = lembaga.map(item => ({
    value: item.name,
    label: item.name
  }));

  const sumberGaji = [
    {
      'name': 'APBN '
    },
    {
      'name': 'APBD Kab/Kota'
    },
    {
      'name': 'Sekolah'
    },
    {
      'name': 'APBD Provinsi'
    },
    {
      'name': 'Yayasan'
    },
    {
      'name': 'Lembaga Donor'
    },
    {
      'name': 'Lainnya'
    },
  ]
  const optionSumberGaji = sumberGaji.map(item => ({
    value: item.name,
    label: item.name
  }));
  
  const onSubmit = (values) => {
    Swal.fire({
      title: 'Apakah data sudah benar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya, kirim!',
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((confirmed) => {
      if (confirmed.isConfirmed) {
        Swal.fire({
          title: 'Mohon tunggu...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        axios.post(apiEndpoint, values)
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Data berhasil disimpan!',
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              navigate('/dashboard/pembimbing/kepegawaian/show');
            }, 2000);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.error,
            });
          });
      }
    });
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });

  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Dashboard" dua="Data Kepegawaian" tiga="Tambah Data" />

        <div className='card-box shadow-sm p-4 mb-30'>
          {errorMessage && <span className='text-danger'>{errorMessage}</span>}
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='status_kepegawaian' className='form-label'>Status Kepegawaian :</label>
                  <Select
                    name="status_kepegawaian"
                    id='status_kepegawaian'
                    className={` ${formik.touched.status_kepegawaian && formik.errors.status_kepegawaian ? "is-invalid" : ""}`}
                    options={optionStatusKepeg}
                    onChange={(selectedOption) => formik.setFieldValue("status_kepegawaian", selectedOption.value)}
                    onBlur={() => formik.setFieldTouched("status_kepegawaian", true)}
                    value={optionStatusKepeg.find(option => option.value === formik.values.status_kepegawaian)}
                  />
                  {formik.touched.status_kepegawaian && formik.errors.status_kepegawaian ? <span className='text-danger'>{formik.errors.status_kepegawaian}</span> : null}

                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor='niy_nigk' className='form-label'>NIY / NIGK :</label>
                <input type='text'
                  className={`form-control ${formik.touched.niy_nigk && formik.errors.niy_nigk ? "is-invalid" : ""}`}
                  name='niy_nigk'
                  id='niy_nigk'
                  {...formik.getFieldProps("niy_nigk", {
                    value: String(formik.values.niy_nigk)
                  })}
                />
                {formik.touched.niy_nigk && formik.errors.niy_nigk ? <span className='text-danger'>{formik.errors.niy_nigk}</span> : null}
              </div>
            
              <div className="col-md-6">
                <label htmlFor='nuptk' className='form-label'>NUPTK :</label>
                <input type='text'
                  className={`form-control ${formik.touched.nuptk && formik.errors.nuptk ? "is-invalid" : ""}`}
                  name='nuptk'
                  id='nuptk'
                  {...formik.getFieldProps("nuptk", {
                    value: String(formik.values.nuptk)
                  })}
                />
                {formik.touched.nuptk && formik.errors.nuptk ? <span className='text-danger'>{formik.errors.nuptk}</span> : null}
              </div>



              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='status_kepegawaian' className='form-label'>Jenis PTK :</label>
                  <Select
                    name="jenis_ptk"
                    id='jenis_ptk'
                    className={`l ${formik.touched.jenis_ptk && formik.errors.jenis_ptk ? "is-invalid" : ""}`}
                    options={optionJenisPtk}
                    onChange={(selectedOption) => formik.setFieldValue("jenis_ptk", selectedOption.value)}
                    onBlur={() => formik.setFieldTouched("jenis_ptk", true)}
                    value={optionJenisPtk.find(option => option.value === formik.values.jenis_ptk)}
                  />
                  {formik.touched.jenis_ptk && formik.errors.jenis_ptk ? <span className='text-danger'>{formik.errors.jenis_ptk}</span> : null}
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor='sk_pengangkatan' className='form-label'>SK Pengangkatan :</label>
                <input type='text'
                  className={`form-control ${formik.touched.sk_pengangkatan && formik.errors.sk_pengangkatan ? "is-invalid" : ""}`}
                  name='sk_pengangkatan'
                  id='sk_pengangkatan'
                  {...formik.getFieldProps("sk_pengangkatan", {
                    value: String(formik.values.sk_pengangkatan)
                  })}
                />
                {formik.touched.sk_pengangkatan && formik.errors.sk_pengangkatan ? <span className='text-danger'>{formik.errors.sk_pengangkatan}</span> : null}
              </div>


              <div className="col-md-6">
                <label htmlFor='tmt_pengangkatan' className='form-label'>TMT Pengangkatan :</label>
                <input type='date'
                  className={`form-control ${formik.touched.tmt_pengangkatan && formik.errors.tmt_pengangkatan ? "is-invalid" : ""}`}
                  name='tmt_pengangkatan'
                  id='tmt_pengangkatan'
                  {...formik.getFieldProps("tmt_pengangkatan", {
                    value: String(formik.values.tmt_pengangkatan)
                  })}
                />
                {formik.touched.tmt_pengangkatan && formik.errors.tmt_pengangkatan ? <span className='text-danger'>{formik.errors.tmt_pengangkatan}</span> : null}
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='status_kepegawaian' className='form-label'>Lembaga Pengangkat :</label>
                  <Select
                    name="lembaga_pengangkat"
                    id='lembaga_pengangkat'
                    className={`l ${formik.touched.lembaga_pengangkat && formik.errors.lembaga_pengangkat ? "is-invalid" : ""}`}
                    options={otptionLembaga}
                    onChange={(selectedOption) => formik.setFieldValue("lembaga_pengangkat", selectedOption.value)}
                    onBlur={() => formik.setFieldTouched("lembaga_pengangkat", true)}
                    value={otptionLembaga.find(option => option.value === formik.values.lembaga_pengangkat)}
                  />
                  {formik.touched.lembaga_pengangkat && formik.errors.lembaga_pengangkat ? <span className='text-danger'>{formik.errors.lembaga_pengangkat}</span> : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='status_kepegawaian' className='form-label'>Sumber Gaji</label>
                  <Select
                    name="sumber_gaji"
                    id='sumber_gaji'
                    className={`l ${formik.touched.sumber_gaji && formik.errors.sumber_gaji ? "is-invalid" : ""}`}
                    options={optionSumberGaji}
                    onChange={(selectedOption) => formik.setFieldValue("sumber_gaji", selectedOption.value)}
                    onBlur={() => formik.setFieldTouched("sumber_gaji", true)}
                    value={optionSumberGaji.find(option => option.value === formik.values.sumber_gaji)}
                  />
                  {formik.touched.sumber_gaji && formik.errors.sumber_gaji ? <span className='text-danger'>{formik.errors.sumber_gaji}</span> : null}
                </div>
              </div>
            </div>

            <button type='submit' className='btn btn-primary bg-blue text-white  mt-3'>Buat Data Kepegawaian</button>

          </form>
        </div>
      </LayoutDashboard>
    </div>
  )
}
