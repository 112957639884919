import React, { useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditSertifikasi = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        'jenis_sertifikasi': "",
        "no_sertifikasi": "",
        "thn_sertifikasi": "",
        "bidang_studi": "",
        "no_reg": "",
        "no_peserta": "",
    })
    const [errors, setErrors] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiEndpoint}/sertifikasi/${id}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    },
                });
                setFormData(response.data.sertifikasi);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/sertifikasi/update/${id}`, formData);
                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/dashboard/pembimbing/sertifikasi/show'));
            } catch (error) {
                console.log(error, "error");
            }
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Edit Data Sertifikasi" tiga="Lainnya" empat="Sertifikasi" urlEmpat="/dashboard/pembimbing/sertifikasi/show" urlSatu="/dashboard/pembimbing" />

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>


                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='jenis_sertifikasi' className='form-label'>Jenis Sertifikasi :</label>
                                    <input type='text' name='jenis_sertifikasi'
                                        className={`form-control ${errors.jenis_sertifikasi ? 'is-invalid' : ''}`}
                                        value={formData.jenis_sertifikasi}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.jenis_sertifikasi && <p style={{ color: "red" }}>{errors.jenis_sertifikasi}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='no_sertifikasi' className='form-label'>No. Sertifikasi :</label>
                                <input type='number' name='no_sertifikasi'
                                    className={`form-control ${errors.no_sertifikasi ? 'is-invalid' : ''}`}
                                    value={formData.no_sertifikasi}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.no_sertifikasi && <p style={{ color: "red" }}>{errors.no_sertifikasi}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='thn_sertifikasi' className='form-label'>Tahun Sertifikasi  :</label>
                                    <input type='number' name='thn_sertifikasi'
                                        className={`form-control ${errors.thn_sertifikasi ? 'is-invalid' : ''}`}
                                        value={formData.thn_sertifikasi}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.thn_sertifikasi && <p style={{ color: "red" }}>{errors.thn_sertifikasi}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='bidang_studi' className='form-label'>Bidang Studi  :</label>
                                    <input type='text' name='bidang_studi'
                                        className={`form-control ${errors.bidang_studi ? 'is-invalid' : ''}`}
                                        value={formData.bidang_studi}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.bidang_studi && <p style={{ color: "red" }}>{errors.bidang_studi}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='no_reg' className='form-label'>No. Registrasi :</label>
                                <input type='number' name='no_reg'
                                    className={`form-control ${errors.no_reg ? 'is-invalid' : ''}`}
                                    value={formData.no_reg}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.no_reg && <p style={{ color: "red" }}>{errors.no_reg}</p>}
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='no_peserta' className='form-label'>No. Peserta :</label>
                                    <input type='number' name='no_peserta'
                                        className={`form-control ${errors.no_peserta ? 'is-invalid' : ''}`}
                                        value={formData.no_peserta}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.no_peserta && <p style={{ color: "red" }}>{errors.no_peserta}</p>}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>


            </LayoutDashboard>
        </div >
    )
}
