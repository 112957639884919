import React, { useEffect, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const LengkapiData = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nama_lengkap: '',
    nik: '',
    jk: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    agama: '',
    no_telp: '',
    email: '',
    nip: '',
    nama_ibu: '',
    alamat: '',
    rt: '',
    rw: '',
    nama_kelurahan: '',
    kecamatan: '',
    kota_kab: '',
    provinsi: '',
    no_kk: '',
    kode_pos: '',
    npwp: '',
    nama_wajib_pajak: '',
    kewarganegaraan: '',
    status_perkawinan: '',
    nama_istri_suami: '',
    nip_istri_suami: '',
    pekerjaan_istri_suami: '',
    no_telp_rumah: '',
    bb: '',
    tb: '',
    gol_darah: '',
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiEndpoint}/gtk/${id}`, {
          headers: {
            'ngrok-skip-browser-warning': 'true',
          },
        });
        setFormData(response.data.gtk);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
  };

  const validateForm = () => {
    const formErrors = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
    );
    setErrors(formErrors);
    return !Object.values(formErrors).some((error) => error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await axios.put(`${apiEndpoint}/gtk/update/${id}`, formData);
      Swal.fire({
        title: 'Sukses!',
        text: 'Berhasil Update Data',
        icon: 'success',
      }).then(() => navigate('/dashboard/pembimbing/gtk/show'));
    } catch (error) {
      console.error('Error updating data:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Gagal Update Data',
        icon: 'error',
      });
    }
  };

  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Dashboard" dua="Lengkapi Data Gtk" tiga="Data Gtk" />
        <div className='card-box shadow-sm p-4 mb-30'>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='nama_lengkap' className='form-label'>Nama Lengkap :</label>
                  <input
                    type='text'
                    id='nama_lengkap'
                    name='nama_lengkap'
                    className={`form-control ${errors.nama_lengkap ? 'is-invalid' : ''}`}
                    value={formData.nama_lengkap}
                    onChange={handleChange}
                  />
                  {errors.nama_lengkap && <p style={{ color: 'red' }}>{errors.nama_lengkap}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor='nik' className='form-label'>NIK :</label>
                <input
                  type='text'
                  id='nik'
                  name='nik'
                  className={`form-control ${errors.nik ? 'is-invalid' : ''}`}
                  value={formData.nik}
                  onChange={handleChange}
                />
                {errors.nik && <p style={{ color: 'red' }}>{errors.nik}</p>}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label htmlFor='jk' className='form-label'>Jenis Kelamin :</label>
                <select
                  id='jk'
                  name='jk'
                  className={`form-control ${errors.jk ? 'is-invalid' : ''}`}
                  value={formData.jk}
                  onChange={handleChange}
                >
                  <option value=''>Pilih Jenis Kelamin</option>
                  <option value='L'>Laki-laki</option>
                  <option value='P'>Perempuan</option>
                </select>
                {errors.jk && <p style={{ color: 'red' }}>{errors.jk}</p>}
              </div>
              <div className="col-md-6">
                <label htmlFor='tempat_lahir' className='form-label'>Tempat Lahir :</label>
                <input
                  type='text'
                  id='tempat_lahir'
                  name='tempat_lahir'
                  className={`form-control ${errors.tempat_lahir ? 'is-invalid' : ''}`}
                  value={formData.tempat_lahir}
                  onChange={handleChange}
                />
                {errors.tempat_lahir && <p style={{ color: 'red' }}>{errors.tempat_lahir}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='tanggal_lahir' className='form-label'>Tanggal Lahir :</label>
                <input
                  type='date'
                  id='tanggal_lahir'
                  name='tanggal_lahir'
                  className={`form-control ${errors.tanggal_lahir ? 'is-invalid' : ''}`}
                  value={formData.tanggal_lahir}
                  onChange={handleChange}
                />
                {errors.tanggal_lahir && <p style={{ color: 'red' }}>{errors.tanggal_lahir}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='agama' className='form-label'>Agama :</label>
                <select
                  id='agama'
                  name='agama'
                  className={`form-control ${errors.agama ? 'is-invalid' : ''}`}
                  value={formData.agama}
                  onChange={handleChange}
                >
                  <option value="">Pilih Agama</option>
                  <option value="Islam">Islam</option>
                  <option value="Kristen Protestan">Kristen Protestan</option>
                  <option value="Katolik">Katolik</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Buddha">Buddha</option>
                  <option value="Konghucu">Konghucu</option>
                </select>
                {errors.agama && <p style={{ color: 'red' }}>{errors.agama}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='no_telp' className='form-label'>Nomor Telpon :</label>
                <input
                  type='number'
                  name='no_telp'
                  className={`form-control ${errors.no_telp ? 'is-invalid' : ''}`}
                  value={formData.no_telp}
                  onChange={handleChange}
                />
                {errors.no_telp && <p style={{ color: 'red' }}>{errors.no_telp}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='email' className='form-label'>Email :</label>
                <input
                  type='text'
                  name='email'
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='nip' className='form-label'>NIP :</label>
                <input
                  type='number'
                  name='nip'
                  className={`form-control ${errors.nip ? 'is-invalid' : ''}`}
                  value={formData.nip}
                  onChange={handleChange}
                />
                {errors.nip && <p style={{ color: 'red' }}>{errors.nip}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='nama_ibu' className='form-label'>Nama Ibu :</label>
                <input
                  type='text'
                  name='nama_ibu'
                  className={`form-control ${errors.nama_ibu ? 'is-invalid' : ''}`}
                  value={formData.nama_ibu}
                  onChange={handleChange}
                />
                {errors.nama_ibu && <p style={{ color: 'red' }}>{errors.nama_ibu}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='alamat' className='form-label'>Alamat :</label>
                <input
                  type='text'
                  name='alamat'
                  className={`form-control ${errors.alamat ? 'is-invalid' : ''}`}
                  value={formData.alamat}
                  onChange={handleChange}
                />
                {errors.alamat && <p style={{ color: 'red' }}>{errors.alamat}</p>}
              </div>
              <div className="col-md-3 mb-10">
                <label htmlFor='rt' className='form-label'>RT :</label>
                <input
                  type='text'
                  name='rt'
                  className={`form-control ${errors.rt ? 'is-invalid' : ''}`}
                  value={formData.rt}
                  onChange={handleChange}
                />
                {errors.rt && <p style={{ color: 'red' }}>{errors.rt}</p>}
              </div>
              <div className="col-md-3 mb-10">
                <label htmlFor='rw' className='form-label'>RW :</label>
                <input
                  type='text'
                  name='rw'
                  className={`form-control ${errors.rw ? 'is-invalid' : ''}`}
                  value={formData.rw}
                  onChange={handleChange}
                />
                {errors.rw && <p style={{ color: 'red' }}>{errors.rw}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='nama_kelurahan' className='form-label'>Nama Kelurahan :</label>
                <input
                  type='text'
                  name='nama_kelurahan'
                  className={`form-control ${errors.nama_kelurahan ? 'is-invalid' : ''}`}
                  value={formData.nama_kelurahan}
                  onChange={handleChange}
                />
                {errors.nama_kelurahan && <p style={{ color: 'red' }}>{errors.nama_kelurahan}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='kecamatan' className='form-label'>Kecamatan :</label>
                <input
                  type='text'
                  name='kecamatan'
                  className={`form-control ${errors.kecamatan ? 'is-invalid' : ''}`}
                  value={formData.kecamatan}
                  onChange={handleChange}
                />
                {errors.kecamatan && <p style={{ color: 'red' }}>{errors.kecamatan}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='kota_kab' className='form-label'>Kota/Kabupaten :</label>
                <input
                  type='text'
                  name='kota_kab'
                  className={`form-control ${errors.kota_kab ? 'is-invalid' : ''}`}
                  value={formData.kota_kab}
                  onChange={handleChange}
                />
                {errors.kota_kab && <p style={{ color: 'red' }}>{errors.kota_kab}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='provinsi' className='form-label'>Provinsi :</label>
                <input
                  type='text'
                  name='provinsi'
                  className={`form-control ${errors.provinsi ? 'is-invalid' : ''}`}
                  value={formData.provinsi}
                  onChange={handleChange}
                />
                {errors.provinsi && <p style={{ color: 'red' }}>{errors.provinsi}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='no_kk' className='form-label'>No KK :</label>
                <input
                  type='number'
                  name='no_kk'
                  className={`form-control ${errors.no_kk ? 'is-invalid' : ''}`}
                  value={formData.no_kk}
                  onChange={handleChange}
                />
                {errors.no_kk && <p style={{ color: 'red' }}>{errors.no_kk}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='kode_pos' className='form-label'>Kode Pos :</label>
                <input
                  type='number'
                  name='kode_pos'
                  className={`form-control ${errors.kode_pos ? 'is-invalid' : ''}`}
                  value={formData.kode_pos}
                  onChange={handleChange}
                />
                {errors.kode_pos && <p style={{ color: 'red' }}>{errors.kode_pos}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='npwp' className='form-label'>NPWP :</label>
                <input
                  type='text'
                  name='npwp'
                  className={`form-control ${errors.npwp ? 'is-invalid' : ''}`}
                  value={formData.npwp}
                  onChange={handleChange}
                />
                {errors.npwp && <p style={{ color: 'red' }}>{errors.npwp}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='nama_wajib_pajak' className='form-label'>Nama Wajib Pajak :</label>
                <input
                  type='text'
                  name='nama_wajib_pajak'
                  className={`form-control ${errors.nama_wajib_pajak ? 'is-invalid' : ''}`}
                  value={formData.nama_wajib_pajak}
                  onChange={handleChange}
                />
                {errors.nama_wajib_pajak && <p style={{ color: 'red' }}>{errors.nama_wajib_pajak}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='kewarganegaraan' className='form-label'>Kewarganegaraan :</label>
                <input
                  type='text'
                  name='kewarganegaraan'
                  className={`form-control ${errors.kewarganegaraan ? 'is-invalid' : ''}`}
                  value={formData.kewarganegaraan}
                  onChange={handleChange}
                />
                {errors.kewarganegaraan && <p style={{ color: 'red' }}>{errors.kewarganegaraan}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='status_perkawinan' className='form-label'>Status Perkawinan :</label>
                <select
                  id='status_perkawinan'
                  name='status_perkawinan'
                  className={`form-control ${errors.status_perkawinan ? 'is-invalid' : ''}`}
                  value={formData.status_perkawinan}
                  onChange={handleChange}
                >
                  <option value="">Pilih Status Perkawinan</option>
                  <option value="Belum Menikah">Belum Menikah</option>
                  <option value="Menikah">Menikah</option>
                  <option value="Cerai Hidup">Cerai Hidup</option>
                  <option value="Cerai Mati">Cerai Mati</option>
                </select>
                {errors.status_perkawinan && <p style={{ color: 'red' }}>{errors.status_perkawinan}</p>}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6 mb-10">
                <label htmlFor='nama_istri_suami' className='form-label'>Nama Istri/Suami :</label>
                <input
                  type='text'
                  name='nama_istri_suami'
                  className={`form-control ${errors.nama_istri_suami ? 'is-invalid' : ''}`}
                  value={formData.nama_istri_suami}
                  onChange={handleChange}
                />
                {errors.nama_istri_suami && <p style={{ color: 'red' }}>{errors.nama_istri_suami}</p>}
              </div>
              <div className="col-md-6 mb-10">
                <label htmlFor='nip_istri_suami' className='form-label'>NIP Istri/Suami :</label>
                <input
                  type='text'
                  name='nip_istri_suami'
                  className={`form-control ${errors.nip_istri_suami ? 'is-invalid' : ''}`}
                  value={formData.nip_istri_suami}
                  onChange={handleChange}
                  />
                  {errors.nip_istri_suami && <p style={{ color: 'red' }}>{errors.nip_istri_suami}</p>}
                </div>
              </div>
  
              <div className="row mt-3">
                <div className="col-md-6 mb-10">
                  <label htmlFor='pekerjaan_istri_suami' className='form-label'>Pekerjaan Istri/Suami :</label>
                  <input
                    type='text'
                    name='pekerjaan_istri_suami'
                    className={`form-control ${errors.pekerjaan_istri_suami ? 'is-invalid' : ''}`}
                    value={formData.pekerjaan_istri_suami}
                    onChange={handleChange}
                  />
                  {errors.pekerjaan_istri_suami && <p style={{ color: 'red' }}>{errors.pekerjaan_istri_suami}</p>}
                </div>
                <div className="col-md-6 mb-10">
                  <label htmlFor='no_telp_rumah' className='form-label'>No Telp Rumah :</label>
                  <input
                    type='text'
                    name='no_telp_rumah'
                    className={`form-control ${errors.no_telp_rumah ? 'is-invalid' : ''}`}
                    value={formData.no_telp_rumah}
                    onChange={handleChange}
                  />
                  {errors.no_telp_rumah && <p style={{ color: 'red' }}>{errors.no_telp_rumah}</p>}
                </div>
              </div>
  
              <div className="row mt-3">
                <div className="col-md-6 mb-10">
                  <label htmlFor='bb' className='form-label'>Berat Badan :</label>
                  <input
                    type='text'
                    name='bb'
                    className={`form-control ${errors.bb ? 'is-invalid' : ''}`}
                    value={formData.bb}
                    onChange={handleChange}
                  />
                  {errors.bb && <p style={{ color: 'red' }}>{errors.bb}</p>}
                </div>
                <div className="col-md-6 mb-10">
                  <label htmlFor='tb' className='form-label'>Tinggi Badan :</label>
                  <input
                    type='text'
                    name='tb'
                    className={`form-control ${errors.tb ? 'is-invalid' : ''}`}
                    value={formData.tb}
                    onChange={handleChange}
                  />
                  {errors.tb && <p style={{ color: 'red' }}>{errors.tb}</p>}
                </div>
              </div>
  
              <div className="row mt-3">
                <div className="col-md-6 mb-10">
                  <label htmlFor='gol_darah' className='form-label'>Golongan Darah :</label>
                  <input
                    type='text'
                    name='gol_darah'
                    className={`form-control ${errors.gol_darah ? 'is-invalid' : ''}`}
                    value={formData.gol_darah}
                    onChange={handleChange}
                  />
                  {errors.gol_darah && <p style={{ color: 'red' }}>{errors.gol_darah}</p>}
                </div>
              </div>
  
              <div className="mt-4">
                <button type='submit' className='btn btn-success waves-effect waves-light me-1'>Simpan</button>
                <button type='button' onClick={() => navigate('/dashboard/pembimbing/data-diri/show')} className='btn btn-danger waves-effect waves-light me-1'>Batal</button>
              </div>
            </form>
          </div>
        </LayoutDashboard>
      </div>
    );
  };

  