import React, { useState } from 'react';
import GelBreadcrumb from '../../../components/globalcomp/GelBreadcrumb';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import LayoutDashboard from '../../../layouts/LayoutDashboard';
import { useContext } from 'react';
import { RombelContext } from '../../../context/RombelContext';
import { RayonContext } from '../../../context/RayonContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select'
import { AllStudentContext } from '../../../context/AllStudentContext';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/student/create"
const initialValues = {
    'email': '',
    'nama': '',
    'rombel': '',
    'rayon': '',
    'nis': '',
    'jk': '',
}
export const AddNewStudentPage = () => {
    const navigate = useNavigate();
    const { allStudent, getAllStudent } = useContext(AllStudentContext);


    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = (values) => {
        Swal.fire({
            title: 'Apakah data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya, kirim!',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then((confirmed) => {
            if (confirmed.isConfirmed) {
                Swal.fire({
                    title: 'Mohon tunggu...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                axios.post(apiEndpoint, values)
                    .then((response) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Data berhasil disimpan!',
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        setTimeout(() => {
                            getAllStudent()
                            navigate('/admin/dashboard/student');
                        }, 2000);
                    })
                    .catch((error) => {
                        setErrorMessage(error.response.data.error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.error,
                        });
                    });
            }
        });
    };

    const { rombels } = useContext(RombelContext);
    const { rayons } = useContext(RayonContext)

    const validationSchema = Yup.object({
        rayon: Yup.string().required('Required'),
        rombel: Yup.string().required('Required'),
        nama: Yup.string().required('Required'),
        email: Yup.string().email('Invalid Email Format').required('Required'),
        jk: Yup.string().required('Required'),
        nis: Yup.number().required('Required'),
    })
    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });


    const optionRombels = rombels.map(rombel => ({
        value: rombel.nama_rombel,
        label: rombel.nama_rombel
    }));

    const optionRayons = rayons.map(rombel => ({
        value: rombel.nama_rayon,
        label: rombel.nama_rayon
    }));

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Data Siswa" dua="Buat Akun" urlSatu="/admin/dashboard/student" />
                <div className='card-box shadow-sm p-4'>
                    <div>
                        {errorMessage && <span className='text-danger'>{errorMessage}</span>}
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='row g-3'>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Email</label>
                                    <input type='email'
                                        className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
                                        name='email'
                                        id='email'
                                        {...formik.getFieldProps("email")}
                                    />
                                    {formik.touched.email && formik.errors.email ? <span className='text-danger'>{formik.errors.email}</span> : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Nama Lengkap</label>
                                    <input type='text'
                                        className={`form-control ${formik.touched.nama && formik.errors.nama ? "is-invalid" : ""}`}
                                        name='nama'
                                        id='nama'
                                        {...formik.getFieldProps("nama")}
                                    />
                                    {formik.touched.nama && formik.errors.nama ? <span className='text-danger'>{formik.errors.nama_rayon}</span> : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">NIS</label>
                                    <input type='text'
                                        className={`form-control ${formik.touched.nis && formik.errors.nis ? "is-invalid" : ""}`}
                                        name='nis'
                                        id='nis'
                                        {...formik.getFieldProps("nis")}
                                    />
                                    {formik.touched.nis && formik.errors.nis ? <span className='text-danger'>{formik.errors.nama_rayon}</span> : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Rombel</label>
                                    <Select
                                        name="rombel"
                                        id='rombel'
                                        className={`l ${formik.touched.rombel && formik.errors.rombel ? "is-invalid" : ""}`}
                                        options={optionRombels}
                                        onChange={(selectedOption) => formik.setFieldValue("rombel", selectedOption.value)}
                                        onBlur={() => formik.setFieldTouched("rombel", true)}
                                        value={optionRombels.find(option => option.value === formik.values.rombel)}
                                    />
                                    {formik.touched.rombel && formik.errors.rombel ? <div className='invalid-feedback'>{formik.errors.rombel}</div> : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">Rayon</label>

                                    <Select
                                        name="rayon"
                                        id='rayon'
                                        className={`l ${formik.touched.rayon && formik.errors.rayon ? "is-invalid" : ""}`}
                                        options={optionRayons}
                                        onChange={(selectedOption) => formik.setFieldValue("rayon", selectedOption.value)}
                                        onBlur={() => formik.setFieldTouched("rombel", true)}
                                        value={optionRayons.find(option => option.value === formik.values.rayon)}
                                    />
                                    {formik.touched.rayon && formik.errors.rayon ? <span className='text-danger'>{formik.errors.rayon}</span> : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group">
                                    <label className="form-label">JK</label>
                                    <select
                                        name="jk"
                                        id='jk'
                                        className={`form-control ${formik.touched.jk && formik.errors.jk ? "is-invalid" : ""}`}
                                        {...formik.getFieldProps("jk")}
                                    >
                                        <option value="">Pilih JK</option>
                                        <option value="P">P</option>
                                        <option value="L">L</option>
                                    </select>
                                    {formik.touched.jk && formik.errors.jk ? <span className='text-danger'>{formik.errors.jk}</span> : null}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div></div>
                                <button type="submit" className="btn btn-primary">Kirim Data</button>
                            </div>
                        </div>

                    </form>
                </div>

            </LayoutDashboard >


        </div >
    );
}
