import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import axios from 'axios'
import Swal from 'sweetalert2'
import { BsPencil, BsTrash, BsTrashFill } from 'react-icons/bs'
import { Information } from '../../../../components/globalcomp/Information'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { confirmDelete } from '../../../../function/delete'

export const ShowDiklat = () => {
    document.title = "Data Diklat";

    const { gtk, getGtk } = useContext(GtkContext)
    const [getDiklat, setDiklat] = useState([])
    useEffect(() => {
        setDiklat(gtk?.gtk?.diklat_id)
    }, [gtk.gtk])

    useEffect(() => {
        getGtk()
    }, [])


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Data Diklat" />
                <Information title="Diklat" />
                <div className='card-box shadow-sm p-4 mb-30'>
                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/diklat/create">
                            <button type="button" className="btn btn-primary bg-blue text-white mb-2">Masukan Data</button>
                        </Link>

                    </div>

                    <div className="flex w-full overflow-x-auto mt-10">
                        <table className="table table-borderless">
                            <thead className='thead-dark'>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">jenis Diklat</th>
                                    <th scope="col">Nama Diklat</th>
                                    <th scope="col">Penyelenggara</th>
                                    <th scope="col">Tahun Diklat</th>
                                    <th scope="col">Peran</th>
                                    <th scope="col">Tingkat Diklat</th>
                                    <th scope="col">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getDiklat && getDiklat.length > 0 ? (
                                    getDiklat.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.jenis_diklat}</td>
                                            <td>{item.nama_diklat}</td>
                                            <td>{item.penyelenggara}</td>
                                            <td>{item.tahun_diklat}</td>
                                            <td>{item.peran}</td>
                                            <td>{item.tingkat_diklat}</td>
                                            <td>
                                                <Link to={`/dashboard/pembimbing/diklat/edit/${item._id}`}>
                                                    <button className='btn btn-sm btn-warning text-white'>
                                                        <BsPencil /> Ubah
                                                    </button>
                                                </Link>
                                                <button
                                                className='btn btn-danger ml-3 btn-sm'
                                                onClick={() => confirmDelete(item._id, 'diklat/delete', getGtk)}
                                            >
                                                <BsTrashFill /> Hapus
                                            </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <Kosong />
                                )}
                            </tbody>

                        </table>


                    </div>
                </div>



            </LayoutDashboard>
        </div>
    )
}
