import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const customTooltipNomor = (
    <Tooltip id="tooltip">
        Nomor SK penugasan PTK
    </Tooltip>
);

const customTooltipTanggal = (
    <Tooltip id="tooltip">
        Tanggal surat yang digunakan sebagai dasar pengisian nomor surat tugas
    </Tooltip>
);

const customTooltipTMT = (
    <Tooltip id="tooltip">
        Tanggal mulai berlakunya PTK bertugas di sekolah
    </Tooltip>
);

const customTooltipStatus = (
    <Tooltip id="tooltip">
        Penugasan PTK di sekolah ini sebagai induk atau bukan
    </Tooltip>
);

const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
};

export const CreatePenugasan = () => {
    const { gtk } = useContext(GtkContext);
    const navigate = useNavigate();
    const gtkId = gtk?.gtk?._id;

    const [formData, setFormData] = useState({
        'no_surat_tugas': "",
        "tanggal_surat_tugas": "",
        "tmt_tugas": "",
        "status_sekolah_induk": "",
        "keluar_karena": "",
        "tanggal_keluar": "",
    })

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value.trim() }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : `wajib diisi` }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }
        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin ingin membuat data baru?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            if (gtk && gtkId) {
                try {
                    await axios.post(`${apiEndpoint}/penugasan/create/${gtkId}`, { ...formData });
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Berhasil Create Data',
                        icon: 'success',
                    }).then(() => navigate('/dashboard/pembimbing/penugasan/show'));
                } catch (error) {
                    console.log(error.response?.data?.error || 'Error');
                }
            } else {
                console.log('Tidak ada ID.');
            }
        }
    }


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Tambah Data Penugasan" tiga="Lainnya" empat="Inpassing" urlEmpat="/dashboard/pembimbing/penugasan/show" urlSatu="/dashboard/pembimbing" />


                {/* create-penugasan */}

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>


                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='no_surat_tugas' className='form-label'>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={customTooltipNomor}
                                        >
                                            <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                        </OverlayTrigger>
                                        No. Surat Tugas :</label>
                                    <input type='number' name='no_surat_tugas'
                                        className={`form-control ${errors.no_surat_tugas ? 'is-invalid' : ''}`}
                                        value={formData.no_surat_tugas}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.no_surat_tugas && <p style={{ color: "red" }}>{errors.no_surat_tugas}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tanggal_surat_tugas' className='form-label'>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={customTooltipTanggal}
                                    >
                                        <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                    </OverlayTrigger>
                                    Tanggal Surat Tugas :</label>
                                <input type='date' name='tanggal_surat_tugas'
                                    className={`form-control ${errors.tanggal_surat_tugas ? 'is-invalid' : ''}`}
                                    value={formData.tanggal_surat_tugas}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.tanggal_surat_tugas && <p style={{ color: "red" }}>{errors.tanggal_surat_tugas}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tmt_tugas' className='form-label'>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={customTooltipTMT}
                                        >
                                            <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                        </OverlayTrigger>
                                        TMT Tugas  :</label>
                                    <input type='date' name='tmt_tugas'
                                        className={`form-control ${errors.tmt_tugas ? 'is-invalid' : ''}`}
                                        value={formData.tmt_tugas}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.tmt_tugas && <p style={{ color: "red" }}>{errors.tmt_tugas}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='status_sekolah_induk' className='form-label'>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={customTooltipStatus}
                                    >
                                        <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                    </OverlayTrigger>
                                    Status Sekolah Induk :</label>
                                <select name='status_sekolah_induk' className={`form-control ${errors.status_sekolah_induk ? 'is-invalid' : ''}`}
                                    value={formData.status_sekolah_induk}
                                    onChange={(e) => handleChange(e)}>
                                    <option value=''>Pilih Opsi</option>
                                    <option value='true'>Ya</option>
                                    <option value='false'>Tidak</option>
                                </select>
                                {errors.status_sekolah_induk && <p style={{ color: "red" }}>{errors.status_sekolah_induk}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='keluar_karena' className='form-label'>Keluar Karena :</label>
                                    <input type='text' name='keluar_karena'
                                        className={`form-control ${errors.keluar_karena ? 'is-invalid' : ''}`}
                                        value={formData.keluar_karena}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.keluar_karena && <p style={{ color: "red" }}>{errors.keluar_karena}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tanggal_keluar' className='form-label'>Tanggal Keluar :</label>
                                    <input type='date' name='tanggal_keluar'
                                        className={`form-control ${errors.tanggal_keluar ? 'is-invalid' : ''}`}
                                        value={formData.tanggal_keluar}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.tanggal_keluar && <p style={{ color: "red" }}>{errors.tanggal_keluar}</p>}
                                </div>
                            </div>
                        </div>


                        <button type="submit" className="btn btn-primary">Submit</button>

                    </div>
                </form>



            </LayoutDashboard >
        </div >
    )
}
