import React, { useContext, useState } from 'react';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import RayonContext from '../../../../context/RayonContext';
import Select from 'react-select'
import AllGtkContext from '../../../../context/AllGtkContext';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/rayon";

const initialValues = {
  "nama_rayon": '',
  "pembimbing": '',
  "no_ruangan": '', 
}

export const CreateRayonPage = () => {
  const navigate = useNavigate()
  const {allGtk} = useContext(AllGtkContext);
  const [errorMessage, setErrorMessage] = useState('');
  const { getRayons } = useContext(RayonContext);

  const onSubmit = (values) => {
    Swal.fire({
      title: 'Apakah data sudah benar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya, kirim!',
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((confirmed) => {
      if (confirmed.isConfirmed) {
        Swal.fire({
          title: 'Mohon tunggu...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        axios.post(apiEndpoint, values)
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Data berhasil disimpan!',
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              getRayons()
              navigate('/admin/rayon/show');
            }, 2000);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.error,
            });
          });
      }
    });
  };

  const validationSchema = Yup.object({
    nama_rayon: Yup.string().required('Required'),
    pembimbing: Yup.string().required('Required'),
    no_ruangan: Yup.string().required('Required')
  
  })

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema
  });


  console.log(allGtk.user_id, "ini eh ")
  const optionPembimbing = allGtk?.map(item => ({
    value: item.user_id._id,
    label: item.user_id.username
  }));
  
  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Master Data" dua="Buat Rayon" tiga="Rayon" urlDua="/admin/rayon" urlSatu="/admin/rayon" />
        <div className='card-box p-4 shadow-sm mb-5 '>
          <div>
            {errorMessage && <span className='text-danger'>{errorMessage}</span>}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='row g-3'>
              <div className='col-md-6'>
                <label className='form-label' htmlFor='nama_rayon'>Nama Rayon</label>
                <input type='text'
                  className={`form-control ${formik.touched.nama_rayon && formik.errors.nama_rayon ? "is-invalid" : ""}`}
                  name='nama_rayon'
                  id='nama_rayon'
                  {...formik.getFieldProps("nama_rayon")}
                />
                {formik.touched.nama_rayon && formik.errors.nama_rayon ? <span className='text-danger'>{formik.errors.nama_rayon}</span> : null}
              </div>
              <div className='col-md-6'>
                <label >Pembimbing</label>
                <Select
                    name="pembimbing"
                    id='pembimbing'
                    className={` ${formik.touched.pembimbing && formik.errors.pembimbing ? "is-invalid" : ""}`}
                    options={optionPembimbing}
                    onChange={(selectedOption) => formik.setFieldValue("pembimbing", selectedOption.value)}
                    onBlur={() => formik.setFieldTouched("pembimbing", true)}
                    value={optionPembimbing?.find(option => option.value === formik.values.pembimbing)}
                  />
                  {formik.touched.pembimbing && formik.errors.pembimbing ? <span className='text-danger'>{formik.errors.pembimbing}</span> : null}
              </div>
          
              <div className='col-md-6'>
                <label className='form-label' htmlFor='nama_rayon'>No Ruangan</label>
                <input type='text'
                  className={`form-control ${formik.touched.no_ruangan && formik.errors.no_ruangan ? "is-invalid" : ""}`}
                  name='no_ruangan'
                  id='no_ruangan'
                  {...formik.getFieldProps("no_ruangan")}
                />
                {formik.touched.no_ruangan && formik.errors.no_ruangan ? <span className='text-danger'>{formik.errors.nama_rayon}</span> : null}
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <div></div>
              <button type='submit' className='btn btn-primary bg-blue text-white  mt-3'>Buat Rayon Baru</button>
            </div>
          </form>
        </div>
      </LayoutDashboard>
    </div>
  );
}
