import React, { useContext, useEffect, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import GtkContext from '../../../../context/GtkContext';
import { Link } from 'react-router-dom';
import { Kosong } from '../../../../components/globalcomp/Kosong';
import { BsPencil } from 'react-icons/bs';
import { confirmDelete } from '../../../../function/delete';
import { BsTrash } from 'react-icons/bs';

export const ShowGtk = () => {
    document.title = "Data Diri";

    const { gtk, getGtk } = useContext(GtkContext);
    const [userGtk, setUserGtk] = useState(null);

    useEffect(() => {
        getGtk();
    }, []);

    useEffect(() => {
        if (gtk.gtk) {
            setUserGtk(gtk.gtk);
        }
    }, [gtk.gtk]);

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Data Pribadi" tiga="Data Diri" urlSatu="/dashboard/pembimbing" />


                <table className="table table-borderless">
                    <thead className='thead-dark'>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nama Lengkap</th>
                            <th scope="col">NIK / KITAS</th>
                            <th scope="col">Jenis Kelamin</th>
                            <th scope="col">Tempat Lahir</th>
                            <th scope="col">Tanggal Lahir</th>
                            <th scope="col">Nama Ibu Kandung</th>
                            <th scope="col">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userGtk ? (
                            <tr>
                                <td>1</td>
                                <td>{userGtk.nama_lengkap}</td>
                                <td>{userGtk.nik}</td>
                                <td>{userGtk.jk}</td>
                                <td>{userGtk.tempat_lahir}</td>
                                <td>{userGtk.tanggal_lahir}</td>
                                <td>{userGtk.nama_ibu}</td>
                                <td className='flex flex-row gap-1'>
                                            <Link to={`/dashboard/pembimbing/data-diri/lengkapi/${userGtk._id}`}>
                                                <button className='btn btn-warning btn-sm text-white'>
                                                    <BsPencil />
                                                </button>
                                            </Link>
                                            <button onClick={() => confirmDelete(userGtk._id)} className='btn ml-2 btn-sm btn-danger'>
                                                <BsTrash />
                                            </button>
                                        </td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="8">
                                    <Kosong />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </LayoutDashboard>
        </div>
    );
};

export default ShowGtk;
