import React from 'react';
import { Link } from 'react-router-dom';

const GelBreadcrumb = ({ satu, dua, urlSatu, tiga, urlDua, empat, urlEmpat, id }) => {
    return (
        <div className="page-header element-dengan-border shadow-sm">
            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <div className="title">
                        <h4>{dua}</h4>
                    </div>
                    <nav aria-label="breadcrumb" role="navigation">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href={urlSatu}>{satu}</a>
                            </li>

                            {tiga ? (
                                <li className="breadcrumb-item">
                                    <a href={urlDua}>{tiga}</a>
                                </li>
                            ) : null}

                            {empat ? (
                                <li className="breadcrumb-item">
                                    <a href={urlEmpat}>{empat}</a>
                                </li>
                            ) : null}

                            <li className="breadcrumb-item active" aria-current="page">
                                {dua}
                            </li>
                        </ol>
                    </nav>
                </div>
                
                {id ? (
                    <div className="col-md-4 col-sm-4 text-end">
                        <Link to={`/dashboard/student/update/${id}`}>
                            <button className='btn btn-primary py-2 px-4' style={{ 'fontSize': "18px", 'fontWeight': '600' }}>
                                Edit Data Siswa
                            </button>
                        </Link>
                    </div>
                ) : ''}
            </div>
        </div>
    );
}

export default GelBreadcrumb;
