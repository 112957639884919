import React, { useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditPenghargaan = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    "tingkat_penghargaan": "",
    "jenis_penghargaan": "",
    "nama_penghargaan": "",
    "tahun": "",
    "instansi": ""
  })


  const [errors, setErrors] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiEndpoint}/penghargaan/${id}`, {
          headers: {
            'ngrok-skip-browser-warning': 'true',
          },
        });
        setFormData(response.data.penghargaan);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const formErrors = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
    );

    if (Object.values(formErrors).some((error) => error)) {
      setErrors(formErrors);
      return;
    }

    const result = await Swal.fire({
      title: 'Konfirmasi',
      text: 'Apakah Anda yakin data sudah benar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    });

    if (result.isConfirmed) {
      try {
        await axios.put(`${apiEndpoint}/penghargaan/update/${id}`, formData);
        Swal.fire({
          title: 'Sukses!',
          text: 'Berhasil Update Data',
          icon: 'success',
        }).then(() => navigate('/dashboard/pembimbing/penghargaan/show'));
      } catch (error) {
        console.log(error, "error");
      }
    }
  };
  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Dashboard" dua="Edit Data Penghragaan" tiga="Lainnya" empat="Penghragaan" urlEmpat="/dashboard/pembimbing/penghargaan/show" urlSatu="/dashboard/pembimbing" />


        {/* create-penghargaan */}
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className='card-box shadow-sm p-4 mb-30'>


            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='tingkat_penghargaan' className='form-label'>Tingkat Penghargaan :</label>
                  <input type='text'
                    className={`form-control ${errors.tingkat_penghargaan ? 'is-invalid' : ''}`}
                    value={formData.tingkat_penghargaan}
                    onChange={(e) => handleChange(e)}
                    name='tingkat_penghargaan' />
                  {errors.tingkat_penghargaan && <p style={{ color: "red" }}>{errors.tingkat_penghargaan}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor='jenis_penghargaan' className='form-label'>Jenis Penghargaan :</label>
                <input type='text'
                  className={`form-control ${errors.jenis_penghargaan ? 'is-invalid' : ''}`}
                  value={formData.jenis_penghargaan}
                  onChange={(e) => handleChange(e)}
                  name='jenis_penghargaan' />
                {errors.jenis_penghargaan && <p style={{ color: "red" }}>{errors.jenis_penghargaan}</p>}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='nama_penghargaan' className='form-label'>Nama Penghargaan  :</label>
                  <input type='text'
                    className={`form-control ${errors.nama_penghargaan ? 'is-invalid' : ''}`}
                    value={formData.nama_penghargaan}
                    onChange={(e) => handleChange(e)}
                    name='nama_penghargaan' />
                  {errors.nama_penghargaan && <p style={{ color: "red" }}>{errors.nama_penghargaan}</p>}
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor='tahun' className='form-label'>Tahun :</label>
                <input type='number' className={`form-control ${errors.tahun ? 'is-invalid' : ''}`}
                  value={formData.tahun}
                  onChange={(e) => handleChange(e)}
                  name='tahun' />
                {errors.tahun && <p style={{ color: "red" }}>{errors.tahun}</p>}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor='instansi' className='form-label'>Instansi :</label>
                  <input type='text'
                    className={`form-control ${errors.instansi ? 'is-invalid' : ''}`}
                    value={formData.instansi}
                    onChange={(e) => handleChange(e)}
                    name='instansi' />
                  {errors.instansi && <p style={{ color: "red" }}>{errors.instansi}</p>}
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-primary">Submit</button>
          </div>


        </form>

      </LayoutDashboard>
    </div>
  )
}
