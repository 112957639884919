import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate } from 'react-router-dom';
import GtkContext from '../../../../context/GtkContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const customTooltipTingkat = (
    <Tooltip id="tooltip">
        Tingkat penghargaan yang pernah diterima oleh PTK
    </Tooltip>
);

const customTooltipJenis = (
    <Tooltip id="tooltip">
        Jenis penghargaan yang pernah diterima oleh PTK
    </Tooltip>
);

const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
};

export const CreatePenghargaan = () => {
    const navigate = useNavigate();
    const { gtk } = useContext(GtkContext)
    const gtkId = gtk?.gtk?._id;
    const [formData, setFormData] = useState({
        "tingkat_penghargaan": "",
        "jenis_penghargaan": "",
        "nama_penghargaan": "",
        "tahun": "",
        "instansi": ""
    })

    useEffect(() => {
    }, [gtk])
    const [errors, setErrors] = useState({});


    const handleChange = (e) => {
        const newData = { ...formData }
        newData[e.target.name] = e.target.value
        setFormData(newData)

        const { name, value } = e.target;

        if (name === 'tingkat_penghargaan' && !value.trim()) {
            setErrors({
                ...errors,
                [name]: 'Tingkat Penghargaan wajib diisi',
            });
        }
        else if (name === 'jenis_penghargaan' && !value.trim()) {
            setErrors({
                ...errors,
                [name]: 'Jenis Penghargaan wajib diisi',
            });

        }
        else if (name === 'nama_penghargaan' && !value.trim()) {
            setErrors({
                ...errors,
                [name]: 'Nama Penghargaan wajib diisi',
            });

        }
        else if (name === 'tahun' && !value.trim()) {
            setErrors({
                ...errors,
                [name]: 'Tahun Lahir wajib diisi',
            });

        }
        else if (name === 'instansi' && !value.trim()) {
            setErrors({
                ...errors,
                [name]: 'Instansi Lahir wajib diisi',
            });

        }
        else {
            setErrors({
                ...errors,
                [name]: '',
            });
        }

    }
    const handleSubmit = async (e) => {
        e.preventDefault();


        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }
        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            if (gtk && gtkId) {
                try {
                    await axios.post(process.env.REACT_APP_API_ENDPOINT + "/penghargaan/create/" + gtkId, {
                        tingkat_penghargaan: formData.tingkat_penghargaan,
                        jenis_penghargaan: formData.jenis_penghargaan,
                        nama_penghargaan: formData.nama_penghargaan,
                        tahun: formData.tahun,
                        instansi: formData.instansi,
                    });
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Berhasil Create Data',
                        icon: 'success',
                    }).then(() => {
                        navigate("/dashboard/pembimbing/penghargaan/show");
                    });
                } catch (error) {
                    console.log(error, "error di form");
                }

            } else {
                console.log("gada Id nya")
            }

        };
    }
    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Data Penghargaan" />


                {/* create-penghargaan */}
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>


                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tingkat_penghargaan' className='form-label'>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={customTooltipTingkat}
                                        >
                                            <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                        </OverlayTrigger>
                                        Tingkat Penghargaan :</label>
                                    <input type='text'
                                        className={`form-control ${errors.tingkat_penghargaan ? 'is-invalid' : ''}`}
                                        value={formData.tingkat_penghargaan}
                                        onChange={(e) => handleChange(e)}
                                        name='tingkat_penghargaan' />
                                    {errors.tingkat_penghargaan && <p style={{ color: "red" }}>{errors.tingkat_penghargaan}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='jenis_penghargaan' className='form-label'>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={customTooltipJenis}
                                    >
                                        <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                    </OverlayTrigger>
                                    Jenis Penghargaan :</label>
                                <input type='text'
                                    className={`form-control ${errors.jenis_penghargaan ? 'is-invalid' : ''}`}
                                    value={formData.jenis_penghargaan}
                                    onChange={(e) => handleChange(e)}
                                    name='jenis_penghargaan' />
                                {errors.jenis_penghargaan && <p style={{ color: "red" }}>{errors.jenis_penghargaan}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='nama_penghargaan' className='form-label'>Nama Penghargaan  :</label>
                                    <input type='text'
                                        className={`form-control ${errors.nama_penghargaan ? 'is-invalid' : ''}`}
                                        value={formData.nama_penghargaan}
                                        onChange={(e) => handleChange(e)}
                                        name='nama_penghargaan' />
                                    {errors.nama_penghargaan && <p style={{ color: "red" }}>{errors.nama_penghargaan}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tahun' className='form-label'>Tahun :</label>
                                <input type='number' className={`form-control ${errors.tahun ? 'is-invalid' : ''}`}
                                    value={formData.tahun}
                                    onChange={(e) => handleChange(e)}
                                    name='tahun' />
                                {errors.tahun && <p style={{ color: "red" }}>{errors.tahun}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='instansi' className='form-label'>Instansi :</label>
                                    <input type='text'
                                        className={`form-control ${errors.instansi ? 'is-invalid' : ''}`}
                                        value={formData.instansi}
                                        onChange={(e) => handleChange(e)}
                                        name='instansi' />
                                    {errors.instansi && <p style={{ color: "red" }}>{errors.instansi}</p>}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>


                </form>

            </LayoutDashboard>
        </div>
    )
}
