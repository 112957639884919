import React from 'react'
import { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import LayoutDashboard from '../../layouts/LayoutDashboard'
import GtkContext from '../../context/GtkContext'
import { useEffect } from 'react'
import Banner from "../../assets/images/banner10.png"
import { Link } from 'react-router-dom'
import DashboardContext from '../../context/DashboardContext'
import { GoPeople } from "react-icons/go";
import { MdOutlineSmsFailed } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgBoy } from "react-icons/cg";
import { CgGirl } from "react-icons/cg";

export const DashboardGuru = () => {
    const { user } = useContext(AuthContext);
    const { gtk } = useContext(GtkContext)
    const { loading, dataBelumLengkap, dataLengkap, dataTotal, dataLainnya, RayonDataDiri, RayonDataFamily, RayonDataDokumen } = useContext(DashboardContext)

    useEffect(() => {
        document.title = 'Dashboard | Sidata';
    }, [gtk]);

    return (
        <div>
            <LayoutDashboard>

                <div className='card-box shadow-sm p-4 mb-4' style={{ position: 'relative' }}>
                    <img src={Banner} alt="" style={{ borderRadius: '10px', width: '100%', height: '100%', objectFit: 'cover' }} />
                    <div style={{ position: 'absolute', top: '52%', left: '20%', transform: 'translate(-50%, -50%)', textAlign: 'left', color: '#fff', marginLeft: '20px' }}>
                        <h4 className="font-16 text-white  text-capitalize">
                            Selamat Datang
                            <div className="weight-700 font-30 text-grey">{user?.username}</div>
                        </h4>
                        <p className="font-15 max-width-600 text-white">
                            Pembimbing Rayon : <span className="">{gtk.nama_rayon ? gtk.nama_rayon : '-'}</span>
                        </p>
                    </div>
                </div>

                <div className="pd-ltr-8">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <div className="card-box height-100-p shadow-sm widget-style3">
                                <div className="d-flex flex-wrap">
                                    <div className="widget-data">
                                        <div className="weight-700 font-24 text-dark">
                                            {loading ? (
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            ) : (
                                                <div>{dataTotal}</div>
                                            )}
                                        </div>
                                        <div className="font-14 text-secondary weight-500">
                                            Total Siswa
                                        </div>
                                    </div>
                                    <div className="widget-icon">
                                        <div className="icon" >
                                            <GoPeople />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <div className="card-box height-100-p shadow-sm widget-style3">
                                <div className="d-flex flex-wrap">
                                    <div className="widget-data">
                                        <div className="weight-700 font-24 text-dark">
                                            {loading ? (
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            ) : (
                                                <div> {dataLainnya.maleCount}</div>
                                            )}
                                        </div>
                                        <div className="font-14 text-secondary weight-500">
                                            Total Siswa Laki-laki
                                        </div>
                                    </div>
                                    <div className="widget-icon">
                                        <div className="icon" >
                                            <CgBoy />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <div className="card-box height-100-p shadow-sm widget-style3">
                                <div className="d-flex flex-wrap">
                                    <div className="widget-data">
                                        <div className="weight-700 font-24 text-dark">
                                            {loading ? (
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            ) : (
                                                <div>{dataLainnya.femaleCount}</div>
                                            )}


                                        </div>
                                        <div className="font-14 text-secondary weight-500">
                                            Total Siswa Perempuan
                                        </div>
                                    </div>
                                    <div className="widget-icon">
                                        <div className="icon" >
                                            <CgGirl />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <Link to="/dashboard/detail-data/diri/belum">
                                <div className="card-box height-100-p shadow-sm widget-style3">
                                    <div className="d-flex flex-wrap">
                                        <div className="widget-data">
                                            <div className="weight-700 font-24 text-dark">
                                                {loading ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <div>{RayonDataDiri.belum}</div>
                                                )}
                                            </div>
                                            <div className="font-14 text-secondary weight-500">
                                                Belum Mengisi Data Diri
                                            </div>
                                        </div>
                                        <div className="widget-icon">
                                            <div className="icon" >
                                                <MdOutlineSmsFailed />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <Link to="/dashboard/detail-data/diri/telah">
                                <div className="card-box height-100-p  shadow-sm widget-style3">
                                    <div className="d-flex flex-wrap">
                                        <div className="widget-data">
                                            <div className="weight-700 font-24 text-dark">
                                                {loading ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <div>{RayonDataDiri.lengkap}</div>
                                                )}


                                            </div>
                                            <div className="font-14 text-secondary weight-500">
                                                Sudah Melengkapi Data Diri
                                            </div>
                                        </div>
                                        <div className="widget-icon">
                                            <div className="icon" >
                                                <FaRegCircleCheck />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <Link to="/dashboard/detail-data/keluarga/belum">
                                <div className="card-box height-100-p shadow-sm widget-style3">
                                    <div className="d-flex flex-wrap">
                                        <div className="widget-data">
                                            <div className="weight-700 font-24 text-dark">
                                                {loading ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <div>{RayonDataFamily.belum}</div>
                                                )}
                                            </div>
                                            <div className="font-14 text-secondary weight-500">
                                                Belum Mengisi Data Keluarga
                                            </div>
                                        </div>
                                        <div className="widget-icon">
                                            <div className="icon" >
                                                <MdOutlineSmsFailed />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <Link to="/dashboard/detail-data/keluarga/telah">
                                <div className="card-box height-100-p  shadow-sm widget-style3">
                                    <div className="d-flex flex-wrap">
                                        <div className="widget-data">
                                            <div className="weight-700 font-24 text-dark">
                                                {loading ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <div>{RayonDataFamily.lengkap}</div>
                                                )}


                                            </div>
                                            <div className="font-14 text-secondary weight-500">
                                                Sudah Melengkapi Data Keluarga
                                            </div>
                                        </div>
                                        <div className="widget-icon">
                                            <div className="icon" >
                                                <FaRegCircleCheck />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <Link to="/dashboard/detail-data/dokumen/belum">
                                <div className="card-box height-100-p shadow-sm widget-style3">
                                    <div className="d-flex flex-wrap">
                                        <div className="widget-data">
                                            <div className="weight-700 font-24 text-dark">
                                                {loading ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <div>{RayonDataDokumen.belum}</div>
                                                )}
                                            </div>
                                            <div className="font-14 text-secondary weight-500">
                                                Belum Mengisi Data Dokumen
                                            </div>
                                        </div>
                                        <div className="widget-icon">
                                            <div className="icon" >
                                                <MdOutlineSmsFailed />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
                            <Link to="/dashboard/detail-data/dokumen/telah">
                                <div className="card-box height-100-p  shadow-sm widget-style3">
                                    <div className="d-flex flex-wrap">
                                        <div className="widget-data">
                                            <div className="weight-700 font-24 text-dark">
                                                {loading ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                ) : (
                                                    <div>{RayonDataDokumen.lengkap}</div>
                                                )}


                                            </div>
                                            <div className="font-14 text-secondary weight-500">
                                                Sudah Melengkapi Data Dokumen
                                            </div>
                                        </div>
                                        <div className="widget-icon">
                                            <div className="icon" >
                                                <FaRegCircleCheck />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>


                </div>
            </LayoutDashboard>
        </div>
    )
}

export default DashboardGuru