import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DashboardPage } from "./pages/admin/DashboardPage";
import { LandingPage } from "./pages/landing/LandingPage";
import { RayonPage } from "./pages/admin/master-data/rayon/RayonPage";
import { StudentPage } from "./pages/admin/student/StudentPage";
import { RombelPage } from "./pages/admin/master-data/rombel/RombelPage";
import { LoginScreen } from "./pages/LoginScreen";
import { RayonProvider } from "./context/RayonContext";
import { RombelProvider } from "./context/RombelContext";
import { EditRayonPage } from "./pages/admin/master-data/rayon/EditRayonPage";
import { EditRombelPage } from "./pages/admin/master-data/rombel/EditRombelPage";
import { PetunjukPage } from "./pages/admin/Petunjuk";
import { DataDiriPage } from "./pages/admin/data-diri/DataDiri/DataDiriPage";
import { StudentDetailPage } from "./pages/admin/student/StudentDetailPage";
import { AddNewStudentPage } from "./pages/admin/student/AddNewStudentPage";
import { AuthContextProvider } from "./context/AuthContext";
import { ProtectedRoute } from "./services/ProtectedRoute";
import { ErrorPage } from "./pages/ErrorPage";
import { ProfilePage } from "./pages/admin/ProfilePage";
import { EditDataSiswaPage } from "./pages/admin/data-diri/EditDataDiri/EditDataSiswaPage";
import { EditDataKeluargaPage } from "./pages/admin/data-diri/EditDataDiri/EditDataKeluargaPage";
import { EditDataAlamatPage } from "./pages/admin/data-diri/EditDataDiri/EditDataAlamatPage";
import { EditDataSmpPage } from "./pages/admin/data-diri/EditDataDiri/EditDataSmpPage";
import { DashboardGuru } from "./pages/admin/DashboardGuru";
import { StudentValidate } from "./pages/admin/guru/StudentValidate";
import { DataDiriRayonPage } from "./pages/admin/data-diri/DataDiri/DataDiriRayonPage";
import { GtkProvider } from "./context/GtkContext";
import { CreateGtk } from "./pages/admin/guru/dataDiriGuru/CreateGtk";
import { CreateKepegawaian } from "./pages/admin/guru/kepegawaian/CreateKepegawaian";
import { Kepegawaian } from "./pages/admin/guru/kepegawaian/Kepegawaian";
import { CreatePendidikan } from "./pages/admin/guru/pendidikan/CreatePendidikan";
import { ShowPendidikan } from "./pages/admin/guru/pendidikan/ShowPendidikan";
import { CreateAnak } from "./pages/admin/guru/anak/CreateAnak";
import { CreateBeasiswa } from "./pages/admin/guru/beasiswa/CreateBeasiswa";
import { ShowBeasiswa } from "./pages/admin/guru/beasiswa/ShowBeasiswa";
import { CreateSertifikasi } from "./pages/admin/guru/sertifikasi/CreateSertifikasi";
import { ShowSertifikasi } from "./pages/admin/guru/sertifikasi/ShowSertifikasi";
import { CreateDiklat } from "./pages/admin/guru/diklat/CreateDiklat";
import { ShowDiklat } from "./pages/admin/guru/diklat/ShowDiklat";
import { CreatePenugasan } from "./pages/admin/guru/penugasan/CreatePenugasan";
import { CreateTugasTambahan } from "./pages/admin/guru/tugasTambahan/CreateTugasTambahan";
import { ShowJabatan } from "./pages/admin/guru/jabatan/ShowJabatan";
import { CreateJabatan } from "./pages/admin/guru/jabatan/CreateJabatan";
import { ShowGaji } from "./pages/admin/guru/gaji/ShowGaji";
import { CreateGaji } from "./pages/admin/guru/gaji/CreateGaji";
import { CreateTunjangan } from "./pages/admin/guru/tunjangan/CreateTunjangan";
import { EditNewStudentPage } from "./pages/admin/student/EditNewStudentPage";
import { DetailDashboardGuruPage } from "./pages/admin/DetailDashboardGuruPage";
import { ShowGtk } from "./pages/admin/guru/dataDiriGuru/ShowGtk";
import { AdminShowGtk } from "./pages/admin/guru/dataDiriGuru/AdminShowGtk";
import { ShowTunjangan } from "./pages/admin/guru/tunjangan/ShowTunjangan";
import { AllGtkProvider } from "./context/AllGtkContext";
import { CreateInpassing } from "./pages/admin/guru/inspassing/CreateInpassing";
import { ShowAnak } from "./pages/admin/guru/anak/ShowAnak";
import { ShowInpanssing } from "./pages/admin/guru/inspassing/ShowInpassing";
import { ShowTugasTambahan } from "./pages/admin/guru/tugasTambahan/ShowTugasTambahan";
import { EditInpassing } from "./pages/admin/guru/inspassing/EditInpassing";
import { ShowPenghargaan } from "./pages/admin/guru/penghargaan/ShowPenghargaan";
import { CreatePenghargaan } from "./pages/admin/guru/penghargaan/CreatePenghargaan";
import { EditPenghargaan } from "./pages/admin/guru/penghargaan/EditPenghargaan";
import { EditTugasTambahan } from "./pages/admin/guru/tugasTambahan/EditTugasTambahan";
import { EditPenugasan } from "./pages/admin/guru/penugasan/EditPenugasan";
import { EditDiklat } from "./pages/admin/guru/diklat/EditDiklat";
import { EditSertifikasi } from "./pages/admin/guru/sertifikasi/EditSertifikasi";
import { EditBeasiswa } from "./pages/admin/guru/beasiswa/EditBeasiswa";
import { EditPendidikan } from "./pages/admin/guru/pendidikan/EditPendikan";
import { EditTunjangan } from "./pages/admin/guru/tunjangan/EditTunjangan";
import { EditJabatan } from "./pages/admin/guru/jabatan/EditJabatan";
import { EditGaji } from "./pages/admin/guru/gaji/EditGaji";
import { EditAnak } from "./pages/admin/guru/anak/EditAnak";
import { StudentProvider } from "./context/StudentContext";
import { DashboardProvider } from "./context/DashboardContext";
import { AllStudentProvider } from "./context/AllStudentContext";
import { TeamPage } from "./pages/TeamPage";
import { StatusPage } from "./pages/admin/student/StatusPage";
import { CreateRombelPage } from "./pages/admin/master-data/rombel/CreateRombelPage";
import { ShowPenugasan } from "./pages/admin/guru/penugasan/ShowPenugasan";
import { JaringanError } from "./pages/JaringanError";
import { DetailRayonPage } from "./pages/admin/master-data/rayon/DetailRayonPage";
import ShowAccPage from "./pages/admin/ShowAccPage";
import { CreateRayonPage } from "./pages/admin/master-data/rayon/CreateRayonPage";
import DetailGuru from "./pages/admin/guru/DetailGuru";
import { EditDataPortfolioPage } from "./pages/admin/data-diri/EditDataDiri/EditDataPortfolio";
import { EditDataSertifikatPage } from "./pages/admin/data-diri/EditDataDiri/EditDataSertifikat";
import { TemplateDataDiri } from "./pages/admin/student/Print/TemplateDataDiri";
import { EditStudentPage } from "./pages/admin/student/EditStudentPage";
import TestPrint from "./pages/admin/student/Print/Index";
import { LengkapiData } from "./pages/admin/guru/dataDiriGuru/LengkapiData";
const App = () =>(
 
      <Router>
        <AuthContextProvider>
          <StudentProvider>
            <AllStudentProvider>
              <RombelProvider>
                <RayonProvider>
                  <GtkProvider>
                    <DashboardProvider>
                      <AllGtkProvider>
                        <Routes>
                          <Route path="/" element={<LandingPage />} />
                          <Route path="/sidata-team" element={<TeamPage />} />

                          <Route path="/dashboard/pdf-print" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><TemplateDataDiri /></ProtectedRoute>} />

                          {/* Student */}
                          <Route path="/dashboard/petunjuk" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><PetunjukPage /></ProtectedRoute>} />
                          <Route path="/dashboard/status" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><StatusPage /></ProtectedRoute>} />
                          <Route path="/dashboard/data-diri" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><DataDiriPage /></ProtectedRoute>} />
                          <Route path="/dashboard/update/data-siswa" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><EditDataSiswaPage /></ProtectedRoute>} />
                          <Route path="/dashboard/update/data-keluarga" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><EditDataKeluargaPage /></ProtectedRoute>} />
                          <Route path="/dashboard/update/data-alamat" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><EditDataAlamatPage /></ProtectedRoute>} />
                          <Route path="/dashboard/update/data-dokumen" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><EditDataSmpPage /></ProtectedRoute>} />
                          <Route path="/dashboard/update/data-portfolio" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><EditDataPortfolioPage /></ProtectedRoute>} />
                          <Route path="/dashboard/update/data-sertifikat" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['student']}><EditDataSertifikatPage /></ProtectedRoute>} />



                          {/* student, Admin, Guru */}
                          <Route path="/dashboard/profile" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin', 'guru', 'student']}><ProfilePage /></ProtectedRoute>} />

                          {/* Admin */}
                          <Route path="/admin" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin', 'guru']}><DashboardPage /></ProtectedRoute>} />
                          <Route path="/admin/show-acc" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin', 'guru']}><ShowAccPage /></ProtectedRoute>} />



                          <Route path="/admin/dashboard/student" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><StudentPage /></ProtectedRoute>} />
                          <Route path="/dashboard/student/detail/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin', 'guru']}><StudentDetailPage /></ProtectedRoute>} />
                          <Route path="/dashboard/student/pdf-print/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><TemplateDataDiri /></ProtectedRoute>} />
                          <Route path="/dashboard/student/update/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin', 'guru']}><EditStudentPage /></ProtectedRoute>} />

                          {/* rombel */}
                          <Route path="/admin/rombel" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><RombelPage /></ProtectedRoute>} />
                          <Route path="/admin/rombel/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><CreateRombelPage /></ProtectedRoute>} />
                          <Route path="/admin/rombel/edit/:rombelId" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><EditRombelPage /></ProtectedRoute>} />

                          {/* rayon */}
                          <Route path="/admin/rayon/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><RayonPage /></ProtectedRoute>} />
                          <Route path="/admin/rayon/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><CreateRayonPage /></ProtectedRoute>} />
                          <Route path="/admin/rayon/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><EditRayonPage /></ProtectedRoute>} />
                          <Route path="/admin/rayon/detail/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><DetailRayonPage /></ProtectedRoute>} />

                          <Route path="/dashboard/student/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><AddNewStudentPage /></ProtectedRoute>} />
                          
                          <Route path="/dashboard/student/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><EditNewStudentPage /></ProtectedRoute>} />

                          <Route path="/admin/show/gtk" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><AdminShowGtk /></ProtectedRoute>} />
                          <Route path="/admin/show-gtk/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><DetailGuru /></ProtectedRoute>} />

                          {/* <Route path="/admin/show/gtk/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><EditGtk /></ProtectedRoute>} /> */}
                          <Route path="/print-pdf/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru','admin']}><TestPrint /></ProtectedRoute>} />


                          {/* Guru */}
                          <Route path="/dashboard/detail-data/:tipe/:status" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru','admin']}><DetailDashboardGuruPage /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><DashboardGuru /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/siswa" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><StudentValidate /></ProtectedRoute>} />



                          {/* gtk Data Pribadi */}
                          <Route path="/dashboard/pembimbing/gtk/lengkapi" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin', 'guru']}><CreateGtk /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/gtk/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin']}><ShowGtk /></ProtectedRoute>} />

                          {/* gtk diri */}
                          <Route path="/dashboard/pembimbing/data-diri/lengkapi/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><LengkapiData /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/data-diri/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowGtk /></ProtectedRoute>} />

                          {/* gtk anak */}
                          <Route path="/dashboard/pembimbing/anak/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateAnak /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/anak/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowAnak /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/anak/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditAnak /></ProtectedRoute>} />

                          {/* gtk penghargaan */}
                          <Route path="/dashboard/pembimbing/penghargaan/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowPenghargaan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/penghargaan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreatePenghargaan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/penghargaan/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditPenghargaan /></ProtectedRoute>} />

                          {/* gtk jabatan */}
                          <Route path="/dashboard/pembimbing/jabatan" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowJabatan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/jabatan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateJabatan /></ProtectedRoute>} />

                          {/* gtk gaji */}
                          <Route path="/dashboard/pembimbing/gaji" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowGaji /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/gaji/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateGaji /></ProtectedRoute>} />

                          {/* gtk tunjangan */}
                          <Route path="/dashboard/pembimbing/tunjangan" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowTunjangan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/tunjangan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateTunjangan /></ProtectedRoute>} />

                          {/* gtk pendidikan */}
                          <Route path="/dashboard/pembimbing/pendidikan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreatePendidikan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/pendidikan/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowPendidikan /></ProtectedRoute>} />

                          {/* gtk beasiswa */}
                          <Route path="/dashboard/pembimbing/beasiswa/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateBeasiswa /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/beasiswa/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowBeasiswa /></ProtectedRoute>} />

                          {/* gtk sertifikasi */}
                          <Route path="/dashboard/pembimbing/sertifikasi/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateSertifikasi /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/sertifikasi/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowSertifikasi /></ProtectedRoute>} />

                          {/* gtk diklat */}
                          <Route path="/dashboard/pembimbing/diklat/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateDiklat /></ProtectedRoute>} />
                            <Route path="/dashboard/pembimbing/diklat" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowDiklat /></ProtectedRoute>} />
                          {/* gtk tugas tambahan */}
                          <Route path="/dashboard/pembimbing/tugas-tambahan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateTugasTambahan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/tugas-tambahan/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowTugasTambahan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/tugas-tambahan/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditTugasTambahan /></ProtectedRoute>} />

                          {/* gtk inpassing */}
                          <Route path="/dashboard/pembimbing/inpassing/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateInpassing /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/inpassing/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowInpanssing /></ProtectedRoute>} />

                          <Route path="/dashboard/pembimbing/beasiswa/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateBeasiswa /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/sertifikasi/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateSertifikasi /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/penghargaan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreatePenghargaan /></ProtectedRoute>} />

                          {/* kepegawaian */}
                          <Route path="/dashboard/pembimbing/kepegawaian/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><Kepegawaian /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/kepegawaian/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateKepegawaian /></ProtectedRoute>} />

                          {/* jabatan */}
                          <Route path="/dashboard/pembimbing/jabatan/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowJabatan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/jabatan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateJabatan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/jabatan/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditJabatan /></ProtectedRoute>} />

                          {/* gaji */}
                          <Route path="/dashboard/pembimbing/gaji/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowGaji /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/gaji/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateGaji /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/gaji/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditGaji /></ProtectedRoute>} />

                          {/* tunjangan */}
                          <Route path="/dashboard/pembimbing/tunjangan/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowTunjangan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/tunjangan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateTunjangan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/tunjangan/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditTunjangan /></ProtectedRoute>} />

                          {/* diklat */}
                          <Route path="/dashboard/pembimbing/diklat/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateDiklat /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/diklat/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowDiklat /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/diklat/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditDiklat /></ProtectedRoute>} />

                          {/* pendidikan */}
                          <Route path="/dashboard/pembimbing/pendidikan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreatePendidikan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/pendidikan/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowPendidikan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/pendidikan/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditPendidikan /></ProtectedRoute>} />

                          {/* beasiswa */}
                          <Route path="/dashboard/pembimbing/beasiswa/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateBeasiswa /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/beasiswa/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowBeasiswa /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/beasiswa/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditBeasiswa /></ProtectedRoute>} />

                          {/* sertifikasi */}
                          <Route path="/dashboard/pembimbing/sertifikasi/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateSertifikasi /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/sertifikasi/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowSertifikasi /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/sertifikasi/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditSertifikasi /></ProtectedRoute>} />

                          {/* penugasan */}
                          <Route path="/dashboard/pembimbing/penugasan/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreatePenugasan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/penugasan/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowPenugasan /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/penugasan/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditPenugasan /></ProtectedRoute>} />

                          {/* inpassing */}
                          <Route path="/dashboard/pembimbing/inpassing/create" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><CreateInpassing /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/inpassing/show" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><ShowInpanssing /></ProtectedRoute>} />
                          <Route path="/dashboard/pembimbing/inpassing/edit/:id" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['guru']}><EditInpassing /></ProtectedRoute>} />

                          <Route path="/dashboard/detail-student/:studentId" element={<ProtectedRoute accessBy="authenticated" allowedRoles={['admin', 'guru']}><DataDiriRayonPage /></ProtectedRoute>} />


                          <Route path="/" element={<LandingPage />} />
                          <Route path="*" element={<ErrorPage />} />
                          <Route path="/not-found" element={<ErrorPage />} />
                          <Route path="/error" element={<JaringanError />} />
                          <Route path="/login" element={<ProtectedRoute accessBy="non-authenticated"><LoginScreen /></ProtectedRoute>} />
                        </Routes>
                      </AllGtkProvider>
                    </DashboardProvider>
                  </GtkProvider>
                </RayonProvider>
              </RombelProvider>
            </AllStudentProvider>
          </StudentProvider>
        </AuthContextProvider>
      </Router>
   
  
)
export default App;