import React, { useContext, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import StudentContext from '../../../../context/StudentContext';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditDataPortfolioPage = () => {
    const navigate = useNavigate();
    const { student, getStudent } = useContext(StudentContext);

    const [formData, setFormData] = useState({
        nama_projek: "",
        tanggal_projek: "",
        link_portfolio: "",
        portfolio: null,
        pengerjaan: "",
        pemberi_projek: ""
    });

    const [errors, setErrors] = useState({});

    const validateFile = (file, acceptedFormats, maxSize) => {
        const allowedFormats = new RegExp(`(${acceptedFormats.join('|')})$`, 'i');
        if (!file) return 'Wajib diisi';
        if (!allowedFormats.test(file.name)) return 'Format file tidak valid. Harap pilih file JPG atau PDF.';
        if (file.size > maxSize) return 'Ukuran file terlalu besar. Maksimal 2 MB.';
        return '';
    };

    const handleChange = (e) => {
        const { name, files } = e.target;
        let errorMessage = ''
        for (let index = 0; index < files.length; index++) {
            // const element = files[index];
            errorMessage = validateFile(files[index], ['jpg', 'jpeg', 'pdf', 'png', 'rar', 'zip'], 2 * 1024 * 1024)
            
        }
        // Object.entries(files).map(([key, value]) => (
        //     errorMessage = validateFile(value, ['jpg', 'jpeg', 'pdf', 'png', 'rar', 'zip'], 2 * 1024 * 1024)
        // ))

        // let file = el;
        // let errorMessage = validateFile(file, ['jpg', 'jpeg', 'pdf', 'rar', 'zip'], 2 * 1024 * 1024);

        setFormData((prevData) => ({ ...prevData, [name]: files }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = Object.fromEntries(
            Object.entries(formData.portfolio).map(([key, value]) => [key, validateFile(value, ['jpg', 'jpeg', 'pdf', 'png', 'rar', 'zip'], 2 * 1024 * 1024)])
        );

        if (Object.values(formErrors).some((error) => error !== '')) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            Swal.fire({
                title: 'Loading...',
                text: 'Sedang memproses, harap tunggu.',
                allowOutsideClick: false,
                showConfirmButton: false,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                const data = new FormData();
                Object.entries(formData).forEach(([key, value]) => {
                    data.append(key, value);
                });

                // lengthPort = formData.portfolio.length
                
                for (let index = 0; index < formData.portfolio.length; index++) {
                    // const element = formData.portfolio[index];
                    
                    data.append("portfolio", formData.portfolio[index])
                }


                // console.log(port)

                // data.append('portfolio', formData.portfolio[0])
                // Object.entries(formData.portfolio).forEach(([key, value]) => {
                //     data.append('file'+key, value);
                // });


                // for (let pair of data.entries()) {
                //     console.log(pair[0] + ', ' + pair[1]);
                // }

                // console.log(data)

                const response = await axios.post(`${apiEndpoint}/upload/portfolio/${student?._id}`, data);
                console.log(response, "ini data response");
                getStudent();
                Swal.fire({
                    title: 'Success!',
                    text: 'Dokumen berhasil diunggah.',
                    icon: 'success',
                }).then(() => navigate('/dashboard/data-diri'));
            } catch (error) {
                console.log(error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Gagal mengunggah data. Silakan coba lagi.',
                    icon: 'error',
                });
            }
        }
    };

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Data Siswa" dua="Edit Data Portfolio" urlSatu="/dashboard/data-diri" />
                <div className='card-box shadow-sm p-4'>
                    <form onSubmit={handleSubmit} encType='multipart/form-data'>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Nama Proyek <span className='text-danger'>*</span></label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.nama_projek ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.nama_projek}
                                        name='nama_projek'
                                        onChange={e => setFormData({...formData, nama_projek: e.target.value})}
                                    />
                                    {errors.nama_projek && <p style={{ color: "red" }}>{errors.nama_projek}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Tanggal Proyek <span className='text-danger'>*</span></label>
                                    <input
                                        type='date'
                                        className={`form-control ${errors.tanggal_projek ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.tanggal_projek}
                                        name='tanggal_projek'
                                        onChange={e => setFormData({...formData, tanggal_projek: e.target.value})}
                                    />
                                    {errors.tanggal_projek && <p style={{ color: "red" }}>{errors.tanggal_projek}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Lama Pengerjaan <span className='text-danger'>*</span></label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.pengerjaan ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.pengerjaan}
                                        name='pengerjaan'
                                        onChange={e => setFormData({...formData, pengerjaan: e.target.value})}
                                    />
                                    {errors.pengerjaan && <p style={{ color: "red" }}>{errors.pengerjaan}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Pemberi Projek <span className='text-danger'>*</span></label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.pemberi_projek ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.pemberi_projek}
                                        name='pemberi_projek'
                                        onChange={e => setFormData({...formData, pemberi_projek: e.target.value})}
                                    />
                                    {errors.pemberi_projek && <p style={{ color: "red" }}>{errors.pemberi_projek}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Link Portolio</label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.link_portfolio ? 'is-invalid' : ''}`}
                                        defaultValue={formData?.link_portfolio}
                                        name='link_portfolio'
                                        onChange={e => setFormData({...formData, link_portfolio: e.target.value})}
                                    />
                                    {errors.link_portfolio && <p style={{ color: "red" }}>{errors.link_portfolio}</p>}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label htmlFor={`formFile-portfolio`} className="form-label text-capitalize">
                                        Portfolio
                                    </label>
                                    <input
                                        className={`form-control ${errors['portfolio'] ? 'is-invalid' : ''}`}
                                        type="file"
                                        id={`formFile-portfolio`}
                                        name={'portfolio'}
                                        onChange={handleChange}
                                        multiple
                                    />
                                    {errors['portfolio'] && <p className='text-danger'>{errors['portfolio']}</p>}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Simpan</button>
                    </form>
                </div>
            </LayoutDashboard>
        </div>
    );
};
