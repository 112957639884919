import React from 'react';

export const Stepper = ({ steps, currentStep }) => {
  return (
    <div className="progress-indicator">
      {steps.map((step, index) => (
        <div key={index} className={`step ${index <= currentStep ? 'completed' : ''}`}>
          <div className="step-content">
            {index < currentStep ? (
              <i className="bi bi-check-circle"></i>
            ) : (
              index + 1
            )}
          </div>
          {index < steps.length - 1 && <div className="connector"></div>}
          <p>{step}</p>
        </div>
      ))}
    </div>
  );
};
