import React, { useContext, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const customTooltipKeterangan = (
    <Tooltip id="tooltip">
        Keterangan terkait beasiswa yang pernah diterima PTK
    </Tooltip>
);

const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
};

export const CreateBeasiswa = () => {

    const navigate = useNavigate();

    const { gtk } = useContext(GtkContext)
    const gtkId = gtk?.gtk?._id
    const [formData, setFormData] = useState({
        'jenis_beasiswa': "",
        "keterangan": "",
        "tahun_mulai": "",
        "tahun_akhir": "",
        "masih_menerima": "",
    })

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : `wajib diisi` }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            if (gtk && gtkId) {
                try {
                    await axios.post(`${apiEndpoint}/beasiswa/create/${gtkId}`, { ...formData });
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Berhasil Create Data',
                        icon: 'success',
                    }).then(() => navigate('/dashboard/pembimbing/beasiswa/show'));
                } catch (error) {
                    console.log(error.response?.data?.error || 'Error');
                }
            } else {
                console.log('Tidak ada ID.');
            }
        }
    }
    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Tambah Data Beasiswa" tiga="Lainnya" empat="Beasiswa" urlEmpat="/dashboard/pembimbing/beasiswa/show" urlSatu="/dashboard/pembimbing" />
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>


                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='jenis_beasiswa' className='form-label'>Jenis Beasiswa :</label>
                                    <input type='text' name='jenis_beasiswa'
                                        className={`form-control ${errors.jenis_beasiswa ? 'is-invalid' : ''}`}
                                        value={formData.jenis_beasiswa}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.jenis_beasiswa && <p style={{ color: "red" }}>{errors.jenis_beasiswa}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='keterangan' className='form-label'>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={customTooltipKeterangan}
                                    >
                                        <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                    </OverlayTrigger>
                                    Keterangan :</label>
                                <input type='text' name='keterangan'
                                    className={`form-control ${errors.keterangan ? 'is-invalid' : ''}`}
                                    value={formData.keterangan}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.keterangan && <p style={{ color: "red" }}>{errors.keterangan}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tahun_mulai' className='form-label'>Tahun Mulai  :</label>
                                    <input type='number' name='tahun_mulai'
                                        className={`form-control ${errors.tahun_mulai ? 'is-invalid' : ''}`}
                                        value={formData.tahun_mulai}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.tahun_mulai && <p style={{ color: "red" }}>{errors.tahun_mulai}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tahun_akhir' className='form-label'>Tahun Akhir :</label>
                                <input type='number' name='tahun_akhir'
                                    className={`form-control ${errors.tahun_akhir ? 'is-invalid' : ''}`}
                                    value={formData.tahun_akhir}
                                    onChange={(e) => handleChange(e)}
                                />
                                {errors.tahun_akhir && <p style={{ color: "red" }}>{errors.tahun_akhir}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='masih_menerima' className='form-label'>Masih Menerima :</label>
                                    <select name='masih_menerima' className={`form-select ${errors.masih_menerima ? 'is-invalid' : ''}`}
                                        value={formData.masih_menerima}
                                        onChange={(e) => handleChange(e)}>
                                        <option value=''>Pilih Opsi</option>
                                        <option value='true'>Ya</option>
                                        <option value='false'>Tidak</option>
                                    </select>
                                    {errors.masih_menerima && <p style={{ color: "red" }}>{errors.masih_menerima}</p>}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>

                </form>

            </LayoutDashboard>
        </div>
    )
}
