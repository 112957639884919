import ReactDOMServer from 'react-dom/server';
import { Page, Document, StyleSheet, PDFViewer, Font } from "@react-pdf/renderer";
import { Html } from 'react-pdf-html';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function PrintDataDiri({student}) {

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const dateFormatted =  new Date().toLocaleDateString('en-GB', options);

    // const styles = StyleSheet.create({
    //     page: {
    //       flexDirection: 'column',
    //       backgroundColor: '#E4E4E4',
    //     },
    //     section: {
    //       margin: 10,
    //       padding: 10,
    //       flexGrow: 1,
    //     },
    //     pageBreak: {
    //       flexBasis: '100%',
    //       height: 0,
    //     },
    //   });

    Font.register({
        family: 'Arial',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/@canvas-fonts/arial@1.0.4/Arial.ttf' },
            { src: "https://cdn.jsdelivr.net/npm/@canvas-fonts/arial-bold@1.0.4/Arial Bold.ttf", fontWeight: 900 }
        ]
    })
    
const element = (
    <html>
      <body>
        <style>
          {`
          body {
            width: 100%;
            padding: 20px 30px;
            height: auto;
            line-height: 1.625;
            font-family: Arial;
          }
          h1 {
              text-align: center;
              font-size: 0.9rem;
              margin-bottom: 1.2rem;
          }
          p {
              text-indent: 50px;
          }
          th {
              text-align: left;
              white-space: nowrap;
              font-size: 0.8rem;
          }
          p,
          table,
          span {
              font-size: 12px
          }
          span {
              font-size: 12px;
              float: right;
          }
          td {
            display: inline;
          }
          .row {
            width: 100%;
            display: flex;
            flexDirection: row;
            font-size: 11px;
            line-height: 1.75;
        }
        .col1 {
            width: 30%;
            display: inline;
            font-size: 11px;
            font-weight: bold;
            line-height: 1.75;
        }
        .col2 {
            width: 2.5%;
            display: inline;
            font-size: 11px;
            line-height: 1.75;
        }
        .col3 {
            width: 67.5%;
            display: inline;
            font-size: 11px;
            line-height: 1.75;
        }
        .textHeadBold {
            font-size: 12px;
            font-weight: bold;
            text-align: start;
            text-transform: uppercase;
        }
        .textBold {
            font-size: 11px;
            font-weight: bold;
        }
        .pageBreak {
            break-after: page;
            clear: both;
            page-break-before: always;
            page-break-after: always;
        }
        #section_data_diri {
            height: 450px;
        }
        #section_data_ayah {
            height: 250px;
        }
        #section_data_ibu {
            height: 225px;
        }
          `}
        </style>
        <div style={{ marginTop: '5px' }}>
            <b style={{textAlign: 'center', fontSize: '12px', fontWeight: 'bold', display: 'block', lineHeight: '1.5px'}}>SURAT PERNYATAAN</b>
            <b style={{textAlign: 'center', fontSize: '12px', fontWeight: 'bold', display: 'block', lineHeight: '1.5px'}}>VALIDASI DAN VERIFIKASI DATA PESERTA DIDIK</b>
            <b style={{textAlign: 'center', fontSize: '12px', fontWeight: 'bold', display: 'block', lineHeight: '1.5px'}}>SMK WIKRAMA BOGOR T.P. 2023-2024</b>
        </div>
        <p><span style={{textIndent: '40px'}}>Yang bertanda tangan di bawah ini kami selaku orang tua peserta didik dari : </span></p>
        <p className="textHeadBold" style={{textDecoration: 'uppercase', backgroundColor: 'gray', padding: '5px 5px 0px 5px', verticalAlign: 'bottom'}}>Data Peserta Didik</p>
        <div id="section_data_diri">
            <div class="row">
                <div class="col1">NIS/NISN/NIK</div>
                <div class="col2">:</div>
                <div class="col3">{student.nis ? student.nis : '-'} / {student.nisn ? student.nisn : '-'} / {student.nik ? student.nik : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Nama</div>
                <div class="col2">:</div>
                <div class="col3">{student.nama ? student.nama : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Jenis Kelamin</div>
                <div class="col2">:</div>
                <div class="col3">{student.jk ? student.jk : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Rombel</div>
                <div class="col2">:</div>
                <div class="col3">{student.rombel ? student.rombel : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Rayon</div>
                <div class="col2">:</div>
                <div class="col3">{student.rayon ? student.rayon : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Tempat, Tanggal Lahir</div>
                <div class="col2">:</div>
                <div class="col3">{student.tempat_lahir ? student.tempat_lahir : '-'}, {student.tanggal_lahir ? student.tanggal_lahir : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Agama</div>
                <div class="col2">:</div>
                <div class="col3">{student.agama ? student.agama : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Alamat</div>
                <div class="col2">:</div>
                <div class="col3">{student.alamat ? student.alamat : '-'} <span className="textBold">RT./RW.</span> {student.rt ? student.rt : '-'}/{student.rw ? student.rw : '-'} <span className="textBold">Desa/Kel.</span> {student.nama_kelurahan ? student.nama_kelurahan : '-'} <span className="textBold">Kec.</span> {student.kecamatan ? student.kecamatan : '-'} <span className="textBold">Kab/Kota.</span> {student.nama_kota_kabupaten ? student.nama_kota_kabupaten : '-'} <span className="textBold">Provinsi.</span> {student.provinsi ? student.provinsi : '-'}, {student.kode_pos ? student.kode_pos : '-'} 
                    <p></p>
                    </div>
            </div>
            <div class="row">
                <div class="col1">Tempat Tinggal</div>
                <div class="col2">:</div>
                <div class="col3">{student.tinggal_bersama ? (student.tinggal_bersama === 'Lainnya' ? student.ket_tinggal_bersama : student.tinggal_bersama) : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Moda Transportasi</div>
                <div class="col2">:</div>
                <div class="col3">{student.transportasi ? (student.transportasi === 'Lainnya' ? student.ket_transportasi : student.transportasi) : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Anak ke-</div>
                <div class="col2">:</div>
                <div class="col3">{student.anak_ke ? student.anak_ke : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Sekolah Asal</div>
                <div class="col2">:</div>
                <div class="col3">{student.asal_smp ? student.asal_smp : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">No. Seri Ijazah SD</div>
                <div class="col2">:</div>
                <div class="col3">{student.no_ijazah_sd ? student.no_ijazah_sd : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">No. Seri Ijazah SMP</div>
                <div class="col2">:</div>
                <div class="col3">{student.no_ijazah_smp ? student.no_ijazah_smp : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">No. Peserta UN SMP</div>
                <div class="col2">:</div>
                <div class="col3">{student.no_un ? student.no_un : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Email Sekolah</div>
                <div class="col2">:</div>
                <div class="col3">{student.email ? student.email : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Nomor HP</div>
                <div class="col2">:</div>
                <div class="col3">{student.no_telp ? student.no_telp : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Tinggi Badan</div>
                <div class="col2">:</div>
                <div class="col3">{student.tb ? student.tb : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Berat Badan</div>
                <div class="col2">:</div>
                <div class="col3">{student.bb ? student.bb : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Golongan Darah</div>
                <div class="col2">:</div>
                <div class="col3">{student.gol_darah ? student.gol_darah : '-'}</div>
            </div>
        </div>
        <p className="textHeadBold" style={{textDecoration: 'uppercase', backgroundColor: 'gray', padding: '5px 5px 0px 5px', verticalAlign: 'bottom'}}>Data Ayah</p>
        <div id="section_data_ayah">
            <div class="row">
                <div class="col1">No. Kartu Keluarga</div>
                <div class="col2">:</div>
                <div class="col3">{student.no_kk ? student.no_kk : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">NIK</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.nik_ayah : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Nama Ayah</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.nama_ayah : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Tempat, Tanggal Lahir</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id?.tempat_lahir_ayah ? student.keluarga_id?.tempat_lahir_ayah : '-'}, {student.keluarga_id?.tanggal_lahir_ayah ? student.keluarga_id?.tanggal_lahir_ayah : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Alamat</div>
                <div class="col2">:</div>
                <div class="col3">{student.alamat ? student.alamat : '-'} <span className="textBold">RT./RW.</span> {student.rt ? student.rt : '-'}/{student.rw ? student.rw : '-'} <span className="textBold">Desa/Kel.</span> {student.nama_kelurahan ? student.nama_kelurahan : '-'} <span className="textBold">Kec.</span> {student.kecamatan ? student.kecamatan : '-'} <span className="textBold">Kab/Kota.</span> {student.nama_kota_kabupaten ? student.nama_kota_kabupaten : '-'} <span className="textBold">Provinsi.</span> {student.provinsi ? student.provinsi : '-'}, {student.kode_pos ? student.kode_pos : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Pendidikan</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.pendidikan_ayah : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Pekerjaan</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.pekerjaan_ayah : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Penghasilan Perbulan</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.penghasilan_ayah : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">No. HP</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.no_telp_ayah : '-'}</div>
            </div>
        </div>

        <div className="pageBreak"></div>

        <p className="textHeadBold" style={{textDecoration: 'uppercase', backgroundColor: 'gray', padding: '5px 5px 0px 5px', verticalAlign: 'bottom'}}>Data Ibu</p>
        
        <div id="section_data_ibu">
            <div class="row">
                <div class="col1">NIK</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.nik_ibu : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Nama Ibu</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.nama_ibu : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Tempat, Tanggal Lahir</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id?.tempat_lahir_ibu ? student.keluarga_id?.tempat_lahir_ibu : '-'}, {student.keluarga_id?.tanggal_lahir_ibu ? student.keluarga_id?.tanggal_lahir_ibu : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Alamat</div>
                <div class="col2">:</div>
                <div class="col3">{student.alamat ? student.alamat : '-'} <span className="textBold">RT./RW.</span> {student.rt ? student.rt : '-'}/{student.rw ? student.rw : '-'} <span className="textBold">Desa/Kel.</span> {student.nama_kelurahan ? student.nama_kelurahan : '-'} <span className="textBold">Kec.</span> {student.kecamatan ? student.kecamatan : '-'} <span className="textBold">Kab/Kota.</span> {student.nama_kota_kabupaten ? student.nama_kota_kabupaten : '-'} <span className="textBold">Provinsi.</span> {student.provinsi ? student.provinsi : '-'}, {student.kode_pos ? student.kode_pos : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Pendidikan</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.pendidikan_ibu : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Pekerjaan</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.pekerjaan_ibu : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">Penghasilan Perbulan</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.penghasilan_ibu : '-'}</div>
            </div>
            <div class="row">
                <div class="col1">No. HP</div>
                <div class="col2">:</div>
                <div class="col3">{student.keluarga_id ? student.keluarga_id.no_telp_ibu : '-'}</div>
            </div>
        </div>

        {student.keluarga_id ? (student.keluarga_id.nama_wali ? (
            <>
                <p className="textHeadBold" style={{textDecoration: 'uppercase', backgroundColor: 'gray', padding: '5px 5px 0px 5px', verticalAlign: 'bottom'}}>Data Wali</p>
                <div class="row">
                    <div class="col1">NIK</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.keluarga_id ? student.keluarga_id.nik_wali : '-'}</div>
                </div>
                <div class="row">
                    <div class="col1">Nama Wali</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.keluarga_id ? student.keluarga_id.nama_wali : '-'}</div>
                </div>
                <div class="row">
                    <div class="col1">Jenis Kelamin Wali</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.keluarga_id ? student.keluarga_id.jk_wali : '-'}</div>
                </div>
                <div class="row">
                    <div class="col1">Tempat, Tanggal Lahir</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.keluarga_id?.tempat_lahir_wali ? student.keluarga_id?.tempat_lahir_wali : '-'}, {student.keluarga_id?.tanggal_lahir_wali ? student.keluarga_id?.tanggal_lahir_wali : '-'}</div>
                </div>
                <div class="row">
                    <div class="col1">Alamat</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.alamat ? student.alamat : '-'} <span className="textBold">RT./RW.</span> {student.rt ? student.rt : '-'}/{student.rw ? student.rw : '-'} <span className="textBold">Desa/Kel.</span> {student.nama_kelurahan ? student.nama_kelurahan : '-'} <span className="textBold">Kec.</span> {student.kecamatan ? student.kecamatan : '-'} <span className="textBold">Kab/Kota.</span> {student.nama_kota_kabupaten ? student.nama_kota_kabupaten : '-'} <span className="textBold">Provinsi.</span> {student.provinsi ? student.provinsi : '-'}, {student.kode_pos ? student.kode_pos : '-'}</div>
                </div>
                <div class="row">
                    <div class="col1">Pendidikan</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.keluarga_id ? student.keluarga_id.pendidikan_wali : '-'}</div>
                </div>
                <div class="row">
                    <div class="col1">Pekerjaan</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.keluarga_id ? student.keluarga_id.pekerjaan_wali : '-'}</div>
                </div>
                <div class="row">
                    <div class="col1">Penghasilan Perbulan</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.keluarga_id ? student.keluarga_id.penghasilan_wali : '-'}</div>
                </div>
                <div class="row">
                    <div class="col1">No. HP</div>
                    <div class="col2">:</div>
                    <div class="col3">{student.keluarga_id ? student.keluarga_id.no_telp_wali : '-'}</div>
                </div>
            </>
        ) : '') : ''}

        <p><span style={{textIndent: '40px'}}>Menyatakan bahwa saya menyetujui data tersebut dan dapat mempertanggungjawabkan kebenarannya, jika suatu saat kesalahan data di ijazah dan dokumen lainnya, bukan merupakan tanggung jawab SMK Wikrama Bogor.</span><br /><span style={{textIndent: '40px'}}>Demikian surat pernyataan ini dibuat agar dapat digunakan sebagaimana mestinya.</span></p>
        <table>
            <tr>
                <td></td>
                <td></td>
                <td><span style={{fontWeight: 'bold', textAlign: 'left'}}>Bogor, {dateFormatted}</span></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td style={{height: '75px'}}></td>
                <td></td>
                <td style={{fontWeight: 'bold', textAlign: 'left'}}>Yang membuat pernyataan,</td>
            </tr>
            <tr>
                {/* <td style={{textAlign: 'center'}}>{student.nama}</td> */}
                <td></td>
                <td></td>
                <td style={{textAlign: 'left'}}>{student.keluarga_id ? (student.keluarga_id.nama_ibu ? student.keluarga_id.nama_ibu : student.keluarga_id.nama_ayah) : '-'}</td>
            </tr>
        </table>
        {/* <div style={{height: 'auto', clear: 'right', marginTop: '12px'}}>
            <div style={{float: 'left', width: '250px', height: '50px', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center'}}>
                <div style={{fontWeight: 'bold', fontSize: '14px', textAlign: 'center'}}></div>
                <div style={{textAlign: 'center', marginTop: '25%', fontSize: '14px'}}></div>
            </div>
            <div style={{float: 'right', width: '250px', height: '50px', display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center'}}>
                <div style={{fontWeight: 'bold', fontSize: '14px', textAlign: 'center'}}>Yang membuat pernyataan,</div>
                <div style={{textAlign: 'center', marginTop: '25%', fontSize: '14px'}}></div>
            </div>
        </div> */}
      </body>
    </html>
  );
  
//   const html = ReactDOMServer.renderToStaticMarkup(element);
  const html = ReactDOMServer.renderToStaticMarkup(element);

  return (
    // <PDFViewer>
        <Document>
            <Page size={'A4'}>
                <Html>{html}</Html>
                {/* <Html
                    stylesheet={styles}
                >
                    <html>
                        <body>
                        <h1 class="title">My PDF Title</h1>
                <p class="text">This is a paragraph with <span class="bold">bold</span> and <span class="italic">italic</span> text.</p>
                        </body>
                    </html>
                </Html> */}
            </Page>
        </Document>
    // </PDFViewer>
  );
}