
import React, { useContext, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { BiPlus } from 'react-icons/bi';
import { BsEyeFill, BsTrashFill } from 'react-icons/bs';
import handleExportClick from '../../../../function/export';
import AllGtkContext from '../../../../context/AllGtkContext';
import { confirmDelete } from '../../../../function/delete';
import { Kosong } from '../../../../components/globalcomp/Kosong';
import ModalImport from '../../../../components/globalcomp/ModalImport';
import importService from '../../../../function/import';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const AdminShowGtk = () => {
    const { allGtk, AllGetGtk } = useContext(AllGtkContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const apiExport = '/export-data-excell-gtk';
    const fileName = 'gtk.xlsx';
    const navigate = useNavigate()
    useEffect(() => {
        document.title = 'Data Gtk | Sidata';
    }, []);

    const filteredRombels = allGtk.filter((rombel) =>
        rombel.nama_lengkap.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredRombels.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [file, setFile] = useState([]);

    const handleOnChange = (e) => {
        setFile(e.target.files);
        console.log(e.target.files, "coy ni e")
    };



    const handleOnSubmitNoneRayon = async (event) => {
        event.preventDefault();
        const apiEndpointTanpaRayon = apiEndpoint + "/import/excel/gtk";
        await importService(file, "file_gtk", apiEndpointTanpaRayon, AllGetGtk);
    };


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Data GTK" urlSatu="/admin" />
                <div className="card-box shadow-sm p-4 mb-30">
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex justify-content-center'>
                            <Link to="/dashboard/pembimbing/gtk/create">
                                <button className='btn bg-blue text-white'>
                                    <BiPlus /> Buat Data GTK
                                </button>
                            </Link>
                            <div>
                                <button className='btn btn-success ml-3' onClick={() => handleExportClick(apiEndpoint + apiExport, fileName)}>
                                    Export Excel
                                </button>
                            </div>
                            <div className="dropdown ml-3">
                                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Import Excel
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <span className="dropdown-item cursor-pointer" data-toggle="modal" data-target="#file-export-none" >Import GTK</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input
                                type="search"
                                className='form-control'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Cari nama lengkap..."
                            />
                        </div>
                    </div>

                    <ModalImport title="Guru Tanpa Rayon" id="file-export-none" onSubmit={handleOnSubmitNoneRayon} onChange={handleOnChange} />

                    <div class="table-responsive">
                        <table className="table mt-3  table-borderless">
                            <thead className='thead-dark  custom-header'>
                                <tr>
                                    <th>#</th>
                                    <th>Nama Lengkap</th>
                                    <th>Email</th>
                                    <th>NIP</th>
                                    <th>No Telephone</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allGtk && allGtk.length > 0 ? (
                                    currentItems.length > 0 ? (
                                        currentItems.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{item.nama_lengkap}</td>
                                                <td>{item.email}</td>
                                                <td>{item.nip}</td>
                                                <td>{item.no_telp}</td>
                  
                                                <td className='d-flex '>
                                                    <Link to={`/admin/show-gtk/${item._id}`}>
                                                        <button className='btn ml-3 btn-sm btn-primary text-white'>
                                                            <BsEyeFill /> Detail
                                                        </button>
                                                    </Link>

                                                    <button className='btn btn-danger ml-3 btn-sm'
                                                        onClick={() => confirmDelete(item._id, 'gtk/delete', navigate, AllGetGtk)}>
                                                        <BsTrashFill /> Hapus
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">Data tidak ditemukan</td>
                                        </tr>
                                    )
                                ) : (
                                    <Kosong />
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                            </li>
                            {Array.from({ length: Math.ceil(filteredRombels.length / itemsPerPage) }).map((_, index) => (
                                <li key={index} className={` page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => paginate(index + 1)}>{index + 1}</button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button className="page-link" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filteredRombels.length / itemsPerPage)}>Next</button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </LayoutDashboard>
        </div>
    )
}
