import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { Information } from '../../../../components/globalcomp/Information'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { BsPencil, BsTrashFill } from 'react-icons/bs'

import { confirmDelete } from '../../../../function/delete'

export const ShowSertifikasi = () => {
    document.title = "Data Sertifikasi";

    const { gtk, getGtk } = useContext(GtkContext)
    const [getSertifikasi, setSertifikasi] = useState([])
    useEffect(() => {
        setSertifikasi(gtk.gtk?.sertifikasi_id)
    }, [gtk.gtk])
    useEffect(() => {
        getGtk()
    }, [])

   
    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Data Sertifikasi" />
                <Information title="sertifikasi" />


                <div className='card-box shadow-sm p-4 mb-30'>

                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/sertifikasi/create">
                            <button type="button" className="btn btn-primary bg-blue text-white mb-2">Tambah Data Sertifikasi</button>
                        </Link>

                    </div>
                    <table className="table table-borderless">
                        <thead className='thead-dark'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Jenis Sertifikasi</th>
                                <th scope="col">No Sertifikasi</th>
                                <th scope="col">Tahun Sertifikasi</th>
                                <th scope="col">Bidang Studi</th>
                                <th scope="col">No. Registrasi</th>
                                <th scope="col">No. Peserta</th>
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getSertifikasi && getSertifikasi.length > 0 ? (
                                getSertifikasi.map((item, index) => (

                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.jenis_sertifikasi}</td>
                                        <td>{item.no_sertifikasi}</td>
                                        <td>{item.thn_sertifikasi}</td>
                                        <td>{item.bidang_studi}</td>
                                        <td>{item.no_reg}</td>
                                        <td>{item.no_peserta}</td><td>
                                            <Link to={`/dashboard/pembimbing/sertifikasi/edit/${item._id}`}>
                                                <button className='btn btn-sm btn-warning text-white'>
                                                    <BsPencil /> Ubah
                                                </button>
                                            </Link>
                                            <button
                                                className='btn btn-danger ml-3 btn-sm'
                                                onClick={() => confirmDelete(item._id, 'sertifikasi/delete', getGtk)}
                                            >
                                                <BsTrashFill /> Hapus
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <Kosong />
                            )}
                        </tbody>

                    </table>
                </div>



            </LayoutDashboard>
        </div>
    )
}
