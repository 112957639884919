import { PDFViewer } from "@react-pdf/renderer"
import PdfDataDiri from "./DataDiri"
import TempDataDiri from "./PrintDataDiri"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export const TemplateDataDiri = () => {
  const { id } = useParams();
  const [student, setStudent] = useState([]);
  const [user, setUser] = useState(() => {
      let userProfile = localStorage.getItem("userProfile");
      if (userProfile) {
          return JSON.parse(userProfile);
      }
      return null;
  });

  const [studentId, setStudentId] = useState(() => {
      if (typeof id === 'undefined') {
          return user?._id
      } else {
          return id
      }
  })

  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  const getStudent = async () => {
      try {
        const response = await axios.get(`${apiEndpoint}/dashboard/student/${studentId}`, {
          headers: {
            'ngrok-skip-browser-warning': 'true',
          },
        });
        setStudent(response.data.student || []);
      } catch (error) {
        console.log("Terjadi kesalahan pada server");
      } 
  
  };

  useEffect(()=> {
    getStudent();
  }, [])


    return (
        <PDFViewer width={'100%'} height={'750px'}>
          <TempDataDiri student={student} />
        </PDFViewer>
    )
}