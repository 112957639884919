import React from 'react'

export const Information = (props) => {
  return (
    <div>
      <h2 style={{
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '40px',
        fontSize: '16px',
        marginBottom: '20px'
      }}>
        <span style={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          borderRadius: '5px',
          height: '8px',
          width: '30px',
          backgroundColor: '#1C367E',
          display: 'inline-block',
          float: 'left',
          marginRight: '20px'
        }}></span>
        Masukan data {props.title} apabila anda menerima {props.title}
      </h2>

    </div>
  )
}
