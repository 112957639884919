import React, { useState, useEffect, useContext } from 'react';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import { RombelContext } from '../../../../context/RombelContext';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const EditRombelPage = () => {
    const { rombelId } = useParams();
    const { rombels } = useContext(RombelContext)
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        nama_rombel: ''
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {

    }, [rombelId, rombels]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'Wajib diisi' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, String(value).trim() ? '' : 'Wajib diisi'])
        );
        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${apiEndpoint}/update-rombel/${rombelId}`, formData);
                Swal.fire({
                    title: 'Sukses!',
                    text: 'Berhasil Update Data',
                    icon: 'success',
                }).then(() => navigate('/admin/rombel'));
            } catch (error) {
                console.error('Error updating data:', error);
                Swal.fire({
                    title: 'Error!',
                    text: 'Gagal Update Data. Silakan coba lagi.',
                    icon: 'error',
                });
            }
        }
    };

    return (
        <LayoutDashboard>
            <GelBreadcrumb satu="Dashboard" dua="Edit Data Rombel" tiga="Master Data" empat="Rombel" urlEmpat="/admin/rombel" urlSatu="/admin" />
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className='card-box shadow-sm p-4 mb-30'>
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor='nama_rombel' className='form-label'>Nama Rombel :</label>
                            <input type='text'
                                className={`form-control ${errors.nama_rombel ? 'is-invalid' : ''}`}
                                value={formData.nama_rombel}
                                onChange={(e) => handleChange(e)}
                                name='nama_rombel'
                            />
                            {errors.nama_rombel && <p style={{ color: "red" }}>{errors.nama_rombel}</p>}
                        </div>
                    </div>


                    <button type="submit" className="btn btn-primary mt-3">Submit</button>
                </div>
            </form>
        </LayoutDashboard>
    )
};

