import React, { useEffect, useState } from 'react'
import GelBreadcrumb from '../../components/globalcomp/GelBreadcrumb'
import LayoutDashboard from '../../layouts/LayoutDashboard'
import axios from 'axios';
import { Kosong } from '../../components/globalcomp/Kosong';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const ShowAccPage = () => {

  const [getAcc, setAcc] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 20;

  const filtersStudents = getAcc.filter((item) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const getAccRole = async () => {
    try {
      const response = await axios.get(`${apiEndpoint}/get-all-users`, {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      });
      setAcc(response.data.user || []);
    } catch (error) {
      console.log("Terjadi kesalahan pada server");
    }

  };

  useEffect(() => {
    getAccRole()
  }, [])

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filtersStudents.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <LayoutDashboard>
      <GelBreadcrumb satu="Home" dua="Konfigurasi Akun" urlSatu="/dashboard/petunjuk" />
      <div className='card-box shadow-sm p-4'>
        <div className='d-flex justify-content-between'>
          <div>

          </div>
          <div>
            <input
              type="search"
              className='form-control'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Cari nama Email..."
            />
          </div>
        </div>
        <table className="table mt-3  table-borderless">
          <thead className='thead-dark  custom-header'>
            <tr>
              <th>#</th>
              <th>Role</th>
              <th>Email</th>
              <th>Nama</th>
            </tr>
          </thead>
          <tbody>
            {getAcc && getAcc.length > 0 ? (
              
              currentItems.map((item, index) => (
                <tr key={item.id}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>
                    {item.role === 'student' ?
                      <span className='badge badge-success'>{item.role}</span> :
                      ""}
                    {item.role === 'guru' ?
                      <span className='badge badge-warning'>{item.role}</span> :
                      ""}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.username}</td>
                </tr>

              ))

            ) : (
              <Kosong />
            )}
          </tbody>

        </table>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <button className="page-link" onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
            </li>
            {Array.from({ length: Math.ceil(filtersStudents.length / itemsPerPage) }).map((_, index) => {
              if (Math.abs(currentPage - (index + 1)) <= 4 || index === 0 || index === Math.ceil(filtersStudents.length / itemsPerPage) - 1) {
                return (
                  <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => paginate(index + 1)}>{index + 1}</button>
                  </li>
                );
              } else if (Math.abs(currentPage - (index + 1)) === 5 && Math.ceil(filtersStudents.length / itemsPerPage) > 10) {
                return <li key={index} className="page-item disabled"><span className="page-link">...</span></li>;
              }
            })}
            <li className="page-item">
              <button className="page-link" onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(filtersStudents.length / itemsPerPage)}>Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </LayoutDashboard >
  )
}

export default ShowAccPage

