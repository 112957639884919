import React, { useEffect, useContext, useState } from 'react';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import { useParams } from 'react-router-dom';
import axios from 'axios';



export const DetailRayonPage = () => {
  const id = useParams()
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT

  const { detailRayon, setDetailRayon } = useState([]);

  useEffect(() => {
    const getDetailRayon = async () => {
      try {
        const response = await axios.get(`${apiEndpoint}/rayon/${id?.id}`, {
          headers: {
            'ngrok-skip-browser-warning': 'true',
          },
        });
        setDetailRayon(response);
      } catch (error) {
        console.log("Terjadi kesalahan pada server");
      }
  
    };
    getDetailRayon()
  }, [id])





  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Master Data" dua="Rayon" urlSatu="/admin/rayon" />
        <div className='card-box shadow-sm p-4'>

        </div>
      </LayoutDashboard>
    </div>
  );
}

