import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { BsPencil, BsTrashFill } from 'react-icons/bs'
import { Kosong } from '../../../../components/globalcomp/Kosong'
import { Information } from '../../../../components/globalcomp/Information'
import { confirmDelete } from '../../../../function/delete'

export const ShowInpanssing = () => {
    document.title = "Data Inpassing";
    const { gtk, getGtk } = useContext(GtkContext)
    const [getInpassing, setInpassing] = useState([])
    useEffect(() => {
        setInpassing(gtk?.gtk?.inpassing_id)
    }, [gtk.gtk])



    useEffect(() => {
        getGtk()
    }, [])
    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" tiga="Lainnya" urlSatu="/dashboard/pembimbing" dua="Data Inpassing" />
                <Information title="inpassing" />
                <div className='card-box shadow-sm p-4 mb-30'>
                    <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/inpassing/create">
                            <button type="button" className="btn btn-primary bg-blue text-shite mb-2">Tambah Data Inpassing</button>
                        </Link>
                    </div>
                    <table className="table table-borderless">
                        <thead className='thead-dark'>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">No SK</th>
                                <th scope="col">Tanggal SK</th>
                                <th scope="col">TMT SK</th>
                                <th scope="col">Angka Kredit</th>
                                <th scope="col">Masa Kerja Tahun</th>
                                <th scope="col">Masa Kerja Bulan</th>
                                <th scope="col">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getInpassing && getInpassing.length > 0 ? (
                                getInpassing.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.no_sk}</td>
                                        <td>{item.tanggal_sk}</td>
                                        <td>{item.tmt_sk}</td>
                                        <td>{item.angka_kridit}</td>
                                        <td>{item.masa_kerja_thn}</td>
                                        <td>{item.masa_kerja_bln}</td>
                                        <td className='d-flex '>
                                            <Link to={`/dashboard/pembimbing/inpassing/edit/${item._id}`}>
                                                <button className='btn btn-warning btn-sm text-white'>
                                                    <BsPencil /> Ubah
                                                </button>
                                            </Link>
                                            <button
                                                className='btn btn-danger ml-3 btn-sm'
                                                onClick={() => confirmDelete(item._id, 'inpassing/delete', getGtk)}
                                            >
                                                <BsTrashFill /> Hapus
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <Kosong />

                            )}
                        </tbody>

                    </table>
                </div>
            </LayoutDashboard>
        </div>
    )
}
