import React, { useContext } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import AllGtkContext from '../../../../context/AllGtkContext'

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/gtk";

const initialValues = {
    nama_lengkap: '',
    nik: '',
    jk: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    agama: '',
    email: '',
    no_telp: '',
    nip: '',
}
export const CreateGtk = () => {
    const { AllGetGtk } = useContext(AllGtkContext)

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const onSubmit = (values) => {
        Swal.fire({
            title: 'Apakah data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya, kirim!',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then((confirmed) => {
            if (confirmed.isConfirmed) {
                Swal.fire({
                    title: 'Mohon tunggu...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                axios.post(apiEndpoint, values)
                    .then((response) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Data berhasil disimpan!',
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        setTimeout(() => {
                            AllGetGtk()
                            navigate('/admin/show/gtk');
                        }, 2000);
                    })
                    .catch((error) => {
                        setErrorMessage(error.response.data.error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: error.response.data.error,
                        });
                    });
            }
        });
    };

    const validationSchema = Yup.object({
        nama_lengkap: Yup.string().required('Required'),
        nik: Yup.string().required('Required'),
        jk: Yup.string().required('Required'),
        tempat_lahir: Yup.string().required('Required'),
        tanggal_lahir: Yup.string().required('Required'),
        agama: Yup.string().required('Required'),
        email: Yup.string().email('Invalid Email Format').required('Required'),
        no_telp: Yup.number().required('Required'),
        nip: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });
    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Buat Data Gtk" tiga="Data Gtk" />
                <form onSubmit={formik.handleSubmit}>
                    <div className='card-box shadow-sm p-4 mb-30'>
                        <div>
                            {errorMessage && <span className='alert alert-danger'>{errorMessage}</span>}
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='nama_lengkap' className='form-label'>Nama Lengkap :</label>
                                    <input type='text'
                                        className={`form-control ${formik.touched.nama_lengkap && formik.errors.nama_lengkap ? "is-invalid" : ""}`}
                                        name='nama_lengkap'
                                        id='nama_lengkap'
                                        {...formik.getFieldProps("nama_lengkap")}
                                    />
                                    {formik.touched.nama_lengkap && formik.errors.nama_lengkap ? <span className='text-danger'>{formik.errors.nama_rayon}</span> : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='jk' className='form-label'>Jenis Kelamin :</label>
                                <select
                                    name="jk"
                                    id='jk'
                                    className={`form-select ${formik.touched.jk && formik.errors.jk ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("jk")}
                                >
                                    <option value="">Pilih JK</option>
                                    <option value="P">P</option>
                                    <option value="L">L</option>
                                </select>
                                {formik.touched.jk && formik.errors.jk ? <span className='text-danger'>{formik.errors.jk}</span> : null}
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className='form-label' htmlFor='tempat_lahir'>Tempat Lahir</label>
                                    <input type='text'
                                        className={`form-control ${formik.touched.tempat_lahir && formik.errors.tempat_lahir ? "is-invalid" : ""}`}
                                        name='tempat_lahir'
                                        {...formik.getFieldProps("tempat_lahir")}
                                    />
                                    {formik.touched.tempat_lahir && formik.errors.tempat_lahir ? <span className='text-danger'>{formik.errors.tempat_lahir}</span> : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className='form-label'>Tanggal Lahir</label>
                                <input type='date'
                                    className={`form-control ${formik.touched.tanggal_lahir && formik.errors.tanggal_lahir ? "is-invalid" : ""}`}
                                    name='tanggal_lahir'
                                    id='tanggal_lahir'
                                    {...formik.getFieldProps("tanggal_lahir")}
                                />
                                {formik.touched.tanggal_lahir && formik.errors.tanggal_lahir ? <span className='text-danger'>{formik.errors.tanggal_lahir}</span> : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='agama' className='form-label'> Agama :</label>
                                    <select
                                        name="agama"
                                        className={`form-select ${formik.touched.agama && formik.errors.agama ? "is-invalid" : ""}`}
                                        id='agama'
                                        {...formik.getFieldProps("agama")}
                                    >
                                        <option value="" disabled>Pilih Agama</option>
                                        <option value="Islam">Islam</option>
                                        <option value="Kristen Protestan">Kristen Protestan</option>
                                        <option value="Kristen Katholik">Kristen Katholik</option>
                                        <option value="Hindu">Hindu</option>
                                        <option value="Budha">Budha</option>
                                        <option value="Konghuchu">Konghuchu</option>

                                    </select>
                                    {formik.touched.agama && formik.errors.agama ? <span className='text-danger'>{formik.errors.agama}</span> : null}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className='form-label' htmlFor='nik'>NIK</label>
                                <input type='text'
                                    className={`form-control ${formik.touched.nik && formik.errors.nik ? "is-invalid" : ""}`}
                                    name='nik'
                                    id='nik'
                                    {...formik.getFieldProps("nik", {
                                        value: String(formik.values.nik)
                                    })}
                                />
                                {formik.touched.nik && formik.errors.nik ? <span className='text-danger'>{formik.errors.nik}</span> : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className='form-label'>Email</label>
                                    <input type='email'
                                        className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
                                        name='email'
                                        id='email'
                                        {...formik.getFieldProps("email")}
                                    />
                                    {formik.touched.email && formik.errors.email ? <span className='text-danger'>{formik.errors.email}</span> : null}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className='form-label' htmlFor='no_telp'>No Telephone</label>
                                <input type='text'
                                    className={`form-control ${formik.touched.no_telp && formik.errors.no_telp ? "is-invalid" : ""}`}
                                    name='no_telp'
                                    id='no_telp'
                                    {...formik.getFieldProps("no_telp", {
                                        value: String(formik.values.no_telp)
                                    })} />
                                {formik.touched.no_telp && formik.errors.no_telp ? <span className='text-danger'>{formik.errors.no_telp}</span> : null}
                            </div>
                            <div className="col-md-6">
                                <label className='form-label' htmlFor='NIP'>NIP</label>
                                <input type='text'
                                    className={`form-control ${formik.touched.nip && formik.errors.nip ? "is-invalid" : ""}`}
                                    name='nip'
                                    id='nip'
                                    {...formik.getFieldProps("nip", {
                                        value: String(formik.values.nip)
                                    })}
                                />
                                {formik.touched.nip && formik.errors.nip ? <span className='text-danger'>{formik.errors.nip}</span> : null}
                            </div>

                        </div>


                        <div className='d-flex justify-content-between'>
                            <div></div>
                            <button type='submit' className='btn btn-primary bg-blue text-white  mt-3'>Buat Data Baru</button>
                        </div>
                    </div>
                </form>
            </LayoutDashboard>
        </div >
    )
}
