import React from 'react';

const ModalImport = ({ onSubmit, onChange, title, id }) => {
  return (
    <div className="modal fade" id={id} tabindex="-1" role="dialog" aria-labelledby="file-exportLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="file-exportLabel">{title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={onSubmit} className='form-group mx-3 my-3'>
            <input type="file" id="file" className='form-control mb-3' onChange={onChange} />
            <button type='submit' className='btn bg-blue text-white'>Import Data</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalImport;
