import React, { useContext, useEffect } from 'react'
import Logo1 from "../assets/new-sidata.png"
import { Link, useLocation, } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import Swal from 'sweetalert2'
import Foto from '../assets/pfp2.jpg'
import { useState } from 'react'
import { BiSolidDashboard } from 'react-icons/bi'
import axios from 'axios'

function LayoutDashboard({ children }) {
    const { user, logoutAPICall } = useContext(AuthContext)
    const popup = async () => {
        try {
            const result = await Swal.fire({
                title: 'Logout',
                text: 'Apakah anda yakin ingin keluar?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, Keluar',
                cancelButtonText: 'Batal',
            });

            if (result.isConfirmed) {
                const loadingAlert = Swal.fire({
                    title: 'Logging out...',
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    },
                    showConfirmButton: false,
                    timer: 2000,
                });

                await logoutAPICall();

                loadingAlert.close();

                Swal.fire('Logged Out!', 'You have been logged out.', 'success');
            }
        } catch (error) {
            console.log('Error during logout:', error);
            Swal.fire('Error', 'Logout failed. Please try again later.', 'error');
        }


    }
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const [isAnotherDropdownOpen, setAnotherDropdownOpen] = useState(false);

    const handleAnotherDropdownToggle = () => {
        setAnotherDropdownOpen(!isAnotherDropdownOpen);
    };

    const [isOtherDropdownOpen, setOtherDropdownOpen] = useState(false);

    const handleOtherDropdownToggle = () => {
        setOtherDropdownOpen(!isOtherDropdownOpen);
    };

    const location = useLocation();
    useEffect(() => {
        document.body.classList.toggle('sidebar-shrink', !isSidebarOpen);
        document.querySelector('.left-side-bar').classList.toggle('open', isSidebarOpen);
        document.querySelector('.mobile-menu-overlay').classList.toggle('show', isSidebarOpen);
    }, [isSidebarOpen]);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };
    const styles = {
        backgroundRadialGradient: {
            backgroundImage: `radial-gradient(650px circle at 0% 0%,
            hsl(218, 41%, 35%) 15%,
            hsl(218, 41%, 30%) 35%,
            hsl(218, 41%, 20%) 75%,
            hsl(218, 41%, 19%) 80%,
            transparent 100%),
            radial-gradient(1250px circle at 100% 100%,
            hsl(218, 41%, 45%) 15%,
            hsl(218, 41%, 30%) 35%,
            hsl(218, 41%, 20%) 75%,
            hsl(218, 41%, 19%) 80%,
            transparent 100%)`,
        },


    };

    const [password, setPassword] = useState('');

    const handlePassChange = (e) => {
        const { name, value } = e.target;
        setPassword(value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await Swal.fire({
            title: "Konfirmasi",
            text: "Apakah Anda yakin mengganti password?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Tidak",
        });

        if (result.isConfirmed) {
            try {
                await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/change-password/${user?._id}`, {'password': password});
                Swal.fire({
                    title: "Sukses!",
                    text: "Berhasil Update Password",
                    icon: "success",
                }).then(() => {
                    window.location.reload();
                });
            } catch (error) {
                console.log(error, "error ini");
            }
        }
    };

    return (
        <>
        
            <div class="modal fade" id="updatePassword" tabindex="-1" role="dialog" aria-labelledby="updatePasswordLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="updatePasswordLabel">Masukan Password Baru</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form className='form-group mx-3 my-3' onSubmit={handleSubmit}>
                            <input type="text" id='password' className='form-control mb-3' onChange={handlePassChange} />
                            {/* <input type="file" id="file" className='form-control mb-3'/> */}
                            <button type='submit' className='btn bg-blue text-white' onSu>Ubah Password</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="header shadow-sm" style={styles.backgroundRadialGradient}>
                <div className="header-left">
                    <button className="menu-icon btn bi bi-list text-white" onClick={toggleSidebar}></button>
                </div>
                <div className="header-right">
                    <div className="user-info-dropdown">
                        <div className="dropdown">
                            <Link className="dropdown-toggle d-flex justify-content-center align-items-center " style={{ gap: '4px' }} href="#" role="button" data-toggle="dropdown">
                                <span className="user-name text-white   ">{user.username}</span>
                                <span className="user-icon shadow-none">
                                    <img src={Foto} alt="" style={{ maxWidth: '45px', marginBottom: '5px' }} />
                                </span>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <Link className="dropdown-item" to="/dashboard/profile"><i className="dw dw-user1"></i> Profile</Link>
                                {
                                    user.role === 'student' ? (
                                        <div>
                                            <button type="button" className="dropdown-item" data-toggle="modal" data-target="#updatePassword">
                                                <i className="dw dw-note1"></i>
                                                Update Password
                                            </button>
                                        </div>
                                    ) : ''
                                }
                                {/* <Link className="dropdown-item" to="/dashboard/profile"><i className="dw dw-user1"></i> Update Password</Link> */}
                                <Link className="dropdown-item" onClick={popup}><i className="dw dw-logout"></i> Log Out</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className={`left-side-bar ${isSidebarOpen ? 'open' : ''}`}>
                <div className="brand-logo">
                    <a href="">
                        <img src={Logo1} style={{ padding: '20px', marginLeft: '-28px' }} alt="" className="light-logo" />
                    </a>
                    <div className="close-sidebar" data-toggle="left-sidebar-close">
                        <i className="ion-close-round"></i>
                    </div>
                </div>
                <div className="menu-block customscroll" style={{ maxHeight: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
                    <div className="sidebar-menu">
                        <ul id="accordion-menu" style={{ paddingLeft: 0 }}>

                            {
                                user.role === "admin" ?
                                    <li>
                                        <Link to="/admin" className={`dropdown-toggle no-arrow ${location.pathname === "/admin" ? "active" : ""}`}>
                                            <BiSolidDashboard  className='icon-copy micon'/> <span className="mtext">Dashboard</span>
                                        </Link>
                                    </li> : ""
                            }

                            {
                                user.role === "student" ?
                                    <li>
                                        <Link to="/dashboard/petunjuk" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/petunjuk" ? "active" : ""}`}>
                                            <span className="micon bi bi-house"></span><span className="mtext">Petunjuk</span>
                                        </Link>
                                    </li> : ""
                            }


                            {
                                user.role === "admin" ? <li>
                                    <Link to="/admin/dashboard/student" className={`dropdown-toggle no-arrow ${location.pathname === "/admin/dashboard/student" || location.pathname === "/dashboard/student/create" || location.pathname.startsWith('/dashboard/student/detail/') ? "active" : ""}`}>
                                        <span className="bi bi-people-fill micon"></span><span className="mtext">Data Siswa</span>
                                    </Link>
                                    
                                </li> : ""
                            }

                            {
                                user.role === "admin" ? <li>
                                    <Link to="/admin/show/gtk" className={`dropdown-toggle no-arrow ${location.pathname === "/admin/show/gtk" ? "active" : ""}`}>
                                        <span className="bi bi-person-vcard-fill micon"></span><span className="mtext">Data GTK</span>
                                    </Link>
                                </li> : ""


                            }

                            {
                                user.role === "student" ? <li>
                                    <Link to="/dashboard/data-diri" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/data-diri" || location.pathname === "/dashboard/update/data-siswa" || location.pathname === "/dashboard/update/data-keluarga" || location.pathname === "/dashboard/update/data-alamat" ? "active" : ""}`}>
                                        <i className="icon-copy micon bi bi-person-circle"></i><span className="mtext">Data Diri</span>
                                    </Link>
                                </li>
                                    : ""
                            }
                            {
                                user.role === "student" ?
                                    <li>
                                        <Link to="/dashboard/status" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/status" ? "active" : ""}`}>
                                            <span className="micon bi  bi-exclamation-circle"></span><span className="mtext">Status</span>
                                        </Link>
                                    </li> : ""
                            }

                            {
                                user.role === "guru" ?
                                    <>
                                        <li>
                                            <Link to="/dashboard/pembimbing" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing" ? "active" : ""}`}>
                                                <i className="icon-copy micon bi bi-person-circle"></i><span className="mtext">Dashboard</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/dashboard/pembimbing/siswa" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/siswa" ? "active" : ""}`}>
                                                <i className="icon-copy bi bi-people-fill micon"></i><span className="mtext">Data Siswa</span>
                                            </Link>
                                        </li>

                                        <li className={`dropdown ${isAnotherDropdownOpen ? 'show' : ''}`}>
                                            <div onClick={handleAnotherDropdownToggle} className="dropdown-toggle cursor-pointer">
                                                <span className="bi bi-database-fill-check micon"></span>
                                                <span className="">Data Pribadi</span>
                                            </div>
                                            <ul className="submenu" id="submenu" style={{ display: isAnotherDropdownOpen ? 'block' : 'none' }}>
                                                <li><Link to="/dashboard/pembimbing/data-diri/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Identitas Pendidik</Link></li>
                                                <li><Link to="/dashboard/pembimbing/anak/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Data Anak</Link></li>

                                            </ul>
                                        </li>

                                        <li className={`dropdown ${isDropdownOpen ? 'show' : ''}`}>
                                            <div onClick={handleDropdownToggle} className="dropdown-toggle cursor-pointer">
                                                <span className="bi bi-person-vcard-fill micon"></span>
                                                <span className="mtext">Kepegawaian</span>
                                            </div>
                                            <ul className="submenu" id="submenu" style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                                                <li><Link to="/dashboard/pembimbing/kepegawaian/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Kepegawaian</Link></li>
                                                <li><Link to="/dashboard/pembimbing/jabatan/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Jabatan</Link></li>
                                                <li><Link to="/dashboard/pembimbing/gaji/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Gaji</Link></li>
                                                <li><Link to="/dashboard/pembimbing/tunjangan/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Tunjangan</Link></li>
                                            </ul>
                                        </li>

                                        <li className={` dropdown ${isOtherDropdownOpen ? 'show' : ''}`} >
                                            <div onClick={handleOtherDropdownToggle} className="dropdown-toggle cursor-pointer">
                                                <span className="micon bi bi-bookmarks-fill"></span>
                                                <span className="mtext">Lainnya</span>
                                            </div>
                                            <ul className="submenu" id="submenu" style={{ display: isOtherDropdownOpen ? 'block' : 'none' }}>
                                                <li><Link to="/dashboard/pembimbing/pendidikan/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Pendidikan</Link></li>
                                                <li><Link to="/dashboard/pembimbing/beasiswa/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Beasiswa</Link></li>
                                                <li><Link to="/dashboard/pembimbing/sertifikasi/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Sertifikasi Pendidik</Link></li>
                                                <li><Link to="/dashboard/pembimbing/diklat" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Diklat</Link></li>
                                                <li><Link to="/dashboard/pembimbing/penugasan/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Penugasan</Link></li>
                                                <li><Link to="/dashboard/pembimbing/tugas-tambahan/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Tugas Tambahan</Link></li>
                                                <li><Link to="/dashboard/pembimbing/penghargaan/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Penghargaan</Link></li>
                                                <li><Link to="/dashboard/pembimbing/inpassing/show" className={`dropdown-toggle no-arrow ${location.pathname === "/dashboard/pembimbing/guru" ? "active" : ""}`}>Inpassing</Link></li>
                                            </ul>
                                        </li>

                                    </>
                                    : ""
                            }


                            {
                                user.role === "admin" ?
                                    <li className={`dropdown ${isDropdownOpen || location.pathname === "/admin/rayon" || location.pathname === "/admin/rombel" ? 'show' : ''}`}>
                                        <div onClick={handleDropdownToggle} className="dropdown-toggle cursor-pointer">
                                            <span className="bi bi-book micon"></span>
                                            <span className="mtext">Master Data</span>
                                        </div>
                                        <ul className="submenu" id="submenu" style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                                            <li><Link to="/admin/rayon/show" className={`dropdown-toggle no-arrow ${location.pathname === "/admin/rayon" ? "active" : ""}`}>Rayon</Link></li>
                                            <li><Link to="/admin/rombel" className={`dropdown-toggle no-arrow ${location.pathname === "/admin/rombel" ? "active" : ""}`}>Rombel</Link></li>
                                        </ul>
                                        <Link to="/admin/show-acc" className={`dropdown-toggle no-arrow ${location.pathname === "/admin/show-acc" ? "active" : ""}`}>
                                        <span className="bi bi-person-fill-check micon"></span><span className="mtext">Konfigurasi Akun</span>
                                    </Link>   
                                    </li> : ""   
                            }

                        </ul>
                    </div>
                </div>
            </div>
            <div className="mobile-menu-overlay"></div>
            <div className={`main-container background-inti ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
                <div className="xs-pd-20-10 mb-5  pd-ltr-20">
                    {children}
                </div>

            </div>

        </>
    )
}

export default LayoutDashboard