import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { Link } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { BsPencil, BsTrash } from 'react-icons/bs'
import Swal from 'sweetalert2'
import axios from 'axios'
import { Information } from '../../../../components/globalcomp/Information'

export const Kepegawaian = () => {

    const { gtk } = useContext(GtkContext)
    const [getKepegawaian, setKepegawaian] = useState([])


    useEffect(() => {
        setKepegawaian(gtk.gtk?.kepegawaian_id)
        console.log(getKepegawaian, "ini kepa")
    }, [gtk.gtk])


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Data Kepegawaian" />
                <Information title="kepegawaian" />


                <div className='card-box shadow-sm p-4 mb-30'>

                <div className='mb-20'>
                        <Link to="/dashboard/pembimbing/kepegawaian/create">
                            <button type="button" className="btn bg-blue text-white btn-primary mb-2">+ Tambah Data Kepegawaian</button>
                        </Link>
                    </div>

                    <div className='mb-20'>
                        {
                            getKepegawaian && getKepegawaian.jenis_ptk && getKepegawaian.status_kepegawaian === null ? (
                                <Link to="/dashboard/pembimbing/kepegawaian/create">
                                    <button style={{ marginBottom: '30px' }} type="button" className="btn btn-primary bg-blue text-white mb-2">
                                        Buat Data Kepegawaian
                                    </button>
                                </Link>
                            ) : (
                                ""
                            )
                        }

                    </div>
                    <table className="table table-borderless">
                        <thead className='thead-dark'>
                            <tr>
                                <th scope="col">Status Kepegawaian</th>
                                <th scope="col">NIY/NIGK</th>
                                <th scope="col">NUPTK</th>
                                <th scope="col">Jenis PTK</th>
                                <th scope="col">SK Pengangkatan</th>
                                <th scope="col">TMT Pengangkatan</th>
                                <th scope="col">Lembaga Pengangkat</th>
                                <th scope="col">Sumber Gaji</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <tr >
                                    <td>{getKepegawaian?.status_kepegawaian}</td>
                                    <td>{getKepegawaian?.niy_nigk}</td>
                                    <td>{getKepegawaian?.nuptk}</td>
                                    <td>{getKepegawaian?.jenis_ptk}</td>
                                    <td>{getKepegawaian?.sk_pengangkatan}</td>
                                    <td>{getKepegawaian?.tmt_pengangkatan}</td>
                                    <td>{getKepegawaian?.lembaga_pengangkat}</td>
                                    <td>{getKepegawaian?.sumber_gaji}</td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>



            </LayoutDashboard>
        </div>
    )
}
