import React, { useEffect } from 'react'
import LayoutDashboard from '../../layouts/LayoutDashboard'
import Banner from "../../assets/images/banner8.png"
import { Link } from 'react-router-dom';

export const PetunjukPage = () => {

    useEffect(() => {
        document.title = "Petunjuk";
    }, []);

    return (
        <div>

            <LayoutDashboard>

                <div className='card-box shadow-sm p-4 mb-4'>
                    <img src={Banner} alt="" style={{ borderRadius: '10px' }} />
                </div>

                <div>
                    <h2 style={{
                        position: 'relative',
                        display: 'inline-block',
                        paddingLeft: '40px',
                        fontSize: '16px',
                        fontWeight: 'bold'

                    }}>
                        <span style={{
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            borderRadius: '5px',
                            height: '8px',
                            width: '30px',
                            backgroundColor: '#1C367E',
                            display: 'inline-block',
                            float: 'left',
                            marginRight: '20px'
                        }}></span>
                        Silakan baca petunjuk dibawah ini dengan seksama!
                    </h2>
                    <p style={{ fontSize: '14px', paddingLeft: '40px' }}>Isilah bersama orangtua atau didampingi oleh orangtua.</p>
                </div>

                <div className="card-box shadow-sm p-5">
                    <h3 style={{ color: '#1C367E', fontWeight: 'bold', fontSize: '20px' }}>SISTEM INFORMASI MANAJEMEN PENDATAAN </h3>
                    <div className='w-full'>
                        <ol type="1" style={{listStyle: "decimal outside"}} className="ml-10 space-y-1 text-gray-500 dark:text-gray-400">
                            <li>
                                Siapkan dokumen berikut ini:
                                <ul className="pl-5 mt-2 space-y-1 list-disc ">
                                    <li className='list-style-student'>
                                        Ijazah SD
                                    </li>
                                    <li className='list-style-student'>
                                        Ijazah SMP
                                    </li>
                                    <li className='list-style-student'>
                                        Akte Kelahiran
                                    </li>
                                    <li className='list-style-student'>
                                        Kartu Keluarga
                                    </li>
                                    <li className='list-style-student'>
                                        KTP Ayah
                                    </li>
                                    <li className='list-style-student'>
                                        KTP Ibu
                                    </li>
                                </ul>
                            </li>
                            <br />
                            <li >
                                Scan semua dokumen ke dalam bentuk .JPG /.JPEG atau .pdf ukuran maksimal masing-masing file adalah 2MB menggunakan Aplikasi Camscanner (<a target='_blank' href='https://play.google.com/store/apps/details?id=com.intsig.camscanner'>https://play.google.com/store/apps/details?id=com.intsig.camscanner</a>) atau aplikasi sejenis lainnya yang sesuai. 
                            </li>
                            <li>
                                Duduk bersama dengan orang tua untuk mengisi dan melakukan verifikasi juga validasi data. Login menggunakan username dan password masing-masing.
                                <div>
                                    <b>Username : email.sch, Password :  nis</b>
                                </div>
                            </li>
                            <li>
                                Isi dan sesuaikan semua <b>DATA DIRI SISWA, KELUARGA, DAN UPLOAD DOKUMEN</b> sesuai dengan dokumen yang sudah disiapkan. <b>Acuan data utamanya adalah ke Ijazah SD, Ijazah SMP, Akte Kelahiran dan Kartu Keluarga.</b> Jika ada yang tidak sesuai silahkan koordinasikan ke pembimbing siswa atau ke Pak Acep.
                            </li>
                            <li>
                                Sebelum <b>KLIK SIMPAN DAN PERSETUJUAN</b> data pastikan data yang sudah diisikan ke website sidata sesuai dengan <b>Dokumen Acuan</b>. Data yang sudah disimpan dan disetujui tidak bisa dirubah kembali tapi jika ada alasan tertentu silahkan koordinasikan ke pembimbing siswa atau ke Pak Acep.
                            </li>
                            <li>
                                Jika sudah selesai melakukan pengisian, verifikasi dan validasi silahkan lapor ke pembimbing siswa masing-masing supaya pembimbing siswa melakukan approve data.
                            </li>
                            <li>
                            Jika ada hal-hal terkait teknis penggunaan website sidata bisa menghubungi Pak Acep(<a target='_blank' href='https://wa.me/628569000223'>https://wa.me/628569000223</a>). Terima kasih.
                            </li>
                        </ol>

                    </div>
                    <Link to="/dashboard/data-diri">
                        <button className='btn btn-primary rounded-sm ml-4 bg-blue'>Lengkapi Data Diri</button>
                    </Link>
                </div>



            </LayoutDashboard>
        </div>
    )
}

export default PetunjukPage