import React, { useContext, useState } from 'react'
import LogoSidata from "../../assets/new-sidata.png"
import LogoSekolah from "../../assets/logowikrama.png"
import AuthContext from '../../context/AuthContext';
import { Link } from 'react-router-dom';

export const LandingPage = () => {
  const { user } = useContext(AuthContext)

  const headerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
  };
  const [activeLink, setActiveLink] = useState('hero');

  const handleLinkClick = (target) => {
    setActiveLink(target);
  };

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleToggleNav = () => {
    setIsNavOpen((prevState) => !prevState);
  };
  return (
    <>

      <header id="header" className="fixed-atas" style={headerStyle}>
        <div className="container d-flex align-items-center">

          <a href="/" className="logo me-auto">
            <img src={LogoSekolah} style={{ maxWidth: "200px", marginRight: "10px" }} alt="" className="img-fluid" />
            <img src={LogoSidata} style={{ maxWidth: "200px" }} alt="" className="img-fluid" />
          </a>
          <nav
            id="navbar"
            className={`navbar order-last order-lg-0 ${isNavOpen ? 'navbar-mobile' : ''}`}
          >            <ul>
              <li>
                <a
                  className={`nav-link scrollto ${activeLink === 'hero' ? 'active' : ''}`}
                  href="#hero"
                  onClick={() => handleLinkClick('hero')}
                >
                  Beranda
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${activeLink === 'about' ? 'active' : ''}`}
                  href="#about"
                  onClick={() => handleLinkClick('about')}
                >
                  Tentang Kami
                </a>
              </li>
              <li>
                <a
                  className={`nav-link scrollto ${activeLink === 'contact' ? 'active' : ''}`}
                  href="#contact"
                  onClick={() => handleLinkClick('contact')}
                >
                  Kontak
                </a>
              </li>
            </ul>
            <i onClick={handleToggleNav} className={`bi bi-list mobile-nav-toggle ${isNavOpen ? 'bi-x' : ''}`}></i>

          </nav>

          {user ?
            <div className="ml-auto">
              {
                user.role == "admin" ? (<a href="/admin" className="appointment-btn scrollto">Dashboard</a>) : user.role === "guru" ? (<a href="/dashboard/pembimbing" className="appointment-btn scrollto">Dashboard</a>) : <a href="/dashboard/petunjuk " className="appointment-btn scrollto">Dashboard</a>

              }
            </div> : <div className="ml-auto">
              <a href="/login" className="appointment-btn scrollto">Sign In</a>
            </div>
          }

        </div>
      </header>

      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <h1>Selamat Datang di SiData</h1>
          <h2>Solusi terbaik untuk mengelola dan mengakses data di SMK Wikrama.</h2>
        </div>
      </section>

      <main id="main">


        <section id="why-us" className="why-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 d-flex align-items-stretch">
                <div className="content">
                  <h3>Kenapa pilih SiData?</h3>

                  Kami berkomitmen untuk memberikan solusi yang inovatif dan efektif untuk kebutuhan pengelolaan data sekolah, menyediakan Anda dengan akses mudah ke berbagai informasi penting yang berkaitan siswa, guru, dan sekolah.

                </div>
              </div>
              <div className="col-lg-8 d-flex align-items-stretch">
                <div className="icon-boxes d-flex flex-column justify-content-center">
                  <div className="row">
                    <div className="col-xl-4 d-flex align-items-stretch">
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-receipt"></i>
                        <h4>Akses Mudah</h4>
                        <p>Memberikan kesempatan untuk mendapatkan data dengan cepat dan tanpa hambatan</p>
                      </div>
                    </div>
                    <div className="col-xl-4 d-flex align-items-stretch">
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-cube-alt"></i>
                        <h4>Kolaborasi</h4>
                        <p>Memberikan fasilitas kolaborasi yang lancar antara berbagai siswa, guru, dan administrasi</p>
                      </div>
                    </div>
                    <div className="col-xl-4 d-flex align-items-stretch">
                      <div className="icon-box mt-4 mt-xl-0">
                        <i className="bx bx-images"></i>
                        <h4>Efisien</h4>
                        <p>Menawarkan solusi yang dapat menghemat waktu dan upaya dengan pengelolaan informasi yang praktis</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>




        <section id="about" className="services">
          <div className="container">

            <div className="section-title">
              <h2>Tentang Kami</h2>
              <p>Selamat datang di SIDATA, Sistem Informasi Data untuk SMK Wikrama Bogor. Website ini dirancang sebagai wadah komprehensif untuk mengelola data peserta didik, guru, dan staf di SMK Wikrama.</p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="icon-box">
                  <div className="icon"><i className="fas fa-pencil"></i></div>
                  <h4><a href="">Pengisian Data</a></h4>
                  <p>Melalui SIDATA, proses pengisian data menjadi lebih efisien dan mudah</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div className="icon-box">
                  <div className="icon"><i className="fas fa-book"></i></div>
                  <h4><a href="">Kelola Data</a></h4>
                  <p>Dengan tujuan utama memfasilitasi kelola data yang optimal</p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div className="icon-box">
                  <div className="icon"><i className="fas fa-school"></i></div>
                  <h4><a href="">Solusi</a></h4>
                  <p>Menjadi solusi yang menjembatani kebutuhan seluruh komunitas pendidikan di SMK Wikrama Bogor</p>
                </div>
              </div>

            </div>

          </div>
        </section>



        <section id="contact" className="contact">
          <div className="container">

            <div className="section-title">
              <h2>Kontak</h2>

            </div>
          </div>

          <div>
            <iframe style={{ border: 0, width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.0139230514087!2d106.84130407499401!3d-6.645191993349406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69c89505b4c37d%3A0x307fc4a38e65fa2b!2sWikrama%20Bogor%20Vocational%20School!5e0!3m2!1sen!2sid!4v1703946289469!5m2!1sen!2sid" frameborder="0" allowfullscreen></iframe>
          </div>

          <div className="container">
            <div className="row mt-5">

              <div className="col-lg-12">
                <div className="info">


                  <div className="email ml-0">

                    <h4>Email</h4>
                    <p>prohumasi@smkwikrama.sch.id</p>
                  </div>

                  <div className="address">

                    <h4>Lokasi</h4>
                    <p>Jl. Raya Wangun Kelurahan Sindangsari 16720</p>
                  </div>

                  <div className="phone">

                    <h4>No. Telp</h4>
                    <p>082221718035</p>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </section>

      </main>

      <footer id="footer">
        <div className="container">

          <div className=" text-center">
            <div className="copyright">
              &copy; <strong> <Link to="/sidata-team" target='_blank'> <span> GEN-19 PPLG</span></Link></strong>. All Rights Reserved
            </div>
          </div>

        </div>
      </footer>

    </>


  );
};


