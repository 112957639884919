import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const confirmDelete = async (_id, endpoint, getData, navigate, path) => {
  try {
    const { value: confirmed } = await Swal.fire({
      title: 'Konfirmasi Hapus',
      text: 'Apakah anda yakin untuk menghapus data ini?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Batal',
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (!confirmed) {
      Swal.fire('Batal', 'Penghapusan data dibatalkan', 'info');
      return;
    }

    Swal.fire({
      title: 'Sedang Menghapus...',
      text: 'Mohon tunggu',
      icon: 'info',
      allowOutsideClick: () => !Swal.isLoading(),
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/${endpoint}/${_id}`);

    if (response.status === 200) {
      getData();
      
      Swal.fire({
        title: 'Sukses!',
        text: 'Data Berhasil Dihapus',
        icon: 'success',
        onAfterClose: () => {
          navigate(path);
        }
      });
    } else {
      Swal.fire('Error', 'Gagal menghapus data', 'error');
    }
  } catch (error) {
    console.log(error, "ini kenapa")
    Swal.fire('Error', 'Terjadi kesalahan saat menghapus data.', 'error');
  }
};

export { confirmDelete };