import React, { useContext } from 'react'
import GelBreadcrumb from '../../../components/globalcomp/GelBreadcrumb'
import LayoutDashboard from '../../../layouts/LayoutDashboard'
import { Stepper } from './components/Stepper'
import { BsPersonVideo3 } from "react-icons/bs";
import { MdFamilyRestroom } from "react-icons/md";
import { IoDocumentsOutline } from "react-icons/io5";
import StudentContext from '../../../context/StudentContext';
import { Link } from 'react-router-dom';
import PdfDataDiri from './Print/DataDiri';
import ReactPDF, { PDFViewer, renderToStream } from '@react-pdf/renderer';
import AuthContext from '../../../context/AuthContext';
const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const StatusPage = () => {
  const steps = ['Pending', 'Terverifikasi'];
  const { student } = useContext(StudentContext);
  const { user } = useContext(AuthContext);

  const currentStepDiri = student?.status_data_diri === 'Verifikasi' ? 2 : 0;
  const currentStepFamily = student?.status_data_family === 'Verifikasi' ? 2 : 0;
  const currentStepDokumen = student?.status_data_dokumen === 'Verifikasi' ? 2 : 0;
  // const userId = student?.user_id?._id;
  // console.log(userId, "tes");

  // const apiPdf = '/print/data-diri/' + userId;
  // const handleExportPdf = () => {
  //   window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/print/data-diri/${userId}`;
  // };

  const handleExportPdf = async () => {
    // ReactPDF.render(<PdfDataDiri />, `${__dirname}/cetak/try.pdf`)
    // const stream = await renderToStream(<PdfDataDiri />)
  }

  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Home" dua="Status Data" urlSatu="/dashboard/petunjuk" />
        <div className='card-box shadow-sm p-4'>


          {/* <button className='btn btn-success mb-3 float-end' onClick={handleExportPdf}>
            Unduh Data
          </button> */}
          <Link target='_blank' to={`/print-pdf/${user._id}`} className='btn btn-success mb-3 float-end' >
            Unduh Data
          </Link>
          {/* <a href={apiEndpoint + '/print-pdf/' + user._id} target='_blank' className='btn btn-success mb-3 float-end'>Unduh Data</a> */}

          <table className="table table-borderless">
            <thead>
              <tr>
                <th className='font-bold  text-center'>
                  <BsPersonVideo3 style={{ fontSize: "30px" }} />
                  <h5 className='mt-2 font-bold'>Data Diri</h5>
                </th>

                <th>
                  <div className='m-4'><Stepper steps={steps} currentStep={currentStepDiri} /></div>
                </th>

              </tr>
              <tr>
                <th className='font-bold  text-center'>
                  <MdFamilyRestroom style={{ fontSize: "30px" }} />
                  <h5 className='mt-2 font-bold'>Keluarga</h5>
                </th>
                <th><div className='m-4'><Stepper steps={steps} currentStep={currentStepFamily} /></div></th>
              </tr>
              <tr>
                <th className='font-bold  text-center'>
                  <IoDocumentsOutline style={{ fontSize: "30px" }} />
                  <h5 className='mt-2 font-bold'>Dokumen</h5>
                </th>
                <th><div className='m-4'><Stepper steps={steps} currentStep={currentStepDokumen} /></div></th>
              </tr>

            </thead>
          </table>
        </div>
      </LayoutDashboard>
    </div>
  )
}
