import React from 'react'

export const Kosong = () => {
  return (
              <tr>
              <td colSpan="8" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  Data Kosong
              </td>
          </tr>

  )
}
