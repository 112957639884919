import React, { useContext, useEffect, useState } from 'react'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { CekSiswa } from './components/CekSiswa'
import { CekKeluarga } from './components/CekKeluarga'
import AuthContext from '../../../../context/AuthContext'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import StudentContext from '../../../../context/StudentContext'
import { CekSmp } from './components/CekSmp'
import Portfolio from './components/Portfolio'
import Sertifikat from './components/Sertfifkat'

export const DataDiriPage = () => {
  document.title = "Data Diri";
  const tabs = [
    { label: 'Siswa' },
    { label: 'Keluarga' },
    { label: 'Dokumen' },
    { label: 'Portfolio' },
    { label: 'Sertifikat' },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const { user } = useContext(AuthContext);
  const { student } = useContext(StudentContext)
  const [studentData, setStudentData] = useState({})


  useEffect(() => {
    setStudentData(student)
  }, [user, student]);


  return (
    <div>
      <LayoutDashboard>
        <GelBreadcrumb satu="Home" dua="Data Diri" urlSatu="/dashboard/petunjuk" />
        <div className='card-box px-4 pt-3 shadow-sm mb-3' >
          <ul className="nav nav-pills nav-fill">
            {tabs.map((tab, index) => (
              <li className="nav-item cursor-pointer" key={index}>
                <p className={`nav-link pointer ${activeTab === index
                  ? 'active'
                  : ''
                  }`} aria-current="page" onClick={() => handleTabClick(index)}> {tab.label}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className='card-box p-4 shadow-sm mb-5'>
          {studentData ? (
            <>
              <div className="tab-content">
                {activeTab === 0 && <div>
                  <CekSiswa studentData={studentData} />
                </div>}
                {activeTab === 1 && <div>
                  <CekKeluarga studentData={studentData} />
                </div>}
                {activeTab === 2 && <div>
                  <CekSmp studentData={studentData} />
                </div>}
                {activeTab === 3 && <div>
                  <Portfolio studentData={studentData} />
                </div>}
                {activeTab === 4 && <div>
                  <Sertifikat studentData={studentData} />
                </div>}
              </div>
            </>
          ) : (<>Loading..</>)}

        </div>
      </LayoutDashboard>
    </div>
  )
}

export default DataDiriPage