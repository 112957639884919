import axios from "axios";
import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        let userProfile = localStorage.getItem("userProfile");
        if (userProfile) {
            return JSON.parse(userProfile);
        }
        return null;
    });

    const loginApiCall = async (payload) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_ENDPOINT + "/login", payload);
            const user = response.data.user;
            setUser(user);
            localStorage.setItem("userProfile", JSON.stringify(user));
            localStorage.setItem("loginTime", new Date().getTime());
            return user;
        } catch (error) {
            throw error;
        }
    };

    const checkAutoLogout = () => {
        const loginTime = localStorage.getItem("loginTime");
        if (loginTime && user) {
            const currentTime = new Date().getTime();
            const timeElapsed = currentTime - parseInt(loginTime);
            const twoMinutesInMillis = 1 * 60 * 60 * 1000;;
            if (timeElapsed >= twoMinutesInMillis) {
                logoutAPICall();
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(checkAutoLogout, 60 * 1000)
        return () => clearInterval(interval);
    }, []);

    const logoutAPICall = async () => {
        try {
            await axios.post(process.env.REACT_APP_API_ENDPOINT + "/logout");
            localStorage.removeItem("userProfile");
            localStorage.removeItem("loginTime");
            setUser(null);
            console.log("Logout berhasil");
        } catch (error) {
            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ loginApiCall, user, logoutAPICall, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
