import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import { useNavigate } from 'react-router-dom';
import GtkContext from '../../../../context/GtkContext';
import Swal from 'sweetalert2';
import axios from 'axios';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const customTooltipJenis = (
    <Tooltip id="tooltip">
        Jenis diklat yang pernah diikuti oleh PTK
    </Tooltip>
);

const customTooltipNama = (
    <Tooltip id="tooltip">
        Nama acara diklat yang pernah diikuti
    </Tooltip>
);

const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
};

export const CreateDiklat = () => {
    const { gtk } = useContext(GtkContext);
    const navigate = useNavigate();
    const gtkId = gtk?.gtk?._id;

    const [formData, setFormData] = useState({
        'jenis_diklat': "",
        "nama_diklat": "",
        "penyelenggara": "",
        "tahun_diklat": "",
        "peran": "",
        "tingkat_diklat": "",

    })



    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : `wajib diisi` }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            if (gtk && gtkId) {
                try {
                    await axios.post(`${apiEndpoint}/diklat/create/${gtkId}`, { ...formData });
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Berhasil Create Data',
                        icon: 'success',
                    }).then(() => navigate('/dashboard/pembimbing/diklat/show'));
                } catch (error) {
                    console.log(error.response?.data?.error || 'Error');
                }
            } else {
                console.log('Tidak ada ID.');
            }
        }
    }


    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Home" dua="Data Diklat" />


                {/* create-diklat */}
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>


                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='jenis_diklat' className='form-label'>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={customTooltipJenis}
                                        >
                                            <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                        </OverlayTrigger>
                                        Jenis Diklat :</label>
                                    <input type='text' className={`form-control ${errors.jenis_diklat ? 'is-invalid' : ''}`}
                                        value={formData.jenis_diklat}
                                        onChange={(e) => handleChange(e)}
                                        name='jenis_diklat' />
                                    {errors.jenis_diklat && <p style={{ color: "red" }}>{errors.jenis_diklat}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='nama_diklat' className='form-label'>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={customTooltipNama}
                                    >
                                        <Button variant="light" style={buttonStyle}><i className="bi bi-info-circle"></i></Button>
                                    </OverlayTrigger>
                                    Nama Diklat :</label>
                                <input type='text' className={`form-control ${errors.nama_diklat ? 'is-invalid' : ''}`}
                                    value={formData.nama_diklat}
                                    onChange={(e) => handleChange(e)}
                                    name='nama_diklat' />
                                {errors.nama_diklat && <p style={{ color: "red" }}>{errors.nama_diklat}</p>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='penyelenggara' className='form-label'>Penyelenggara  :</label>
                                    <input type='text' className={`form-control ${errors.penyelenggara ? 'is-invalid' : ''}`}
                                        value={formData.penyelenggara}
                                        onChange={(e) => handleChange(e)}
                                        name='penyelenggara' />
                                    {errors.penyelenggara && <p style={{ color: "red" }}>{errors.penyelenggara}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tahun_diklat' className='form-label'>Tahun Diklat :</label>
                                <input type='number' className={`form-control ${errors.tahun_diklat ? 'is-invalid' : ''}`}
                                    value={formData.tahun_diklat}
                                    onChange={(e) => handleChange(e)}
                                    name='tahun_diklat' />
                                {errors.tahun_diklat && <p style={{ color: "red" }}>{errors.tahun_diklat}</p>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='peran' className='form-label'>Peran :</label>
                                    <input type='text' className={`form-control ${errors.peran ? 'is-invalid' : ''}`}
                                        value={formData.peran}
                                        onChange={(e) => handleChange(e)}
                                        name='peran' />
                                    {errors.peran && <p style={{ color: "red" }}>{errors.peran}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tingkat_diklat' className='form-label'>Tingkat Diklat :</label>
                                    <input type='text' className={`form-control ${errors.tingkat_diklat ? 'is-invalid' : ''}`}
                                        value={formData.tingkat_diklat}
                                        onChange={(e) => handleChange(e)}
                                        name='tingkat_diklat' />
                                    {errors.tingkat_diklat && <p style={{ color: "red" }}>{errors.tingkat_diklat}</p>}

                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>



            </LayoutDashboard>
        </div>
    )
}
