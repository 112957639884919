import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFViewer, Font } from "@react-pdf/renderer";
import { Col, Row, Table } from "react-bootstrap";

// !!! not used

// Font.register({fontStyle: 'normal', fontWeight: 'bold'})

Font.register({
    family: 'Roboto',
    src: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    sectionTitle: {
        margin: 10,
        padding: 10,
        // flexGrow: 1,
        // width: '',
        color: 'black',
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '1.5px',
    },
    sectionTitleText: {
        // fontFamily: 'Roboto',
        fontWeight: 'bold',
    }
})

const PdfDataDiri = () => (
    <Document>
        <Page size={'A4'} style={styles.page}>
            <View style={styles.section}>
                <View style={styles.sectionTitle}>
                    <Text>SURAT PERNYATAAN</Text>
                    <Text>VALIDASI DAN VERIFIKASI DATA PESERTA DIDIK</Text>
                    <Text>SMK WIKRAMA BOGOR T.P. 2023-2024</Text>
                </View>
                <View style={styles.section}>
                    <Text style={{ fontSize: '12px', paddingLeft: '35px' }}>Yang bertanda tangan di bawah ini kami selaku orang tua peserta didik dari :</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text>NIS</Text>
                        <Text>12083493</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
)

// ReactPDF.render(<PdfDataDiri />, `${__dirname}/cetak/try.pdf`)
// ReactPDF.renderToStream(<PdfDataDiri />)

export default PdfDataDiri