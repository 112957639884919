import React, { useContext, useEffect, useState } from 'react'
import LayoutDashboard from '../../../../layouts/LayoutDashboard'
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import GtkContext from '../../../../context/GtkContext'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';



const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const customTooltipStatusAnak = (
    <Tooltip id="tooltip">
       Status anak PTK
    </Tooltip>
);

const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
  };


export const CreateAnak = () => {
    const navigate = useNavigate();

    const { gtk } = useContext(GtkContext)
    const gtkId = gtk?.gtk?._id;

    const [formData, setFormData] = useState({
        'nama': "",
        "jk": "",
        'anak_ke': "",
        "tempat_lahir": "",
        "tanggal_lahir": "",
        "jenjang_pendidikan": ""
    })

    const [errors, setErrors] = useState({});


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value ? '' : 'wajib diisi' }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            if (gtk && gtkId) {
                try {
                    await axios.post(`${apiEndpoint}/anak/create/${gtkId}`, { ...formData });
                    Swal.fire({
                        title: 'Sukses!',
                        text: 'Berhasil Create Data',
                        icon: 'success',
                    }).then(() => navigate('/dashboard/pembimbing/anak/show'));
                } catch (error) {
                    console.log(error.response?.data?.error || 'Error');
                }
            } else {
                console.log('Tidak ada ID.');
            }
        }
    }
    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Data Anak" tiga="Tambah Data Anak" />

                <div className='card-box shadow-sm p-4 mb-30'>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='nama' className='form-label'>Nama Anak :</label>
                                    <input type='text' name='nama'
                                        className={`form-control ${errors.nama ? 'is-invalid' : ''}`}
                                        value={formData.nama}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {errors.nama && <p style={{ color: "red" }}>{errors.nama}</p>}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='anak_ke' className='form-label'>Anak Ke :</label>
                                <input type='number' name='anak_ke' className={`form-control ${errors.anak_ke ? 'is-invalid' : ''}`}
                                    value={formData.anak_ke}
                                    onChange={(e) => handleChange(e)} />

                                {errors.anak_ke && <p style={{ color: "red" }}>{errors.anak_ke}</p>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor='jk' className='form-label'>Jenjang Pendidikan :</label>
                                <select name='jenjang_pendidikan' className={`form-control ${errors.jenjang_pendidikan ? 'is-invalid' : ''}`}
                                    value={formData.jenjang_pendidikan}
                                    onChange={(e) => handleChange(e)}>
                                    <option value=''>Pilih</option>
                                    <option value='tidak_sekolah'>Tidak Sekolah</option>
                                    <option value='putus_sd'>Putus SD</option>
                                    <option value='sd_sederajat'>SD Sederajat</option>
                                    <option value='smp_sederajat'>SMP Sederajat</option>
                                    <option value='sma_sederajat'>SMA Sederajat</option>
                                    <option value='d1'>D1</option>
                                    <option value='d2'>D2</option>
                                    <option value='d3'>D3</option>
                                    <option value='d4/s1'>D4/S1</option>
                                    <option value='s2'>S2</option>
                                    <option value='s3'>S3</option>
                                </select>
                                {errors.jenjang_pendidikan && <p style={{ color: "red" }}>{errors.jenjang_pendidikan}</p>}
                            </div>



                            <div className="col-md-6">
                                <label htmlFor='jk' className='form-label'>Jenis Kelamin :</label>
                                <select name='jk' className={`form-control ${errors.jk ? 'is-invalid' : ''}`}
                                    value={formData.jk}
                                    onChange={(e) => handleChange(e)}>
                                    <option value=''>Pilih Jenis Kelamin</option>
                                    <option value='L'>Laki-laki</option>
                                    <option value='P'>Perempuan</option>
                                </select>
                                {errors.jk && <p style={{ color: "red" }}>{errors.jk}</p>}
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor='tempat_lahir' className='form-label'>Tempat Lahir :</label>
                                    <input type='text' name='tempat_lahir' className={`form-control ${errors.tempat_lahir ? 'is-invalid' : ''}`}
                                        value={formData.tempat_lahir}
                                        onChange={(e) => handleChange(e)} />

                                    {errors.tempat_lahir && <p style={{ color: "red" }}>{errors.tempat_lahir}</p>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor='tanggal_lahir' className='form-label'>Tanggal Lahir :</label>
                                <input type='date' name='tanggal_lahir'
                                    className={`form-control ${errors.tanggal_lahir ? 'is-invalid' : ''}`}
                                    value={formData.tanggal_lahir}
                                    onChange={(e) => handleChange(e)} />
                                {errors.tanggal_lahir && <p style={{ color: "red" }}>{errors.tanggal_lahir}</p>}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary">Submit</button>

                    </form>
                </div>


            </LayoutDashboard>

        </div>

    )
}
