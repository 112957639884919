import React, { useContext, useState } from 'react';
import LayoutDashboard from '../../../../layouts/LayoutDashboard';
import GelBreadcrumb from '../../../../components/globalcomp/GelBreadcrumb';
import { useNavigate } from 'react-router-dom';
import GtkContext from '../../../../context/GtkContext';
import Swal from 'sweetalert2';
import axios from 'axios';
import { RombelContext } from '../../../../context/RombelContext';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

export const CreateRombelPage = () => {

    const {getRombels } = useContext(RombelContext)
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        'nama_rombel': '',

    });

    const [errors, setErrors] = useState({});


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: value.trim() ? '' : 'wajib diisi' }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = Object.fromEntries(
            Object.entries(formData).map(([key, value]) => [key, value.trim() ? '' : 'wajib diisi'])
        );

        if (Object.values(formErrors).some((error) => error)) {
            setErrors(formErrors);
            return;
        }

        const result = await Swal.fire({
            title: 'Konfirmasi',
            text: 'Apakah Anda yakin data sudah benar?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        });

        if (result.isConfirmed) {
            try {
                await axios.post(`${apiEndpoint}/rombel`, { ...formData });
                Swal.fire({
                    icon: 'success',
                    title: 'Data berhasil disimpan!',
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  setTimeout(() => {
                    getRombels()
                    navigate('/admin/rombel');
                  }, 2000);
            } catch (error) {
                console.log(error.response?.data?.error || 'Error');
            }

        }
    }

    return (
        <div>
            <LayoutDashboard>
                <GelBreadcrumb satu="Dashboard" dua="Tambah Data Rombel" tiga="Rombel" urlDua="/admin/rombel" urlSatu="/admin" />
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='card-box shadow-sm p-4 mb-30'>
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor='nama_rombel' className='form-label'>Nama Rombel :</label>
                                <input type='text'
                                    className={`form-control ${errors.nama_rombel ? 'is-invalid' : ''}`}
                                    value={formData.nama_rombel}
                                    onChange={(e) => handleChange(e)}
                                    name='nama_rombel' />
                                {errors.nama_rombel && <p style={{ color: "red" }}>{errors.nama_rombel}</p>}
                            </div>
                        </div>


                        <button type="submit" className="btn bg-blue text-white mt-3">Submit</button>
                    </div>
                </form>
            </LayoutDashboard>
        </div>
    )
}
