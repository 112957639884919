import React from 'react';
import { Link } from 'react-router-dom';

const DashCard = ({ icon: Icon, total, isLoading, title, linkDetail }) => {
  return (
    <div className="col-xl-3 col-lg-3 col-md-6 mb-20">
        {
          linkDetail ? (
            <Link to={linkDetail}>
              <div className="card-box height-100-p widget-style3">
                <div className="d-flex flex-wrap">

                  <div className="widget-data">
                    <div className="weight-700 font-24 text-dark">{isLoading ? (<div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>) : (total)}</div>
                    <div className="font-14 text-secondary weight-500">
                      {title}
                    </div>
                  </div>
                  <div className="widget-icon">
                    <div className="icon">
                      <Icon />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ) : (
            <div className="card-box height-100-p widget-style3">
              <div className="d-flex flex-wrap">

                <div className="widget-data">
                  <div className="weight-700 font-24 text-dark">{isLoading ? (<div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>) : (total)}</div>
                  <div className="font-14 text-secondary weight-500">
                    {title}
                  </div>
                </div>
                <div className="widget-icon">
                  <div className="icon">
                    <Icon />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
  );
};

export default DashCard;
